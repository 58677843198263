import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../shared/components/TableElements'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { searchCasinoBettingByBettingLogKey } from '../../../utils/api/logManage/logApi'
import CasinoBettingInfoData from './CasinoBettingInfoData'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const CasinoBettingInfo = ({ bettingUserId, bettingLogKey, receiverType }) => {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)
  const [apiFlag, setApiFlag] = useState(false)
  const onClickHandler = () => {
    setApiFlag(true)
    setMemberNoteModalShow(prevState => !prevState)
  }

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)

  const [params, setParams] = useState({
    bettingLogKey,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      bettingLogKey,
    }))
  }, [page, bettingLogKey])

  const [content, setContent] = useState([])
  const [totalElement, setTotalElement] = useState(0)
  const [totalBettingAmount, setBettingAmount] = useState(0)
  const [totalBettingResultAmount, setTotalBettingResultAmount] = useState(0)

  const fetchSearchCasinoBettingByBettingLogKey = async () => {
    await searchCasinoBettingByBettingLogKey(params).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
      setBettingAmount(res.data.totalBettingAmount)
      setTotalBettingResultAmount(res.data.totalBettingResultAmount)
    })
  }

  useEffect(() => {
    if (!apiFlag) return
    fetchSearchCasinoBettingByBettingLogKey()
  }, [apiFlag])

  const [memberBettingLogListData, setMemberBettingLogListData] = useState(CasinoBettingInfoData(content))

  useEffect(() => {
    setMemberBettingLogListData(CasinoBettingInfoData(content))
  }, [content])

  const [memberBettingLogRows, setMemberBettingLogRows] = useState(memberBettingLogListData.tableRowsData)

  useEffect(() => {
    setMemberBettingLogRows(memberBettingLogListData.tableRowsData)
  }, [memberBettingLogListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <CustomBtnTableWrap style={{ marginLeft: '10px' }}>
      <CustomButton role={Button} onClick={() => onClickHandler()}>
        배팅정보
      </CustomButton>
      <CustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>배팅세부정보 - {bettingUserId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <div style={{ padding: '10px 0' }}>
            <span style={{ color: 'red', fontWeight: '700' }}>
              배팅액: {totalBettingAmount.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'blue', fontWeight: '700', marginLeft: '10px' }}>
              당첨액: {totalBettingResultAmount.toString().replace(commonReg2, ',')} 원
            </span>
          </div>
          <ReactTableBase
            key="common"
            columns={memberBettingLogListData.tableHeaderData}
            data={memberBettingLogRows}
            tableConfig={tableConfig}
          />
        </CustomModalBody>
      </CustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default CasinoBettingInfo

CasinoBettingInfo.propTypes = {
  bettingUserId: PropTypes.string.isRequired,
  bettingLogKey: PropTypes.string.isRequired,
  receiverType: PropTypes.string.isRequired,
}

const CustomStyledModal = styled(NoteCustomStyledModal)`
  .modal-dialog {
    max-width: 1100px !important;
  }
`

const CustomButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
`
