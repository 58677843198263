import { Card, CardBody } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

import { CustomCol } from '../../shared/components/CustomCol'

const StatisticsTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <CustomCol md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <BorderedBottomTabs>
            <Tab.Container defaultActiveKey={activeKey}>
              <TabsWrap>
                <Nav className="nav-tabs">
                  <NavItem>
                    <NavLink
                      eventKey="1"
                      onClick={e => {
                        history.push('/statistics/rolling')
                      }}
                    >
                      정산 통계
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="2"
                      onClick={e => {
                        history.push('/statistics/rolling2')
                      }}
                    >
                      정산 통계(V2)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="4"
                      onClick={e => {
                        history.push('/user/history/pointdeposit')
                      }}
                    >
                      롤링 적립내역
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="7"
                      onClick={e => {
                        history.push('/user/history/losing-pointdeposit')
                      }}
                    >
                      루징 적립내역
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="6"
                      onClick={e => {
                        history.push('/partner/management/pointexchange')
                      }}
                    >
                      롤링/루징 전환신청
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="5"
                      onClick={e => {
                        history.push('/user/history/pointexchange')
                      }}
                    >
                      롤링/루징 전환내역
                    </NavLink>
                  </NavItem>
                </Nav>
              </TabsWrap>
            </Tab.Container>
          </BorderedBottomTabs>
        </CardBody>
      </Card>
    </CustomCol>
  )
}

export default StatisticsTabs

StatisticsTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
