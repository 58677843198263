import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { MoneyExchangeTypeEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton, CustomSettlementTypeButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import { MemberDepositStatusEnums } from '../../../enums/MemberHistory/MemberDepositEnums'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MemberPointExchangeListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '롤링/루징',
      accessor: 'pointType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomSettlementTypeButton pointType={original.pointType}>
            {MoneyExchangeTypeEnums[original.pointType]}
          </CustomSettlementTypeButton>
        )
      },
    },
    {
      Header: '변경전(C)',
      accessor: 'beforePoint',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '전환액',
      accessor: 'changePointExchange',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후(C)',
      accessor: 'afterPoint',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneyStatus) {
          case 'APPROVE':
            return (
              <span style={{ color: 'blue', fontWeight: '900' }}>{MemberDepositStatusEnums[original.moneyStatus]}</span>
            )
          case 'WAITING':
            return (
              <span style={{ color: 'orange', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.moneyStatus]}
              </span>
            )
          case 'CANCEL':
            return (
              <span style={{ color: 'red', fontWeight: '900' }}>{MemberDepositStatusEnums[original.moneyStatus]}</span>
            )
          case 'APPLY':
            return (
              <span style={{ color: 'green', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.moneyStatus]}
              </span>
            )
          default:
            return (
              <span style={{ color: 'black', fontWeight: '900' }}>
                {MemberDepositStatusEnums[original.moneyStatus]}
              </span>
            )
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberPointExchangeListData
