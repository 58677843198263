import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 게시판 관리 등록 API
export async function createBoardList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/boardlist',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 게시판 관리 수정 API
export async function updateBoardList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/boardlist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 게시판 삭제 API
export async function deleteBoardList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/boardlist/delete',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 게시판 관리 조회 API
export async function searchBoardList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/boardlist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchBoardList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchBoardList() {
    await searchBoardList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchBoardList()
  }, [params])

  return { totalElement, content, fetchSearchBoardList }
}

// 팝업 관리 등록 API
export async function createPopupList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/popuplist',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 팝업 관리 수정 API
export async function updatePopupList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/popuplist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 팝업 관리 조회 API
export async function searchPopupList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/popuplist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPopupList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPopupList() {
    await searchPopupList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPopupList()
  }, [params])

  return { totalElement, content, fetchSearchPopupList }
}

// 1:1문의 등록 API
export async function createOnetoOne(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/onetoone',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 1:1문의 삭제 API
export async function deleteOnetoOneList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/onetoonelist',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 1:1문의 관리 조회 API
export async function searchOnetoOneList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/onetoonelist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchOnetoOneList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchOnetoOneList() {
    await searchOnetoOneList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchOnetoOneList()
  }, [params])

  return { totalElement, content, fetchSearchOnetoOneList }
}

// 1:1문의 업데이트 API
export async function updateOnetoOne(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/onetoonelist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 1:1 문의 레디스 제거 API
export async function deleteOnetoOneRedis(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/onotoone-redis',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 쪽지 삭제 API
export async function deleteNoteList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/notelist',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 쪽지 조회 API
export async function searchNoteList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/notelist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchNoteList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchNoteList() {
    await searchNoteList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchNoteList()
  }, [params])

  return { totalElement, content, fetchSearchNoteList }
}

// 파트너 1명 - 쪽지 보내기
export async function publishToOnePartnerNote(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/partner-note',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 파트너 계열사 - 쪽지 보내기
export async function publishToLinePartnerNote(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/note-all-partner',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 1명 - 쪽지 보내기
export async function publishToOneMemberNote(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/member-note',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 전체 - 쪽지 보내기
export async function publishToAllMemberNote(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/note-all-member',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너별 쪽지보내기 - 파트너 리스트 조회
 */
export async function searchNotePartnerList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/note/partner-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 쪽지 상태 업데이트 API
export async function updateNote(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/notestatus',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 쿠폰관리 - 쿠폰등록 API
export async function createCouponList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/couponlist',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 쿠폰 관리 - 생성된 쿠폰 조회 API
export async function searchCouponList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/couponlist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchCouponList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchCouponList() {
    await searchCouponList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchCouponList()
  }, [params])

  return { totalElement, content, fetchSearchCouponList }
}

// 어드민이 쿠폰 지급을 위한 Select용 API
export async function searchCouponSelectList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/couponlist/select',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 이용자 또는 파트너가 쿠폰 사용을 위한 Select용 API
export async function searchCouponUseSelectList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/couponuselist/select',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 쿠폰관리 - 쿠폰사용내역
export async function searchCouponUseList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/couponuselist',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchCouponUseList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchCouponUseList() {
    await searchCouponUseList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchCouponUseList()
  }, [params])

  return { totalElement, content, fetchSearchCouponUseList }
}

// 회원에게 쿠폰 발행
export async function publishMemberCoupon(body) {
  const response = await axios({
    url: '/tyson/partner/v1/operation/management/member/publish-coupon',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 파트너에게 쿠폰 발행
export async function publishPartnerCoupon(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/partner/publish-coupon',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
