import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  searchPartner,
  searchPartnerSettlementStatisticsV2,
  searchSettlementStatistics,
  searchSettlementStatisticsSpecify,
} from '../utils/api/partnerManage/partnerManageApi'

export const fetchSearchPartnerSettlementStatisticsV2 = createAsyncThunk(
  'partneSettlement/fetchSearchPartnerSettlementStatistics',
  async params => {
    const res = await searchPartnerSettlementStatisticsV2(params)
    return res.data
  },
)

function findUserByKey(data, key) {
  // 기본 검사: 현재 레벨의 userKey가 찾고자 하는 key와 동일한지 확인

  if (Array.isArray(data)) {
    const findIndex = data.findIndex(e => e.userKey === key)
    if (findIndex !== -1) {
      return data[findIndex]
    }
    for (let i = 0; i < data.length; i += 1) {
      // if (data[i].subUserInfos.length === 0) {
      //   continue
      // }
      const result = findUserByKey(data[i].subUserInfos, key)
      if (result) return result
    }
  } else {
    return null
  }
}

const settlementInfoSlice = createSlice({
  name: 'settlementInfo',
  initialState: {
    userSettlementList: [],
    apiLoading: false,
    searchUserId: null,
    scrollUserKey: null,
  },
  reducers: {
    resetSettlementDataV2: (state, action) => {
      state.userSettlementList = []
      state.searchUserId = null
      state.scrollUserKey = null
    },
    handleIsClosed: (state, action) => {
      const findUser = findUserByKey(state.userSettlementList, action.payload)
      if (findUser) {
        const temp = !findUser.isOpened
        findUser.isOpened = temp
        // 안비워주면 하부 2번 생성됨
        if (!temp) {
          findUser.subUserInfos = []
          state.scrollUserKey = null
        } else {
          // 열릴때
          state.scrollUserKey = findUser.userKey
        }
      }
    },
    handleSearchUserId: (state, action) => {
      state.searchUserId = action.payload
    },
    handleScrollUserKey: (state, action) => {
      state.scrollUserKey = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSearchPartnerSettlementStatisticsV2.pending, (state, action) => {
      state.apiLoading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSearchPartnerSettlementStatisticsV2.fulfilled, (state, action) => {
      const { content } = action.payload
      if (content.length === 0) {
        state.apiLoading = false
        return
      }
      const existUserSettlementList = state.userSettlementList
      content.forEach(item => {
        if (item.topUserKey === null || state.searchUserId) {
          existUserSettlementList.push({
            ...item,
            isOpened: false,
            subUserInfos: [],
          })
        } else {
          const findTopUserKey = item.topUserKey
          const findUser = findUserByKey(existUserSettlementList, findTopUserKey)
          if (findUser) {
            findUser.subUserInfos.push({
              ...item,
              isOpened: false,
              subUserInfos: [],
            })
          } else {
            // 2레벨 파트너는 topUserkey 가 내려옴 -> 처음엔 기존 리스트에 없으니까 넣어

            existUserSettlementList.push({
              ...item,
              isOpened: false,
              subUserInfos: [],
            })
          }
        }
      })
      if (state.searchUserId) {
        state.searchUserId = null
      }
      state.userSettlementList = existUserSettlementList
      state.apiLoading = false // 요청 시작 시 로딩 상태를 true로 설정
    })
  },
})

export const { handleToggle, resetSettlementDataV2, handleIsClosed, handleSearchUserId, handleScrollUserKey } =
  settlementInfoSlice.actions

export default settlementInfoSlice.reducer
