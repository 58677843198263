import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { createSocketChannel } from './createSocketChannel'
import { deleteAccessToken, getAccessToken, setAccessToken } from '../../utils/token'
import { emitPromise, socket } from '../../utils/socket'
import { SocketIOEventName } from '../../enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../enums/WebSocket/SocketIORequestMessageType'
import { reAuthenticationAction, setUserInfo } from '../memberInfoSlice'

function* onLostSessionMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.LOST_SESSION)

  while (true) {
    try {
      const message = yield take(channel)

      deleteAccessToken()
      //   alert('세션이 만료되어, 로그아웃됩니다.')
      window.location.href = '/login'
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onDuplicatedSessionMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.DUPLICATE_LOGIN)

  while (true) {
    try {
      const message = yield take(channel)

      deleteAccessToken()
      alert('다른 환경에서 로그인되어, 로그아웃됩니다.')
      window.location.href = '/login'
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onRefreshSessionMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.REFRESH_SESSION)

  while (true) {
    try {
      const message = yield take(channel)

      window.location.reload()
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* handleReAuthentication(action) {
  try {
    const { newWindow } = action.payload

    const payload = {
      type: SocketIORequestMessageType.RE_AUTHENTICATION,
      payload: {
        accessToken: getAccessToken(),
        newWindow,
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.CREATE, payload)

    if (res.status) {
      setAccessToken(res.data.accessToken)

      yield put(setUserInfo(res.data.user))

      return res.data.accessToken
    }

    deleteAccessToken()
    alert('세션이 끊겼습니다. 다시 로그인해주세요.')
    window.location.href = '/login'

    return null
  } catch (error) {
    deleteAccessToken()
    window.location.href = '/login'
    return null
  }
}

function* watchReAuthentication() {
  yield takeLatest(reAuthenticationAction, handleReAuthentication)
}

export default function* authSaga() {
  yield all([
    fork(onLostSessionMessage),
    fork(onDuplicatedSessionMessage),
    fork(onRefreshSessionMessage),
    watchReAuthentication(),
  ])
}
