import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { BetSettlementStatus, BetStatus } from '../../../enums/Sport/SportMarketEnums'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import {
  BettingResultStatusEnumsSelectOptions,
  sportResultStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'

const SportDetailBettingLog = ({ sportsDetailBettingLogList, bonsuFolder }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [newSettlementType, setNewSettlementType] = useState(null)
  const onSubmit2 = sportsDetailBettingLogKey => {}

  // const data = {
  //   settlementType: e.target.value || null,
  // }
  // if (!data) {
  //   alert('진행중으로는 결과변경이 불가합니다.')
  // } else {
  //   onSubmit2(sportsDetailBettingLog.sportsDetailBettingLogKey, data)
  // }
  return (
    <SportDetailBettingLogWrap>
      <td colSpan={17} style={{ padding: '10px' }}>
        <table>
          <thead>
            <tr>
              <th>경기시간</th>
              <th>종목</th>
              <th>국가 - 리그</th>
              <th>경기</th>
              <th>마켓</th>
              <th>배팅</th>
              <th>기준점</th>
              <th>배당</th>
              <th>결과</th>
            </tr>
          </thead>
          <tbody>
            {bonsuFolder.bonusFolderNum !== 0 && (
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td
                  style={{
                    background: 'rgba(237, 222, 88,0.5)',
                    color: '#000',
                  }}
                >
                  {bonsuFolder.bonusFolderNum} 폴더 이상
                </td>
                <td
                  style={{
                    background: 'rgba(237, 222, 88,0.5)',
                    color: '#000',
                  }}
                >
                  보너스 배당
                </td>
                <td>-</td>
                <td>{bonsuFolder.bonusFolderPer}</td>
                {/* <td> - </td> */}
                <td> 적중 </td>
              </tr>
            )}

            {sportsDetailBettingLogList.map(sportsDetailBettingLog => {
              return (
                <tr>
                  <td>{convertToKstSportsEventDateTime(sportsDetailBettingLog.startDate)}</td>
                  <td>{sportsDetailBettingLog.sportName}</td>
                  <td>
                    {sportsDetailBettingLog.locationName} - {sportsDetailBettingLog.leagueName}
                  </td>
                  <td>
                    {sportsDetailBettingLog.homeName} vs {sportsDetailBettingLog.awayName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {sportsDetailBettingLog.marketName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {sportsDetailBettingLog.betNameKr}
                  </td>
                  <td>{sportsDetailBettingLog.lineNum || '-'}</td>
                  <td>{sportsDetailBettingLog.priceNum}</td>
                  <td>{BetSettlementStatus[sportsDetailBettingLog.betSettlementType]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </SportDetailBettingLogWrap>
  )
}

export default SportDetailBettingLog

SportDetailBettingLog.propTypes = {
  sportsDetailBettingLogList: PropTypes.arrayOf().isRequired,
  bonsuFolder: PropTypes.shape(),
}

SportDetailBettingLog.defaultProps = {
  bonsuFolder: null,
}

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #6b6b6b !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #fbf7ea;

      &:hover {
        border: none;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
