import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'

const MemberStatisticsBettingSports = ({ sportsGame }) => {
  return (
    <MemberInfoWrap>
      <table>
        <tbody>
          <tr>
            <th>오늘 배팅액</th>
            <td>{sportsGame.todayBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 당첨액</th>
            <td>{sportsGame.todayWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 차액</th>
            <td>
              {sportsGame?.todayWinningAmount > 0 ? (
                <span style={{ color: 'blue', fontWeight: '600' }}>
                  {sportsGame?.todayWinningAmount?.toString().replace(commonReg2, ',')}
                </span>
              ) : (
                <span style={{ color: 'red', fontWeight: '600' }}>
                  {sportsGame?.todayWinningAmount?.toString().replace(commonReg2, ',')}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>이번달 배팅액</th>
            <td>{sportsGame.monthBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 당첨액</th>
            <td>{sportsGame.monthWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 차액</th>
            <td>
              {sportsGame?.monthProfitAmount > 0 ? (
                <span style={{ color: 'blue', fontWeight: '600' }}>
                  {sportsGame?.monthProfitAmount?.toString().replace(commonReg2, ',')}
                </span>
              ) : (
                <span style={{ color: 'red', fontWeight: '600' }}>
                  {sportsGame?.monthProfitAmount?.toString().replace(commonReg2, ',')}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>기간내 배팅액</th>
            <td>{sportsGame.periodBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 당첨액</th>
            <td>{sportsGame.periodWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 차액</th>
            <td>
              {sportsGame?.periodWinningAmount > 0 ? (
                <span style={{ color: 'blue', fontWeight: '600' }}>
                  {sportsGame?.periodWinningAmount?.toString().replace(commonReg2, ',')}
                </span>
              ) : (
                <span style={{ color: 'red', fontWeight: '600' }}>
                  {sportsGame?.periodWinningAmount?.toString().replace(commonReg2, ',')}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberStatisticsBettingSports

MemberStatisticsBettingSports.propTypes = {
  sportsGame: PropTypes.shape({
    todayBettingAmount: PropTypes.string,
    todayWinningAmount: PropTypes.string,
    todayProfitAmount: PropTypes.string,
    monthBettingAmount: PropTypes.string,
    monthWinningAmount: PropTypes.string,
    monthProfitAmount: PropTypes.string,
    periodBettingAmount: PropTypes.string,
    periodWinningAmount: PropTypes.string,
    periodProfitAmount: PropTypes.string,
  }).isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
