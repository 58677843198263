import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import LosingSearchButton from '../../../shared/components/LosingSearchButton'
import { useSearchPartnerLosingPointDeposit } from '../../../utils/api/memberHistory/memberHistoryApi'
import { convertToKst } from '../../../utils/dateTime'
import StatisticsTabs from '../StatisticsTabs'
import PartnerLosingPointDepositListData from './PartnerLosingPointDepositListData'

const PartnerLosingPointDepositList = () => {
  /**
    포인트 입금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [pointDepositType, setPointDepositType] = useState(null)
  const [params, setParams] = useState({
    pointDepositType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      pointDepositType: !pointDepositType ? null : pointDepositType,
    }))
  }, [pointDepositType])

  const { totalElement, content, fetchSearchMemberPointDeposit } = useSearchPartnerLosingPointDeposit(params)

  const [pointDepositListData, setPointDepositListData] = useState(PartnerLosingPointDepositListData(content))

  useEffect(() => {
    setPointDepositListData(PartnerLosingPointDepositListData(content))
  }, [content])

  const [rows, setData] = useState(pointDepositListData.tableRowsData)
  useEffect(() => {
    setData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }

  const [activeKey, setActiveKey] = useState('7')
  return (
    <Card>
      <CardBody>
        <StatisticsTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '900' }}>본인의 루징적립</span> 내역을 확인하실
              수 있습니다.
            </p>
            <p>
              * <span style={{ color: 'blue', fontWeight: '900' }}> 홀덤 입출금은</span> 루징 적립이 되지 않습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <LosingSearchButton
          pointDepositType={pointDepositType}
          onClickHandler={e => {
            setPointDepositType(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={pointDepositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default PartnerLosingPointDepositList
