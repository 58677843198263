import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 누적 통계 - 누적 현황표
 */
export async function searchAccumulateStatus(params) {
  const response = await axios({
    url: '/tyson/partner/v1/statistics/total/status',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useAccumulateStatus(params) {
  const [accumulateStatusTotalElement, setAccumulateStatusTotalElement] = useState(0)
  const [accumulateStatusContent, setAccumulateStatusContent] = useState([])
  const [totalProfit, setTotalProfit] = useState(0)

  async function fetchAccumulateStatus() {
    await searchAccumulateStatus(params).then(response => {
      setAccumulateStatusTotalElement(response.data.totalElement)
      setAccumulateStatusContent(response.data.content)
      setTotalProfit(response.data.totalProfit)
    })
  }

  useEffect(() => {
    fetchAccumulateStatus()
  }, [params])

  return { accumulateStatusTotalElement, accumulateStatusContent, fetchAccumulateStatus, totalProfit }
}

/**
 * 누적 통계
 */
export async function searchDailyBettingStatistics(params) {
  const response = await axios({
    url: '/tyson/partner/v1/statistics/daily-betting-statistics',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useAccumulateStatistics(params) {
  const [accumulateTotalElement, setAccumulateTotalElement] = useState(0)
  const [accumulateContent, setAccumulateContent] = useState([])

  async function fetchAccumulateStatistics() {
    await searchDailyBettingStatistics(params).then(response => {
      setAccumulateTotalElement(response.data.totalElement)
      setAccumulateContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchAccumulateStatistics()
  }, [params])

  return { accumulateTotalElement, accumulateContent, fetchAccumulateStatistics }
}

/**
 * 입출금 통계
 */
export async function searchMoneyStatistics(params) {
  const response = await axios({
    url: '/tyson/partner/v1/statistics/accumulate/money',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useMoneyStatistics(params) {
  const [moneyTotalElement, setMoneyTotalElement] = useState(0)
  const [moneyContent, setMoneyContent] = useState([])

  async function fetchMoneyStatistics() {
    await searchMoneyStatistics(params).then(response => {
      setMoneyTotalElement(response.data.totalElement)
      setMoneyContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchMoneyStatistics()
  }, [params])

  return { moneyTotalElement, moneyContent, fetchMoneyStatistics }
}
