import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import Select from 'react-select'
import styled from 'styled-components'

export const CustomSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;

  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
/** =========================================
 *  회원 조회 - 머니 증차감 Select - START
 *  ========================================= */

export const MemberIncDecSelect = styled(CustomSelect)`
  border: solid 1px black;
`

/** =========================================
 *  회원 조회 - 머니 증차감 Select - END
 *  ========================================= */

/** =========================================
 *  회원 조회 - 쿠폰 Select - START
 *  ========================================= */

export const MemberCouponSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }
`

/** =========================================
 *  회원 조회 - 쿠폰 Select - END
 *  ========================================= */

/** =========================================
 *  쪽지 Select - START
 *  ========================================= */

export const NoteSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }
`

/** =========================================
 *  쪽지 Select - END
 *  ========================================= */

/** =========================================
 *  회원 등록 은행 Select - START
 *  ========================================= */

export const MemberResterBankSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
    border: 1px solid #3c3c3c;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }

  .react-select__control {
    border: 1px solid #3c3c3c;

    &.react-select__control--is-focused,
    &:hover {
      border-color: #3c3c3c !important;
    }
  }
`

/** =========================================
 *  회원 등록 은행 Select - END
 *  ========================================= */

/** =========================================
 *  파트너 수정 은행 Select - START
 *  ========================================= */

export const PartnerInfoBankSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }
`

/** =========================================
 *  파트너 수정 은행 Select - END
 *  ========================================= */

/** =========================================
 *  파트너 조회 - 머니 증차감 Select - START
 *  ========================================= */

export const PartnerIncDecSelect = styled(CustomSelect)``

/** =========================================
 *  파트너 조회 - 머니 증차감 Select - END
 *  ========================================= */

/** =========================================
 *  파트너 조회 - 머니 증차감 Select - START
 *  ========================================= */

export const PartnerNoteSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }
`

/** =========================================
 *  파트너 조회 - 머니 증차감 Select - END
 *  ========================================= */

/** =========================================
 *  파트너 등록 Select - START
 *  ========================================= */

export const PartnerRegisterSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }
`

/** =========================================
 *  파트너 등록 Select -  END
 *  ========================================= */

/** =========================================
 *  파트너 입금신청 Select - START
 *  ========================================= */

export const PartnerCouponSelect = styled(CustomSelect)`
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }
`

/** =========================================
 *  파트너 입금신청 Select - END
 *  ========================================= */
