import PropTypes from 'prop-types'
import React from 'react'
import MemberHistoryBettingCasino from './MemberHistoryBettingCasino'
import MemberHistoryBettingMini from './MemberHistoryBettingMini'
import MemberSportsBettingHistory from './MemberSportsBettingHistory'

const MemberHistoryBetting = ({ memberId }) => {
  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>카지노 배팅로그</h3>
      <MemberHistoryBettingCasino memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>스포츠 배팅로그</h3>
      <MemberSportsBettingHistory memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>미니게임 배팅로그</h3>
      <MemberHistoryBettingMini memberId={memberId} />
    </>
  )
}

export default MemberHistoryBetting

MemberHistoryBetting.propTypes = {
  memberId: PropTypes.string.isRequired,
}
