import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const RollingSearchButton = ({ pointDepositType, onClickHandler }) => {
  return (
    <AuthoritySearchButtonBox>
      <Button
        size="sm"
        active={!pointDepositType}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        전체
      </Button>
      <Button
        id="CASINO"
        size="sm"
        active={pointDepositType === 'CASINO'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        카지노 배팅
      </Button>
      <Button
        id="SPORTS"
        size="sm"
        active={pointDepositType === 'SPORTS'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        스포츠 배팅
      </Button>
      <Button
        id="MINI_GAME"
        size="sm"
        active={pointDepositType === 'MINI_GAME'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        미니게임 배팅
      </Button>
      <Button
        id="VIRTUAL"
        size="sm"
        active={pointDepositType === 'VIRTUAL'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        가상 배팅
      </Button>
      <Button
        id="HOLDEM"
        size="sm"
        active={pointDepositType === 'HOLDEM'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        홀덤 배팅
      </Button>
      <Button
        id="ADMIN"
        size="sm"
        active={pointDepositType === 'ADMIN'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        관리자 지급
      </Button>
      <Button
        id="ATTENDANCE"
        size="sm"
        active={pointDepositType === 'ATTENDANCE'}
        variant="outline-secondary"
        onClick={e => {
          onClickHandler(e)
        }}
      >
        출석 포인트
      </Button>
    </AuthoritySearchButtonBox>
  )
}

export default RollingSearchButton

RollingSearchButton.propTypes = {
  onClickHandler: PropTypes.func,
  pointDepositType: PropTypes.string,
}

RollingSearchButton.defaultProps = {
  onClickHandler: () => {},
  pointDepositType: null,
}

const AuthoritySearchButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    // &:focus {
    //   background: grey;
    //   color: #fff;
    //   border-color: #4ce1b6;
    //   outline: none;
    // }
    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
