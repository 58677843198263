import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import headerInfoSlice from './headerInfoSlice'
import userInfoSlice from './SettlementInfoSlice'
import partnerInfoSlice from './PartnerInfoSlice'
import memberInfoSlice from './memberInfoSlice'
import SettlementInfoSliceV2 from './SettlementInfoSliceV2'
import rootSaga from './saga/rootSaga'
import customCenterSlice from './customCenterSlice'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    headerInfo: headerInfoSlice,
    userInfo: userInfoSlice,
    partnerInfo: partnerInfoSlice,
    customCenter: customCenterSlice,
    memberInfo: memberInfoSlice,
    settlementInfo: SettlementInfoSliceV2,
  },
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
})

// 루트 사가 실행
sagaMiddleware.run(rootSaga)

export default store
