import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function updateVirtualGameFixtureStatus(params) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game',
    method: 'PUT',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function searchVirtualGameFixtureList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function searchVirtualGameBettingLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/betting/virtual-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function deleteVirtualGameBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/betting-log/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateVirtualGameDetailBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/betting-log/betSettlementType',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function cancelVirtualGameBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/betting-log/cancel',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 팀 조회
export async function searchVirturalGameTeamList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/team',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 종목 변경
export async function updateVirturalGameTeamList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/team',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 종목 조회
export async function searchVirturalGameSportList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/sport',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 종목 변경
export async function updateVirturalGameSportList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/sport',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 리그 조회
export async function searchVirturalGameLeagueList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/league',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 가상게임 리그 변경
export async function updateVirturalGameLeagueList(body) {
  const response = await axios({
    url: '/tyson/admin/v1/virtual-game/league',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
