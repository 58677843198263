import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchMemberInfoAction, setUserInfo } from '../memberInfoSlice'
import { searchUserHoldingAsset } from '../../utils/api/memberManage/memberManageApi'

function* handleFetchMemberInfo(action) {
  try {
    const res = yield call(searchUserHoldingAsset, action.payload)

    yield put(setUserInfo(res.data))
  } catch (error) {
    // console.log();
  }
}

function* watchFetchMemberInfo() {
  yield takeLatest(fetchMemberInfoAction, handleFetchMemberInfo)
}

export default function* userInfoSaga() {
  yield all([watchFetchMemberInfo()])
}
