import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { searchMemberMoneyDepositInfo } from '../../../../utils/api/memberHistory/memberHistoryApi'
import { commonReg2 } from '../../../../utils/validate/commonValidate'

function MemberDepositInfoForm({ memberId, moneyDepositKey, onClickHandler }) {
  const [content, setContent] = useState({})
  const fetchSearchMoneyInfo = async () => {
    searchMemberMoneyDepositInfo({
      memberId,
      moneyDepositKey,
    }).then(res => {
      setContent(res.data)
    })
  }

  useEffect(() => {
    fetchSearchMoneyInfo()
  }, [memberId])

  const getResultAmount = (num1, num2) => {
    const resultAmount = num1 - num2

    if (resultAmount > 0) {
      return <td style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    if (resultAmount < 0) {
      return <td style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    return <td style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
  }

  return (
    <MemberInfoWrap>
      <MemberInfoHeader>
        [{content?.memberName}] 회원의 <span style={{ color: 'red', fontWeight: '600' }}>최근 한달</span> 내역입니다.
      </MemberInfoHeader>
      <table>
        <tbody>
          <tr>
            <th colSpan={2}>아이디</th>
            <td colSpan={3}>{content?.memberId}</td>
            <th colSpan={2}>회원명</th>
            <td colSpan={2}>{content?.memberName}</td>
          </tr>
          <tr>
            <th colSpan={2}>입금액</th>
            <td>{content?.monthMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>출금액</th>
            <td>{content?.monthMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>입출금 차액</th>
            {getResultAmount(Number(content?.monthMoneyDepositAmount), Number(content?.monthMoneyWithdrawalAmount))}
          </tr>
          <tr>
            <th colSpan={2}>스포츠 배팅액</th>
            <td>{content?.monthSportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>스포츠 당첨액</th>
            <td> {content?.monthSportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>스포츠 수익</th>
            {getResultAmount(Number(content?.monthSportsBettingAmount), Number(content?.monthSportsWinningAmount))}
          </tr>
          <tr>
            <th colSpan={2}>라이브카지노 배팅액</th>
            <td>{content?.monthLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>라이브카지노 당첨액</th>
            <td> {content?.monthLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>라이브카지노 수익</th>
            {getResultAmount(
              Number(content?.monthLiveCasinoBettingAmount),
              Number(content?.monthLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>슬롯카지노 배팅액</th>
            <td>{content?.monthSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>슬롯카지노 당첨액</th>
            <td> {content?.monthSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>슬롯카지노 수익</th>
            {getResultAmount(
              Number(content?.monthSlotCasinoBettingAmount),
              Number(content?.monthSlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>미니게임 배팅액</th>
            <td>{content?.monthMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>미니게임 당첨액</th>
            <td> {content?.monthMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>스포츠 수익</th>
            {getResultAmount(Number(content?.monthMiniGameBettingAmount), Number(content?.monthMiniGameWinningAmount))}
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberDepositInfoForm

MemberDepositInfoForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  moneyDepositKey: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 320px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const MemberInfoHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`
