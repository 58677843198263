export const MoneySortEnums = {
  NORMAL_MONEY: '일반',
  HOLDEM_MONEY: '홀덤',
}

export const MemberDepositStatusEnums = {
  WAITING: '대기',
  APPROVE: '승인',
  CANCEL: '취소',
  APPLY: '신청',
}

export const MemberDepositStatusEnumsSelectOptions = () => {
  return Object.entries(MemberDepositStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberBounsTypeEnums = {
  BONUS_SPORTS: '[스포츠 이용] : 포인트를 받겠습니다.',
  BONUS_SPORTS_ETC: '[스포츠 이용] : 단폴,두폴,실시간 이용시 포인트를 받지 않겠습니다.',
  BONUS_CASINO: '[카지노,슬롯 이용] : 포인트를 받지 않겠습니다.',
}

export const MemberDepositTypeEnums = {
  FIRST: '첫충',
  EVERY: '매충',
  SURPRISE: '돌발',
  NONE: '-',
}

export const BonusStatusEnums = {
  0: '없음',
  5: '5%',
  10: '10%',
  15: '15%',
  20: '20%',
  25: '25%',
  30: '30%',
}

export const BonusStatusEnumsRadioOptions = () => {
  return Object.entries(BonusStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bonusPercent',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bonusPercent',
          label: value,
          radioValue: key,
        },
  )
}
