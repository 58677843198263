import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const BettingManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            {/* <NavItem>
              <NavLink href="/management/log/real-time-betting" eventKey="1">
                실시간 현황판
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/log/sport-betting')
                }}
              >
                스포츠 배팅내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/log/betting')
                }}
              >
                카지노 배팅내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/log/minigame-betting')
                }}
              >
                미니게임 배팅내역
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                eventKey="10"
                onClick={e => {
                  history.push('/management/log/tokengame-betting')
                }}
              >
                토큰게임 배팅내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/log/virtualgame-betting')
                }}
              >
                가상게임 배팅내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/log/holdem/revolution/betting-log')
                }}
              >
                레볼루션 홀덤 배팅내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/management/log/holdem/wild-games/betting-log')
                }}
              >
                와일드게임즈 배팅내역
              </NavLink>
            </NavItem> */}
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}
export default BettingManagementTabs

BettingManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
