import React from 'react'
import styled from 'styled-components'
import RealTimeCasinoBettingLogList from './RealTimeCasinoBettingLogList'
import RealTimeMiniBettingLogList from './RealTimeMiniBettingLogList'
import RealTimeSportBettingLogList from './RealTimeSportBettingLogList'

const RealTimeBettingLogList = () => {
  return (
    <RealTimeBettingLogWrap>
      <RealTimeBettingLogTitle>
        <h2>실시간 배팅 현황</h2>
        <p>
          * 미니게임과 카지노는 <span style={{ color: 'red', fontWeight: '600' }}>10초</span>단위로 조회합니다.
        </p>
      </RealTimeBettingLogTitle>
      <RealTimeBettingLogInnerWrap>
        <BettingLogWrap>
          <RealTimeMiniBettingLogList />
        </BettingLogWrap>
        <BettingLogWrap>
          <RealTimeCasinoBettingLogList />
        </BettingLogWrap>
      </RealTimeBettingLogInnerWrap>
      <RealTimeBettingLogInnerWrap>
        <BettingLogWrap>
          <RealTimeSportBettingLogList />
        </BettingLogWrap>
      </RealTimeBettingLogInnerWrap>
    </RealTimeBettingLogWrap>
  )
}

export default RealTimeBettingLogList

const RealTimeBettingLogWrap = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`

const RealTimeBettingLogTitle = styled.div`
  width: 100%;
  border-bottom: 2px solid #f2f4f7;

  h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    color: #000;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    margin-bottom: 5px;
  }
`

const RealTimeBettingLogInnerWrap = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
`

const BettingLogWrap = styled.div`
  width: 100%;
  padding: 10px 10px;

  h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
  }
`
