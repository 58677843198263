import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import Sidebar from './sidebar/Sidebar'
import PartnerTopbar from './topbar/PartnerTopbar'
import ScrollToTop from '../App/ScrollToTop'

const Layout = () => {
  const [isSidebarShown, setIsSidebarShown] = useState(false)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const changeSidebarVisibility = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  const changeMobileSidebarVisibility = () => {
    setIsSidebarShown(!isSidebarShown)
  }

  // 권한별 Topbar

  return (
    <LayoutContainer collapse={isSidebarCollapsed}>
      <PartnerTopbar />

      <Sidebar
        sidebarShow={isSidebarShown}
        collapse={isSidebarCollapsed}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </LayoutContainer>
  )
}

export default withRouter(Layout)
// export default Layout

// region STYLES

const LayoutContainer = styled.div``

// endregion
