export const SocketIORequestMessageType = {
  /**
   * INIT
   * 로그인 X 조회가 필요한 것
   * 스포츠 경기 수, 실시간 입출금, 공지/이벤트 게시글 등
   * */
  HOME_INIT: 'HOME_INIT',
  POPUP_INIT: 'POPUP_INIT',
  SPORT_INIT: 'SPORT_INIT',

  /**
   * Auth - USER
   * */
  USER_LOGIN: 'USER_LOGIN',
  AUTHENTICATION: 'AUTHENTICATION',
  RE_AUTHENTICATION: 'RE_AUTHENTICATION',

  /**
   * User
   * */
  USER_INFO: 'USER_INFO',
  USER_NOTE: 'USER_NOTE',
  USER_ONE_TO_ONE: 'USER_ONE_TO_ONE',

  /**
   * Log
   * */
  CLIENT_USER_LOG: 'CLIENT_USER_LOG',
}
