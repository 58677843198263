import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const EdgeSearchButton = ({ isAll, edgeName, onClickHandler }) => {
  const { edgeNames } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
    }
  })
  return (
    <EdgeSearchButtonBox>
      {isAll && (
        <Button
          size="sm"
          active={!edgeName}
          variant="outline-secondary"
          onClick={e => {
            onClickHandler(e)
          }}
        >
          전체
        </Button>
      )}
    </EdgeSearchButtonBox>
  )
}

export default EdgeSearchButton

EdgeSearchButton.propTypes = {
  isAll: PropTypes.bool,
  onClickHandler: PropTypes.func,
  edgeName: PropTypes.string,
}

EdgeSearchButton.defaultProps = {
  isAll: true,
  onClickHandler: () => {},
  edgeName: null,
}

const EdgeSearchButtonBox = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    // &:focus {
    //   background: grey;
    //   color: #fff;
    //   border-color: #4ce1b6;
    //   outline: none;
    // }
    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
