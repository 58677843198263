import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { BettingResultEnumsSelectOptions, bettingGameTypeEnumsSelectOptions } from '../../../enums/Log/bettingEnums'
import { useSearchBetting } from '../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../utils/dateTime'
import BettingManagementTabs from '../BettingManagementTabs'
import BettingLogListData from './BettingLogListData'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'

const BettingLogList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   배팅로그 조회
   */
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  // endDate 설정
  endDate.setHours(23)
  endDate.setMinutes(59)
  endDate.setSeconds(59)

  // startDate 설정
  startDate.setHours(0)
  startDate.setMinutes(0)
  startDate.setSeconds(0)

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchBetting } = useSearchBetting(bettingLogparams)

  const [bettingLogListData, setBettingLogListData] = useState(BettingLogListData(content))
  useEffect(() => {
    setBettingLogListData(BettingLogListData(content))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 제목
  const [searchTitle, setSearchTitle] = useState('')

  const onSearchTitleHandler = e => {
    setSearchTitle(e.target.value)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = BettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = bettingGameTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 게임 라운드 검색
  const [searchGameRound, setSearchGameRound] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      gameResult: !searchGameResult ? null : searchGameResult,
      gameType: !searchGameType ? null : searchGameType,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      gameRound: !searchGameRound ? null : searchGameRound,
      page: 0,
    }))
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('3')
  return (
    <Card>
      <CardBody>
        <BettingManagementTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지에서는 <span style={{ color: 'red' }}>본인, 직영 및 하부회원, 하부트너의</span> 배팅 내역만
              확인 할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임라운드</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchGameRound"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchGameRound}
                  onChange={e => setSearchGameRound(e.target.value)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="게임결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임종류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default BettingLogList
