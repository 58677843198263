import {
  Card,
  CardBody,
  PartnerSettlementCardSubhead,
  PartnerSettlementCardTitle,
  PartnerSettlementCardTitleWrap,
} from '@/shared/components/Card'
import Tooltip from '@/shared/components/Tooltip'
import PropTypes from 'prop-types'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchDatePickerWrap,
} from '@/shared/components/Search'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { searchDailyBettingStatistics } from '../../../utils/api/statistics/accumulateStatisticsApi'
import MemberManageMentTabs from '../../MemberManagement/MemberManageMentTabs'
import { convertToKst, convertToKstDate } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const DailyBettingStatisticsList = () => {
  /**
   * 일별 배팅 통계
   */
  const now = new Date()
  now.setDate(1)
  now.setHours(0, 0, 0, 0)
  const [params, setParams] = useState({
    startDate: convertToKst(now),
  })

  const [content, setContent] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [sumData, setSumData] = useState({
    casinoLiveBettingUserCount: 0,
    casinoLiveBettingAmount: 0,
    casinoLiveWinningAmount: 0,
    casinoLiveTotalProfitAmount: 0,
    casinoLiveTotalRollingAmount: 0,
    casinoLiveTotalLosingAmount: 0,
    casinoSlotBettingUserCount: 0,
    casinoSlotBettingAmount: 0,
    casinoSlotWinningAmount: 0,
    casinoSlotTotalProfitAmount: 0,
    casinoSlotTotalRollingAmount: 0,
    casinoSlotTotalLosingAmount: 0,
    miniGameBettingUserCount: 0,
    miniGameSingleBettingAmount: 0,
    miniGameSingleWinningAmount: 0,
    miniGameSingleTotalProfitAmount: 0,
    miniGameSingleTotalRollingAmount: 0,
    miniGameSingleTotalLosingAmount: 0,
    miniGameCombinationBettingAmount: 0,
    miniGameCombinationWinningAmount: 0,
    miniGameCombinationTotalProfitAmount: 0,
    miniGameCombinationTotalRollingAmount: 0,
    miniGameCombinationTotalLosingAmount: 0,
    sportsBettingUserCount: 0,
    sportsOneFolderBettingAmount: 0,
    sportsOneFolderWinningAmount: 0,
    sportsOneFolderTotalProfitAmount: 0,
    sportsOneFolderTotalRollingAmount: 0,
    sportsOneFolderTotalLosingAmount: 0,
    sportsTwoFolderBettingAmount: 0,
    sportsTwoFolderWinningAmount: 0,
    sportsTwoFolderTotalProfitAmount: 0,
    sportsTwoFolderTotalRollingAmount: 0,
    sportsTwoFolderTotalLosingAmount: 0,
    sportsThreeFolderBettingAmount: 0,
    sportsThreeFolderWinningAmount: 0,
    sportsThreeFolderTotalProfitAmount: 0,
    sportsThreeFolderTotalRollingAmount: 0,
    sportsThreeFolderTotalLosingAmount: 0,
    sportsFourFolderBettingAmount: 0,
    sportsFourFolderWinningAmount: 0,
    sportsFourFolderTotalProfitAmount: 0,
    sportsFourFolderTotalRollingAmount: 0,
    sportsFourFolderTotalLosingAmount: 0,
    sportsFiveFolderBettingAmount: 0,
    sportsFiveFolderWinningAmount: 0,
    sportsFiveFolderTotalProfitAmount: 0,
    sportsFiveFolderTotalRollingAmount: 0,
    sportsFiveFolderTotalLosingAmount: 0,
    sportsMultiFolderBettingAmount: 0,
    sportsMultiFolderWinningAmount: 0,
    sportsMultiFolderTotalProfitAmount: 0,
    sportsMultiFolderTotalRollingAmount: 0,
    sportsMultiFolderTotalLosingAmount: 0,
    virtualGameBettingUserCount: 0,
    virtualSoccerGameBettingAmount: 0,
    virtualSoccerGameWinningAmount: 0,
    virtualSoccerGameTotalProfitAmount: 0,
    virtualSoccerGameTotalRollingAmount: 0,
    virtualSoccerGameTotalLosingAmount: 0,
    virtualBaseballGameBettingAmount: 0,
    virtualBaseballGameWinningAmount: 0,
    virtualBaseballGameTotalProfitAmount: 0,
    virtualBaseballGameTotalRollingAmount: 0,
    virtualBaseballGameTotalLosingAmount: 0,
    virtualBasketballGameBettingAmount: 0,
    virtualBasketballGameWinningAmount: 0,
    virtualBasketballGameTotalProfitAmount: 0,
    virtualBasketballGameTotalRollingAmount: 0,
    virtualBasketballGameTotalLosingAmount: 0,
    virtualHorseGameBettingAmount: 0,
    virtualHorseGameWinningAmount: 0,
    virtualHorseGameTotalProfitAmount: 0,
    virtualHorseGameTotalRollingAmount: 0,
    virtualHorseGameTotalLosingAmount: 0,
    virtualGreyhoundsGameBettingAmount: 0,
    virtualGreyhoundsGameWinningAmount: 0,
    virtualGreyhoundsGameTotalProfitAmount: 0,
    virtualGreyhoundsGameTotalRollingAmount: 0,
    virtualGreyhoundsGameTotalLosingAmount: 0,
    holdemBettingAmount: 0,
    holdemWinningAmount: 0,
    holdemTotalProfitAmount: 0,
    holdemTotalRollingAmount: 0,
    holdemTotalLosingAmount: 0,
    holdemBettingUserCount: 0,
    totalDailyProfitAmount: 0,
  })

  const fetchSearchDailyBettingStatistics = async () => {
    setApiLoading(true)
    setSumData({
      casinoLiveBettingUserCount: 0,
      casinoLiveBettingAmount: 0,
      casinoLiveWinningAmount: 0,
      casinoLiveTotalProfitAmount: 0,
      casinoLiveTotalRollingAmount: 0,
      casinoLiveTotalLosingAmount: 0,
      casinoSlotBettingUserCount: 0,
      casinoSlotBettingAmount: 0,
      casinoSlotWinningAmount: 0,
      casinoSlotTotalProfitAmount: 0,
      casinoSlotTotalRollingAmount: 0,
      casinoSlotTotalLosingAmount: 0,
      miniGameBettingUserCount: 0,
      miniGameSingleBettingAmount: 0,
      miniGameSingleWinningAmount: 0,
      miniGameSingleTotalProfitAmount: 0,
      miniGameSingleTotalRollingAmount: 0,
      miniGameSingleTotalLosingAmount: 0,
      miniGameCombinationBettingAmount: 0,
      miniGameCombinationWinningAmount: 0,
      miniGameCombinationTotalProfitAmount: 0,
      miniGameCombinationTotalRollingAmount: 0,
      miniGameCombinationTotalLosingAmount: 0,
      sportsBettingUserCount: 0,
      sportsOneFolderBettingAmount: 0,
      sportsOneFolderWinningAmount: 0,
      sportsOneFolderTotalProfitAmount: 0,
      sportsOneFolderTotalRollingAmount: 0,
      sportsOneFolderTotalLosingAmount: 0,
      sportsTwoFolderBettingAmount: 0,
      sportsTwoFolderWinningAmount: 0,
      sportsTwoFolderTotalProfitAmount: 0,
      sportsTwoFolderTotalRollingAmount: 0,
      sportsTwoFolderTotalLosingAmount: 0,
      sportsThreeFolderBettingAmount: 0,
      sportsThreeFolderWinningAmount: 0,
      sportsThreeFolderTotalProfitAmount: 0,
      sportsThreeFolderTotalRollingAmount: 0,
      sportsThreeFolderTotalLosingAmount: 0,
      sportsFourFolderBettingAmount: 0,
      sportsFourFolderWinningAmount: 0,
      sportsFourFolderTotalProfitAmount: 0,
      sportsFourFolderTotalRollingAmount: 0,
      sportsFourFolderTotalLosingAmount: 0,
      sportsFiveFolderBettingAmount: 0,
      sportsFiveFolderWinningAmount: 0,
      sportsFiveFolderTotalProfitAmount: 0,
      sportsFiveFolderTotalRollingAmount: 0,
      sportsFiveFolderTotalLosingAmount: 0,
      sportsMultiFolderBettingAmount: 0,
      sportsMultiFolderWinningAmount: 0,
      sportsMultiFolderTotalProfitAmount: 0,
      sportsMultiFolderTotalRollingAmount: 0,
      sportsMultiFolderTotalLosingAmount: 0,
      virtualGameBettingUserCount: 0,
      virtualSoccerGameBettingAmount: 0,
      virtualSoccerGameWinningAmount: 0,
      virtualSoccerGameTotalProfitAmount: 0,
      virtualSoccerGameTotalRollingAmount: 0,
      virtualSoccerGameTotalLosingAmount: 0,
      virtualBaseballGameBettingAmount: 0,
      virtualBaseballGameWinningAmount: 0,
      virtualBaseballGameTotalProfitAmount: 0,
      virtualBaseballGameTotalRollingAmount: 0,
      virtualBaseballGameTotalLosingAmount: 0,
      virtualBasketballGameBettingAmount: 0,
      virtualBasketballGameWinningAmount: 0,
      virtualBasketballGameTotalProfitAmount: 0,
      virtualBasketballGameTotalRollingAmount: 0,
      virtualBasketballGameTotalLosingAmount: 0,
      virtualHorseGameBettingAmount: 0,
      virtualHorseGameWinningAmount: 0,
      virtualHorseGameTotalProfitAmount: 0,
      virtualHorseGameTotalRollingAmount: 0,
      virtualHorseGameTotalLosingAmount: 0,
      virtualGreyhoundsGameBettingAmount: 0,
      virtualGreyhoundsGameWinningAmount: 0,
      virtualGreyhoundsGameTotalProfitAmount: 0,
      virtualGreyhoundsGameTotalRollingAmount: 0,
      virtualGreyhoundsGameTotalLosingAmount: 0,
      holdemBettingAmount: 0,
      holdemWinningAmount: 0,
      holdemTotalProfitAmount: 0,
      holdemTotalRollingAmount: 0,
      holdemTotalLosingAmount: 0,
      holdemBettingUserCount: 0,
      totalDailyProfitAmount: 0,
    })
    await searchDailyBettingStatistics(params)
      .then(res => {
        setContent(res.data.content)
        setSumData(prev =>
          res.data.content.reduce(
            (acc, item) => ({
              totalDailyProfitAmount:
                acc.totalDailyProfitAmount +
                item.dailyCasinoBettingStatisticsModel?.casinoLiveTotalProfitAmount +
                item.dailyCasinoBettingStatisticsModel?.casinoSlotTotalProfitAmount +
                item.dailyMiniGameBettingStatisticsModel?.miniGameSingleTotalProfitAmount +
                item.dailyMiniGameBettingStatisticsModel?.miniGameCombinationTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsOneFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsTwoFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsThreeFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsFourFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsFiveFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel?.sportsMultiFolderTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameTotalProfitAmount,
              casinoLiveBettingUserCount:
                acc.casinoLiveBettingUserCount + item.dailyCasinoBettingStatisticsModel.casinoLiveBettingUserCount,
              casinoLiveBettingAmount:
                acc.casinoLiveBettingAmount + item.dailyCasinoBettingStatisticsModel.casinoLiveBettingAmount,
              casinoLiveWinningAmount:
                acc.casinoLiveWinningAmount + item.dailyCasinoBettingStatisticsModel.casinoLiveWinningAmount,
              casinoLiveTotalProfitAmount:
                acc.casinoLiveTotalProfitAmount + item.dailyCasinoBettingStatisticsModel.casinoLiveTotalProfitAmount,
              casinoLiveTotalRollingAmount:
                acc.casinoLiveTotalRollingAmount + item.dailyCasinoBettingStatisticsModel.casinoLiveTotalRollingAmount,
              casinoLiveTotalLosingAmount:
                acc.casinoLiveTotalLosingAmount + item.dailyCasinoBettingStatisticsModel.casinoLiveTotalLosingAmount,
              casinoSlotBettingUserCount:
                acc.casinoSlotBettingUserCount + item.dailyCasinoBettingStatisticsModel.casinoSlotBettingUserCount,
              casinoSlotBettingAmount:
                acc.casinoSlotBettingAmount + item.dailyCasinoBettingStatisticsModel.casinoSlotBettingAmount,
              casinoSlotWinningAmount:
                acc.casinoSlotWinningAmount + item.dailyCasinoBettingStatisticsModel.casinoSlotWinningAmount,
              casinoSlotTotalProfitAmount:
                acc.casinoSlotTotalProfitAmount + item.dailyCasinoBettingStatisticsModel.casinoSlotTotalProfitAmount,
              casinoSlotTotalRollingAmount:
                acc.casinoSlotTotalRollingAmount + item.dailyCasinoBettingStatisticsModel.casinoSlotTotalRollingAmount,
              casinoSlotTotalLosingAmount:
                acc.casinoSlotTotalLosingAmount + item.dailyCasinoBettingStatisticsModel.casinoSlotTotalLosingAmount,
              miniGameBettingUserCount:
                acc.miniGameBettingUserCount + item.dailyMiniGameBettingStatisticsModel.miniGameBettingUserCount,
              miniGameSingleBettingAmount:
                acc.miniGameSingleBettingAmount + item.dailyMiniGameBettingStatisticsModel.miniGameSingleBettingAmount,
              miniGameSingleWinningAmount:
                acc.miniGameSingleWinningAmount + item.dailyMiniGameBettingStatisticsModel.miniGameSingleWinningAmount,
              miniGameSingleTotalProfitAmount:
                acc.miniGameSingleTotalProfitAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameSingleTotalProfitAmount,
              miniGameSingleTotalRollingAmount:
                acc.miniGameSingleTotalRollingAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameSingleTotalRollingAmount,
              miniGameSingleTotalLosingAmount:
                acc.miniGameSingleTotalLosingAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameSingleTotalLosingAmount,
              miniGameCombinationBettingAmount:
                acc.miniGameCombinationBettingAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameCombinationBettingAmount,
              miniGameCombinationWinningAmount:
                acc.miniGameCombinationWinningAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameCombinationWinningAmount,
              miniGameCombinationTotalProfitAmount:
                acc.miniGameCombinationTotalProfitAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameCombinationTotalProfitAmount,
              miniGameCombinationTotalRollingAmount:
                acc.miniGameCombinationTotalRollingAmount +
                item.dailyMiniGameBettingStatisticsModel.miniGameCombinationTotalRollingAmount,
              miniGameCombinationTotalLosingAmount:
                acc.miniGameCombinationTotalLosingAmount +
                item.dailyMiniGameBettingStatisticsModel?.miniGameCombinationTotalLosingAmount,
              virtualGameBettingUserCount:
                acc.virtualGameBettingUserCount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGameBettingUserCount,
              virtualSoccerGameBettingAmount:
                acc.virtualSoccerGameBettingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameBettingAmount,
              virtualSoccerGameWinningAmount:
                acc.virtualSoccerGameWinningAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameWinningAmount,
              virtualSoccerGameTotalProfitAmount:
                acc.virtualSoccerGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameTotalProfitAmount,
              virtualSoccerGameTotalRollingAmount:
                acc.virtualSoccerGameTotalRollingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameTotalRollingAmount,
              virtualSoccerGameTotalLosingAmount:
                acc.virtualSoccerGameTotalLosingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameTotalLosingAmount,
              virtualBaseballGameBettingAmount:
                acc.virtualBaseballGameBettingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameBettingAmount,
              virtualBaseballGameWinningAmount:
                acc.virtualBaseballGameWinningAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameWinningAmount,
              virtualBaseballGameTotalProfitAmount:
                acc.virtualBaseballGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameTotalProfitAmount,
              virtualBaseballGameTotalRollingAmount:
                acc.virtualBaseballGameTotalRollingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameTotalRollingAmount,
              virtualBaseballGameTotalLosingAmount:
                acc.virtualBaseballGameTotalLosingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameTotalLosingAmount,
              virtualBasketballGameBettingAmount:
                acc.virtualBasketballGameBettingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameBettingAmount,
              virtualBasketballGameWinningAmount:
                acc.virtualBasketballGameWinningAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameWinningAmount,
              virtualBasketballGameTotalProfitAmount:
                acc.virtualBasketballGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameTotalProfitAmount,
              virtualBasketballGameTotalRollingAmount:
                acc.virtualBasketballGameTotalRollingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameTotalRollingAmount,
              virtualBasketballGameTotalLosingAmount:
                acc.virtualBasketballGameTotalLosingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameTotalLosingAmount,
              virtualHorseGameBettingAmount:
                acc.virtualHorseGameBettingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameBettingAmount,
              virtualHorseGameWinningAmount:
                acc.virtualHorseGameWinningAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameWinningAmount,
              virtualHorseGameTotalProfitAmount:
                acc.virtualHorseGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameTotalProfitAmount,
              virtualHorseGameTotalRollingAmount:
                acc.virtualHorseGameTotalRollingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameTotalRollingAmount,
              virtualHorseGameTotalLosingAmount:
                acc.virtualHorseGameTotalLosingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameTotalLosingAmount,
              virtualGreyhoundsGameBettingAmount:
                acc.virtualGreyhoundsGameBettingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameBettingAmount,
              virtualGreyhoundsGameWinningAmount:
                acc.virtualGreyhoundsGameWinningAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameWinningAmount,
              virtualGreyhoundsGameTotalProfitAmount:
                acc.virtualGreyhoundsGameTotalProfitAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameTotalProfitAmount,
              virtualGreyhoundsGameTotalRollingAmount:
                acc.virtualGreyhoundsGameTotalRollingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameTotalRollingAmount,
              virtualGreyhoundsGameTotalLosingAmount:
                acc.virtualGreyhoundsGameTotalLosingAmount +
                item.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameTotalLosingAmount,
              sportsBettingUserCount:
                acc.sportsBettingUserCount + item.dailySportsBettingStatisticsModel.sportsBettingUserCount,
              sportsOneFolderBettingAmount:
                acc.sportsOneFolderBettingAmount + item.dailySportsBettingStatisticsModel.sportsOneFolderBettingAmount,
              sportsOneFolderWinningAmount:
                acc.sportsOneFolderWinningAmount + item.dailySportsBettingStatisticsModel.sportsOneFolderWinningAmount,
              sportsOneFolderTotalProfitAmount:
                acc.sportsOneFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsOneFolderTotalProfitAmount,
              sportsOneFolderTotalRollingAmount:
                acc.sportsOneFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsOneFolderTotalRollingAmount,
              sportsOneFolderTotalLosingAmount:
                acc.sportsOneFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsOneFolderTotalLosingAmount,
              sportsTwoFolderBettingAmount:
                acc.sportsTwoFolderBettingAmount + item.dailySportsBettingStatisticsModel.sportsTwoFolderBettingAmount,
              sportsTwoFolderWinningAmount:
                acc.sportsTwoFolderWinningAmount + item.dailySportsBettingStatisticsModel.sportsTwoFolderWinningAmount,
              sportsTwoFolderTotalProfitAmount:
                acc.sportsTwoFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsTwoFolderTotalProfitAmount,
              sportsTwoFolderTotalRollingAmount:
                acc.sportsTwoFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsTwoFolderTotalRollingAmount,
              sportsTwoFolderTotalLosingAmount:
                acc.sportsTwoFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsTwoFolderTotalLosingAmount,
              sportsThreeFolderBettingAmount:
                acc.sportsThreeFolderBettingAmount +
                item.dailySportsBettingStatisticsModel.sportsThreeFolderBettingAmount,
              sportsThreeFolderWinningAmount:
                acc.sportsThreeFolderWinningAmount +
                item.dailySportsBettingStatisticsModel.sportsThreeFolderWinningAmount,
              sportsThreeFolderTotalProfitAmount:
                acc.sportsThreeFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsThreeFolderTotalProfitAmount,
              sportsThreeFolderTotalRollingAmount:
                acc.sportsThreeFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsThreeFolderTotalRollingAmount,
              sportsThreeFolderTotalLosingAmount:
                acc.sportsThreeFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsThreeFolderTotalLosingAmount,
              sportsFourFolderBettingAmount:
                acc.sportsFourFolderBettingAmount +
                item.dailySportsBettingStatisticsModel.sportsFourFolderBettingAmount,
              sportsFourFolderWinningAmount:
                acc.sportsFourFolderWinningAmount +
                item.dailySportsBettingStatisticsModel.sportsFourFolderWinningAmount,
              sportsFourFolderTotalProfitAmount:
                acc.sportsFourFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsFourFolderTotalProfitAmount,
              sportsFourFolderTotalRollingAmount:
                acc.sportsFourFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsFourFolderTotalRollingAmount,
              sportsFourFolderTotalLosingAmount:
                acc.sportsFourFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsFourFolderTotalLosingAmount,
              sportsFiveFolderBettingAmount:
                acc.sportsFiveFolderBettingAmount +
                item.dailySportsBettingStatisticsModel.sportsFiveFolderBettingAmount,
              sportsFiveFolderWinningAmount:
                acc.sportsFiveFolderWinningAmount +
                item.dailySportsBettingStatisticsModel.sportsFiveFolderWinningAmount,
              sportsFiveFolderTotalProfitAmount:
                acc.sportsFiveFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsFiveFolderTotalProfitAmount,
              sportsFiveFolderTotalRollingAmount:
                acc.sportsFiveFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsFiveFolderTotalRollingAmount,
              sportsFiveFolderTotalLosingAmount:
                acc.sportsFiveFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsFiveFolderTotalLosingAmount,
              sportsMultiFolderBettingAmount:
                acc.sportsMultiFolderBettingAmount +
                item.dailySportsBettingStatisticsModel.sportsMultiFolderBettingAmount,
              sportsMultiFolderWinningAmount:
                acc.sportsMultiFolderWinningAmount +
                item.dailySportsBettingStatisticsModel.sportsMultiFolderWinningAmount,
              sportsMultiFolderTotalProfitAmount:
                acc.sportsMultiFolderTotalProfitAmount +
                item.dailySportsBettingStatisticsModel.sportsMultiFolderTotalProfitAmount,
              sportsMultiFolderTotalRollingAmount:
                acc.sportsMultiFolderTotalRollingAmount +
                item.dailySportsBettingStatisticsModel.sportsMultiFolderTotalRollingAmount,
              sportsMultiFolderTotalLosingAmount:
                acc.sportsMultiFolderTotalLosingAmount +
                item.dailySportsBettingStatisticsModel.sportsMultiFolderTotalLosingAmount,
              holdemBettingUserCount:
                acc.holdemBettingUserCount + item.dailyHoldemBettingStatisticsModel.holdemBettingUserCount,
              holdemBettingAmount: acc.holdemBettingAmount + item.dailyHoldemBettingStatisticsModel.holdemBettingAmount,
              holdemWinningAmount: acc.holdemWinningAmount + item.dailyHoldemBettingStatisticsModel.holdemWinningAmount,
              holdemTotalProfitAmount:
                acc.holdemTotalProfitAmount + item.dailyHoldemBettingStatisticsModel.holdemTotalProfitAmount,
              holdemTotalRollingAmount:
                acc.holdemTotalRollingAmount + item.dailyHoldemBettingStatisticsModel.holdemTotalRollingAmount,
              holdemTotalLosingAmount:
                acc.holdemTotalLosingAmount + item.dailyHoldemBettingStatisticsModel.holdemTotalLosingAmount,
            }),
            {
              totalDailyProfitAmount: prev.totalDailyProfitAmount,
              casinoLiveBettingUserCount: prev.casinoLiveBettingUserCount,
              casinoLiveBettingAmount: prev.casinoLiveBettingAmount,
              casinoLiveWinningAmount: prev.casinoLiveWinningAmount,
              casinoLiveTotalProfitAmount: prev.casinoLiveTotalProfitAmount,
              casinoLiveTotalRollingAmount: prev.casinoLiveTotalRollingAmount,
              casinoLiveTotalLosingAmount: prev.casinoLiveTotalLosingAmount,
              casinoSlotBettingUserCount: prev.casinoSlotBettingUserCount,
              casinoSlotBettingAmount: prev.casinoSlotBettingAmount,
              casinoSlotWinningAmount: prev.casinoSlotWinningAmount,
              casinoSlotTotalProfitAmount: prev.casinoSlotTotalProfitAmount,
              casinoSlotTotalRollingAmount: prev.casinoSlotTotalRollingAmount,
              casinoSlotTotalLosingAmount: prev.casinoSlotTotalLosingAmount,
              miniGameBettingUserCount: prev.miniGameBettingUserCount,
              miniGameSingleBettingAmount: prev.miniGameSingleBettingAmount,
              miniGameSingleWinningAmount: prev.miniGameSingleWinningAmount,
              miniGameSingleTotalProfitAmount: prev.miniGameSingleTotalProfitAmount,
              miniGameSingleTotalRollingAmount: prev.miniGameSingleTotalRollingAmount,
              miniGameSingleTotalLosingAmount: prev.miniGameSingleTotalLosingAmount,
              miniGameCombinationBettingAmount: prev.miniGameCombinationBettingAmount,
              miniGameCombinationWinningAmount: prev.miniGameCombinationWinningAmount,
              miniGameCombinationTotalProfitAmount: prev.miniGameCombinationTotalProfitAmount,
              miniGameCombinationTotalRollingAmount: prev.miniGameCombinationTotalRollingAmount,
              miniGameCombinationTotalLosingAmount: prev.miniGameCombinationTotalLosingAmount,
              virtualSoccerGameBettingAmount: prev.virtualSoccerGameBettingAmount,
              virtualSoccerGameWinningAmount: prev.virtualSoccerGameWinningAmount,
              virtualSoccerGameTotalProfitAmount: prev.virtualSoccerGameTotalProfitAmount,
              virtualSoccerGameTotalRollingAmount: prev.virtualSoccerGameTotalRollingAmount,
              virtualSoccerGameTotalLosingAmount: prev.virtualSoccerGameTotalLosingAmount,
              virtualBaseballGameBettingAmount: prev.virtualBaseballGameBettingAmount,
              virtualBaseballGameWinningAmount: prev.virtualBaseballGameWinningAmount,
              virtualBaseballGameTotalProfitAmount: prev.virtualBaseballGameTotalProfitAmount,
              virtualBaseballGameTotalRollingAmount: prev.virtualBaseballGameTotalRollingAmount,
              virtualBaseballGameTotalLosingAmount: prev.virtualBaseballGameTotalLosingAmount,
              virtualBasketballGameBettingAmount: prev.virtualBasketballGameBettingAmount,
              virtualBasketballGameWinningAmount: prev.virtualBasketballGameWinningAmount,
              virtualBasketballGameTotalProfitAmount: prev.virtualBasketballGameTotalProfitAmount,
              virtualBasketballGameTotalRollingAmount: prev.virtualBasketballGameTotalRollingAmount,
              virtualBasketballGameTotalLosingAmount: prev.virtualBasketballGameTotalLosingAmount,
              virtualHorseGameBettingAmount: prev.virtualHorseGameBettingAmount,
              virtualHorseGameWinningAmount: prev.virtualHorseGameWinningAmount,
              virtualHorseGameTotalProfitAmount: prev.virtualHorseGameTotalProfitAmount,
              virtualHorseGameTotalRollingAmount: prev.virtualHorseGameTotalRollingAmount,
              virtualHorseGameTotalLosingAmount: prev.virtualHorseGameTotalLosingAmount,
              virtualGreyhoundsGameBettingAmount: prev.virtualGreyhoundsGameBettingAmount,
              virtualGreyhoundsGameWinningAmount: prev.virtualGreyhoundsGameWinningAmount,
              virtualGreyhoundsGameTotalProfitAmount: prev.virtualGreyhoundsGameTotalProfitAmount,
              virtualGreyhoundsGameTotalRollingAmount: prev.virtualGreyhoundsGameTotalRollingAmount,
              virtualGreyhoundsGameTotalLosingAmount: prev.virtualGreyhoundsGameTotalLosingAmount,
              virtualGameBettingUserCount: prev.virtualGameBettingUserCount,
              sportsBettingUserCount: prev.sportsBettingUserCount,
              sportsOneFolderBettingAmount: prev.sportsOneFolderBettingAmount,
              sportsOneFolderWinningAmount: prev.sportsOneFolderWinningAmount,
              sportsOneFolderTotalProfitAmount: prev.sportsOneFolderTotalProfitAmount,
              sportsOneFolderTotalRollingAmount: prev.sportsOneFolderTotalRollingAmount,
              sportsOneFolderTotalLosingAmount: prev.sportsOneFolderTotalLosingAmount,
              sportsTwoFolderBettingAmount: prev.sportsTwoFolderBettingAmount,
              sportsTwoFolderWinningAmount: prev.sportsTwoFolderWinningAmount,
              sportsTwoFolderTotalProfitAmount: prev.sportsTwoFolderTotalProfitAmount,
              sportsTwoFolderTotalRollingAmount: prev.sportsTwoFolderTotalRollingAmount,
              sportsTwoFolderTotalLosingAmount: prev.sportsTwoFolderTotalLosingAmount,
              sportsThreeFolderBettingAmount: prev.sportsThreeFolderBettingAmount,
              sportsThreeFolderWinningAmount: prev.sportsThreeFolderWinningAmount,
              sportsThreeFolderTotalProfitAmount: prev.sportsThreeFolderTotalProfitAmount,
              sportsThreeFolderTotalRollingAmount: prev.sportsThreeFolderTotalRollingAmount,
              sportsThreeFolderTotalLosingAmount: prev.sportsThreeFolderTotalLosingAmount,
              sportsFourFolderBettingAmount: prev.sportsFourFolderBettingAmount,
              sportsFourFolderWinningAmount: prev.sportsFourFolderWinningAmount,
              sportsFourFolderTotalProfitAmount: prev.sportsFourFolderTotalProfitAmount,
              sportsFourFolderTotalRollingAmount: prev.sportsFourFolderTotalRollingAmount,
              sportsFourFolderTotalLosingAmount: prev.sportsFourFolderTotalLosingAmount,
              sportsFiveFolderBettingAmount: prev.sportsFiveFolderBettingAmount,
              sportsFiveFolderWinningAmount: prev.sportsFiveFolderWinningAmount,
              sportsFiveFolderTotalProfitAmount: prev.sportsFiveFolderTotalProfitAmount,
              sportsFiveFolderTotalRollingAmount: prev.sportsFiveFolderTotalRollingAmount,
              sportsFiveFolderTotalLosingAmount: prev.sportsFiveFolderTotalLosingAmount,
              sportsMultiFolderBettingAmount: prev.sportsMultiFolderBettingAmount,
              sportsMultiFolderWinningAmount: prev.sportsMultiFolderWinningAmount,
              sportsMultiFolderTotalProfitAmount: prev.sportsMultiFolderTotalProfitAmount,
              sportsMultiFolderTotalRollingAmount: prev.sportsMultiFolderTotalRollingAmount,
              sportsMultiFolderTotalLosingAmount: prev.sportsMultiFolderTotalLosingAmount,
              holdemBettingUserCount: prev.holdemBettingUserCount,
              holdemBettingAmount: prev.holdemBettingAmount,
              holdemWinningAmount: prev.holdemWinningAmount,
              holdemTotalProfitAmount: prev.holdemTotalProfitAmount,
              holdemTotalRollingAmount: prev.holdemTotalRollingAmount,
              holdemTotalLosingAmount: prev.holdemTotalLosingAmount,
            },
          ),
        )
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchDailyBettingStatistics()
  }, [params])

  const [searchStartDate, setSearchStartDate] = useState(now)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams({
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      dailyStartDate: null,
      dailyEndDate: null,
    })
  }

  const adjustTimes = date => {
    if (!date) return
    const adjustedDate = new Date(date)
    adjustedDate.setHours(0, 0, 0) // 초만 59로 조정
    return adjustedDate
  }

  // 검색하기 버튼
  const onSearchClickHandler2 = () => {
    if (!searchExecuteStartDate || !searchExecuteEndDate) {
      alert('일별검색시 시작일과 종료일 설정은 필수입니다.')
    }

    const adjustedStartDate = adjustTimes(searchExecuteStartDate)
    const adjustedEndDate = adjustTimes(searchExecuteEndDate)

    setParams({
      startDate: null,
      dailyStartDate: !searchExecuteStartDate ? null : convertToKst(adjustedStartDate),
      dailyEndDate: !searchExecuteEndDate ? null : convertToKst(adjustedEndDate),
    })
  }

  // 라이브 베팅통계
  const [isActiveLiveInfo, setIsActiveLiveInfo] = useState(true)
  // 슬롯 베팅통계
  const [isActiveSlotInfo, setIsActiveSlotInfo] = useState(true)
  // 홀덤 베팅통계
  const [isActiveHoldemInfo, setIsActiveHoldemInfo] = useState(false)
  // 미니게임 베팅통계
  const [isActiveMiniGameInfo, setIsActiveMiniGameInfo] = useState(false)
  // 가상게임 베팅통계
  const [isActiveVirtualGameInfo, setIsActiveVirtualGameInfo] = useState(false)
  // 스포츠 베팅통계
  const [isActiveSportsInfo, setIsActiveSportsInfo] = useState(false)

  const sumSixData = (d1, d2, d3, d4, d5, d6) => {
    return (Number(d1) + Number(d2) + Number(d3) + Number(d4) + Number(d5) + Number(d6))
      .toString()
      .replace(commonReg2, ',')
  }

  const sumTwoData = (d1, d2) => {
    return (Number(d1) + Number(d2)).toString().replace(commonReg2, ',')
  }

  const sumFiveData = (d1, d2, d3, d4, d5) => {
    return (Number(d1) + Number(d2) + Number(d3) + Number(d4) + Number(d5)).toString().replace(commonReg2, ',')
  }
  return (
    <>
      <Card style={{ marginTop: '30px' }}>
        <CardBody style={{ padding: '0px' }}>
          <PartnerSettlementCardTitleWrap>
            <PartnerSettlementCardTitle>일별 배팅 통계</PartnerSettlementCardTitle>
            <PartnerSettlementCardSubhead>
              <p>
                * 본인 포함{' '}
                <span style={{ fontWeight: 'bolder', color: 'red', fontSize: '12px' }}>
                  직영/하부 회원 및 하위 파트너{' '}
                </span>
                들의 일별 배팅 통계를 확인 하실 수 있습니다.
              </p>
              <p style={{ color: 'black' }}>
                * 거래회원수는 동일한 ID로 여러번 베팅해도
                <span style={{ color: 'black', fontWeight: 'bolder' }}>1회로</span> 값이 책정됩니다.
              </p>
            </PartnerSettlementCardSubhead>
          </PartnerSettlementCardTitleWrap>
          <TableColumnSearchButtonBox>
            <Button
              size="sm"
              active={isActiveLiveInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveLiveInfo(prev => !prev)
              }}
            >
              라이브 베팅통계
            </Button>
            <Button
              id="MEMBER"
              size="sm"
              active={isActiveSlotInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveSlotInfo(prev => !prev)
              }}
            >
              슬롯 베팅통계
            </Button>
            <Button
              id="PARTNER"
              size="sm"
              active={isActiveMiniGameInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveMiniGameInfo(prev => !prev)
              }}
            >
              미니게임 베팅통계
            </Button>
            <Button
              id="PARTNER"
              size="sm"
              active={isActiveSportsInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveSportsInfo(prev => !prev)
              }}
            >
              스포츠 베팅통계
            </Button>
            <Button
              id="PARTNER"
              size="sm"
              active={isActiveVirtualGameInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveVirtualGameInfo(prev => !prev)
              }}
            >
              가상게임 베팅통계
            </Button>
            <Button
              id="MEMBER"
              size="sm"
              active={isActiveHoldemInfo}
              variant="outline-secondary"
              onClick={e => {
                setIsActiveHoldemInfo(prev => !prev)
              }}
            >
              홀덤 베팅통계
            </Button>
          </TableColumnSearchButtonBox>
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>날짜</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    locale={ko}
                    showMonthYearPicker
                    isClearable
                    placeholderText="날짜"
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                월별 검색하기
              </Button>
            </SearchButtonWrap>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchExecuteStartDate}
                    onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd 00:00:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchExecuteEndDate}
                    onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd 00:00:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler2()
                }}
              >
                일별 검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          {apiLoading ? (
            <Loading />
          ) : (
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th scope="col" rowSpan={3}>
                      정산일
                    </th>
                    <CustomTh scope="col" rowSpan={3} colorText="isMain" textColor="profit">
                      총 거래회원수 <br />
                      (카지노+미니+스포츠+홀덤+가상)
                    </CustomTh>
                    {isActiveLiveInfo && (
                      <CustomTh scope="col" colSpan={4} colorText="isActiveLiveInfo">
                        라이브 일별 배팅통계
                      </CustomTh>
                    )}
                    {isActiveSlotInfo && (
                      <CustomTh scope="col" colSpan={4} colorText="isActiveSlotInfo">
                        슬롯 일별 배팅통계
                      </CustomTh>
                    )}
                    {isActiveMiniGameInfo && (
                      <CustomTh scope="col" colSpan={7} colorText="isActiveMiniGameInfo">
                        미니게임 일별 배팅통계
                      </CustomTh>
                    )}

                    {isActiveSportsInfo && (
                      <CustomTh scope="col" colSpan={19} colorText="isActiveSportsInfo">
                        스포츠 일별 배팅통계
                      </CustomTh>
                    )}
                    {isActiveVirtualGameInfo && (
                      <CustomTh scope="col" colSpan={16} colorText="isActiveVirtualGameInfo">
                        가상게임 일별 배팅통계
                      </CustomTh>
                    )}
                    {isActiveHoldemInfo && (
                      <CustomTh scope="col" colSpan={4} colorText="isActiveHoldemInfo">
                        홀덤 일별 배팅통계
                      </CustomTh>
                    )}
                  </tr>
                  <tr>
                    {isActiveLiveInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveLiveInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveLiveInfo">
                          라이브 배팅액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveLiveInfo">
                          라이브 당첨액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveLiveInfo" textColor="profit">
                          라이브 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                    {isActiveSlotInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveSlotInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveSlotInfo">
                          슬롯 배팅액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveSlotInfo">
                          슬롯 당첨액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveSlotInfo" textColor="profit">
                          슬롯 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                    {isActiveMiniGameInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveMiniGameInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" colSpan={2} colorText="isActiveMiniGameInfo">
                          미니게임 배팅액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={2} colorText="isActiveMiniGameInfo">
                          미니게임 당첨액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={2} colorText="isActiveMiniGameInfo" textColor="profit">
                          미니게임 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                    {isActiveSportsInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveSportsInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" colSpan={6} colorText="isActiveSportsInfo">
                          스포츠 배팅액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={6} colorText="isActiveSportsInfo">
                          스포츠 당첨액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={6} colorText="isActiveSportsInfo" textColor="profit">
                          스포츠 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                    {isActiveVirtualGameInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveVirtualGameInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" colSpan={5} colorText="isActiveVirtualGameInfo">
                          가상게임 배팅액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={5} colorText="isActiveVirtualGameInfo">
                          가상게임 당첨액
                        </CustomTh>
                        <CustomTh scope="col" colSpan={5} colorText="isActiveVirtualGameInfo" textColor="profit">
                          가상게임 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                    {isActiveHoldemInfo && (
                      <>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo">
                          거래 회원수
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo">
                          홀덤 배팅액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo">
                          홀덤 당첨액
                        </CustomTh>
                        <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo" textColor="profit">
                          홀덤 수익(배팅-당첨)
                        </CustomTh>
                      </>
                    )}
                  </tr>
                  <tr>
                    {isActiveMiniGameInfo && (
                      <>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col">
                          일반
                        </CustomTh>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col">
                          조합
                        </CustomTh>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col">
                          일반
                        </CustomTh>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col">
                          조합
                        </CustomTh>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col" textColor="profit">
                          일반
                        </CustomTh>
                        <CustomTh colorText="isActiveMiniGameInfo" scope="col" textColor="profit">
                          조합
                        </CustomTh>
                      </>
                    )}
                    {isActiveSportsInfo && (
                      <>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          단폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          2폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          3폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          4폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          5폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          다폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          단폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          2폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          3폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          4폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          5폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col">
                          다폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          단폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          2폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          3폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          4폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          5폴더
                        </CustomTh>
                        <CustomTh colorText="isActiveSportsInfo" scope="col" textColor="profit">
                          다폴더
                        </CustomTh>
                      </>
                    )}
                    {isActiveVirtualGameInfo && (
                      <>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          축구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          야구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          농구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          경마
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          개경주
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          축구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          야구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          농구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          경마
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          개경주
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          축구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          야구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          농구
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          경마
                        </CustomTh>
                        <CustomTh colorText="isActiveVirtualGameInfo" scope="col">
                          개경주
                        </CustomTh>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {/* 합계 */}
                  {content.length !== 0 && (
                    <tr style={{ background: 'rgba(255,24,8,0.3)', color: 'red' }}>
                      <td>합계</td>
                      <td>
                        {(
                          sumData?.casinoLiveBettingUserCount +
                          sumData?.casinoSlotBettingUserCount +
                          sumData?.miniGameBettingUserCount +
                          sumData?.sportsBettingUserCount +
                          sumData?.holdemBettingUserCount +
                          sumData?.virtualGameBettingUserCount
                        )
                          ?.toString()
                          .replace(commonReg2, ',')}
                      </td>
                      {isActiveLiveInfo && (
                        <>
                          {/* 라이브 합계 */}
                          <td>{sumData?.casinoLiveBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoLiveBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoLiveWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoLiveTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                        </>
                      )}
                      {isActiveSlotInfo && (
                        <>
                          {/* 슬롯 합계 */}
                          <td>{sumData?.casinoSlotBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoSlotBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoSlotWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.casinoSlotTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                        </>
                      )}
                      {isActiveMiniGameInfo && (
                        <>
                          <td>{sumData?.miniGameBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameSingleBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameCombinationBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameSingleWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameCombinationWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameSingleTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.miniGameCombinationTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                        </>
                      )}
                      {isActiveSportsInfo && (
                        <>
                          <td>{sumData?.sportsBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          {/* 스포츠 배팅액 합계 */}
                          <td>{sumData?.sportsOneFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsTwoFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsThreeFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFourFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFiveFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsMultiFolderBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          {/* 스포츠 당첨액 합계 */}
                          <td>{sumData?.sportsOneFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsTwoFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsThreeFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFourFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFiveFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsMultiFolderWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          {/* 스포츠 수익 */}
                          <td>{sumData?.sportsOneFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsTwoFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsThreeFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFourFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsFiveFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.sportsMultiFolderTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                        </>
                      )}
                      {isActiveVirtualGameInfo && (
                        <>
                          <td>{sumData?.virtualGameBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          {/* 스포츠 배팅액 합계 */}
                          <td>{sumData?.virtualSoccerGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualBaseballGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualBasketballGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualHorseGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualGreyhoundsGameBettingAmount?.toString().replace(commonReg2, ',')}</td>

                          {/* 스포츠 당첨액 합계 */}
                          <td>{sumData?.virtualSoccerGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualBaseballGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualBasketballGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualHorseGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualGreyhoundsGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          {/* 스포츠 수익 */}
                          <td>{sumData?.virtualSoccerGameTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.virtualBaseballGameTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>
                            {sumData?.virtualBasketballGameTotalProfitAmount?.toString().replace(commonReg2, ',')}
                          </td>
                          <td>{sumData?.virtualHorseGameTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>
                            {sumData?.virtualGreyhoundsGameTotalProfitAmount?.toString().replace(commonReg2, ',')}
                          </td>
                        </>
                      )}
                      {isActiveHoldemInfo && (
                        <>
                          {/* 홀덤 합계 */}
                          <td>{sumData?.holdemBettingUserCount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.holdemBettingAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.holdemWinningAmount?.toString().replace(commonReg2, ',')}</td>
                          <td>{sumData?.holdemTotalProfitAmount?.toString().replace(commonReg2, ',')}</td>
                        </>
                      )}
                    </tr>
                  )}
                  {content?.map((original, index) => (
                    <>
                      <tr>
                        <td>{convertToKstDate(original?.settlementDate)}</td>
                        <td>
                          {(
                            original?.dailyCasinoBettingStatisticsModel?.casinoLiveBettingUserCount +
                            original?.dailyCasinoBettingStatisticsModel?.casinoSlotBettingUserCount +
                            original?.dailyMiniGameBettingStatisticsModel?.miniGameBettingUserCount +
                            original?.dailySportsBettingStatisticsModel?.sportsBettingUserCount +
                            original?.dailyHoldemBettingStatisticsModel?.holdemBettingUserCount
                          )
                            ?.toString()
                            .replace(commonReg2, ',')}
                        </td>
                        {isActiveLiveInfo && (
                          <>
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 라이브베팅 ID: ${
                                  original?.dailyCasinoBettingStatisticsModel?.casinoLiveBettingUserCount > 0
                                    ? original.dailyCasinoBettingStatisticsModel?.casinoLiveBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailyCasinoBettingStatisticsModel?.casinoLiveBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            {/* 라이브 배팅액 */}
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoLiveBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoLiveWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoLiveTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                        {isActiveSlotInfo && (
                          <>
                            {/* 슬롯 배팅액 */}
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 슬롯베팅 ID: ${
                                  original?.dailyCasinoBettingStatisticsModel?.casinoSlotBettingUserCount > 0
                                    ? original.dailyCasinoBettingStatisticsModel?.casinoSlotBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailyCasinoBettingStatisticsModel?.casinoSlotBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoSlotBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoSlotWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyCasinoBettingStatisticsModel?.casinoSlotTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                        {/* 미니게임 */}
                        {isActiveMiniGameInfo && (
                          <>
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 미니베팅 ID: ${
                                  original?.dailyMiniGameBettingStatisticsModel?.miniGameBettingUserCount > 0
                                    ? original.dailyMiniGameBettingStatisticsModel?.miniGameBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailyMiniGameBettingStatisticsModel?.miniGameBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameSingleBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameCombinationBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameSingleWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameCombinationWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameSingleTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyMiniGameBettingStatisticsModel?.miniGameCombinationTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                        {isActiveSportsInfo && (
                          <>
                            {/* 스포츠 배팅액 */}
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 스포츠베팅 ID: ${
                                  original?.dailySportsBettingStatisticsModel?.sportsBettingUserCount > 0
                                    ? original.dailySportsBettingStatisticsModel?.sportsBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailySportsBettingStatisticsModel?.sportsBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsOneFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsTwoFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsThreeFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFourFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFiveFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsMultiFolderBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            {/* 스포츠 당첨액 */}
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsOneFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsTwoFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsThreeFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFourFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFiveFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsMultiFolderWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>

                            {/* 스포츠 수익 */}
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsOneFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsTwoFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsThreeFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFourFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsFiveFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailySportsBettingStatisticsModel?.sportsMultiFolderTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                        {isActiveVirtualGameInfo && (
                          <>
                            {/* 스포츠 배팅액 */}
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 스포츠베팅 ID: ${
                                  original?.dailyVirtualGameBettingStatisticsModel?.virtualGameBettingUserCount > 0
                                    ? original.dailyVirtualGameBettingStatisticsModel?.virtualGameBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailyVirtualGameBettingStatisticsModel?.virtualGameBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            {/* 스포츠 당첨액 */}
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            {/* 스포츠 수익 */}
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualSoccerGameTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBaseballGameTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualBasketballGameTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualHorseGameTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyVirtualGameBettingStatisticsModel?.virtualGreyhoundsGameTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                        {isActiveHoldemInfo && (
                          <>
                            {/* 홀덤 배팅액 */}
                            <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                              <TooltipWrapper
                                text={`${convertToKstDate(original?.settlementDate)} 홀덤베팅 ID: ${
                                  original?.dailyHoldemBettingStatisticsModel?.holdemBettingUserCount > 0
                                    ? original.dailyHoldemBettingStatisticsModel?.holdemBettingUserIds
                                    : '없음'
                                }`}
                                place="bottom"
                              >
                                <span>
                                  {original?.dailyHoldemBettingStatisticsModel?.holdemBettingUserCount
                                    ?.toString()
                                    .replace(commonReg2, ',')}
                                </span>
                              </TooltipWrapper>
                            </td>
                            <td>
                              {original?.dailyHoldemBettingStatisticsModel?.holdemBettingAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyHoldemBettingStatisticsModel?.holdemWinningAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                            <td>
                              {original?.dailyHoldemBettingStatisticsModel?.holdemTotalProfitAmount
                                ?.toString()
                                .replace(commonReg2, ',')}
                            </td>
                          </>
                        )}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
              {content.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
            </TableWrap>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default DailyBettingStatisticsList

DailyBettingStatisticsList.propTypes = {}

const getColor = colorText => {
  switch (true) {
    case colorText === 'isActiveLiveInfo':
      return 'green'
    case colorText === 'isActiveSlotInfo':
      return '#a66414'
    case colorText === 'isActiveHoldemInfo':
      return '#f28c0f'
    case colorText === 'isActiveSportsInfo':
      return 'purple'
    case colorText === 'isActiveMiniGameInfo':
      return '#808212'
    case colorText === 'isActiveVirtualGameInfo':
      return '#262626'
    case colorText === 'isMain':
      return 'blue'
    default:
      return 'lightgrey'
  }
}

const CustomTh = styled.th`
  background: ${props => getColor(props.colorText)} !important;
  opacity: 0.8;
  color: ${props => (props.textColor === 'profit' ? 'yellow' : '#fff')} !important;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const PlusSquare = styled(AiOutlinePlusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`

const MinusSquare = styled(AiOutlineMinusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`
const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`

const TableColumnSearchButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
