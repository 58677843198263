import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const SidebarLink = ({ title, icon, newLink, route, onClick, target }) => (
  <li>
    <SidebarNavLink to={route} onClick={onClick} activeClassName="active" target={target} exact>
      {icon ? <SidebarLinkIcon className={`lnr lnr-${icon}`} /> : ''}
      <SidebarLinkTitle>{title}</SidebarLinkTitle>
    </SidebarNavLink>
  </li>
)

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  target: PropTypes.string,
  route: PropTypes.string,
  onClick: PropTypes.func,
}

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  target: '',
  route: '/',
  onClick: () => {},
}

export default SidebarLink

// region STYLES

export const SidebarNavLink = styled(NavLink)`
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;
  color: #646777;
  text-align: left;
  font-size: 14px;

  // 클릭했을때 색깔
  &.active {
    background: grey;

    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 3px;
    background: #0d0d0c;
    opacity: 0;
    transition: all 0.1s;
    left: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: #afafaf !important;
    color: #646777;

    &:before {
      opacity: 1;
    }
  }

  @media screen and (min-width: 576px) {
    width: 100%;
  }
`

export const SidebarLinkTitle = styled.span`
  margin: 0;
  font-size: 15px;
  line-height: 17px;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
`

export const SidebarLinkIcon = styled.span`
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  color: #fff;
  margin-right: 10px;
`

// endregion
