import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {
  FixtureBetResult,
  RealVirtualGameSportType,
  VirtualDetailBettingLogSettlementType,
  VirtualGameBettingType,
  VirtualGameLeagueType,
} from '../../../enums/VirtualGame/virtualGameEnums'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'

const VirtualDetailBettingLog = ({ virtualDetailBettingLogList }) => {
  const getFixtureResult = detailBettingLog => {
    switch (detailBettingLog.sportType) {
      case 'VIRTUAL_SOCCER':
        return `${detailBettingLog.homeScore} : ${detailBettingLog.awayScore}`
      case 'VIRTUAL_BASKETBALL':
        return `승패: ${FixtureBetResult[detailBettingLog.winDrawLoseResult]}, 핸디캡: ${
          FixtureBetResult[detailBettingLog.handicapResult]
        }, 오버언더: ${FixtureBetResult[detailBettingLog.overUnderResult]}`
      case 'VIRTUAL_BASEBALL':
        return `${detailBettingLog.homeScore} : ${detailBettingLog.awayScore}`
      case 'VIRTUAL_GREYHOUNDS':
        return `1등 : ${detailBettingLog.raceFirstNumber}번  ${detailBettingLog.raceFirstName}`
      case 'VIRTUAL_HORSE':
        return `1등 : ${detailBettingLog.raceFirstNumber}번  ${detailBettingLog.raceFirstName}`
      default:
        return '-'
    }
  }

  return (
    <SportDetailBettingLogWrap>
      <td colSpan={17} style={{ padding: '10px' }}>
        <table>
          <thead>
            <tr>
              <th>경기시간</th>
              <th>종목</th>
              <th>리그</th>
              <th>경기명</th>
              <th>경기결과</th>
              <th>마켓</th>
              <th>배팅</th>
              <th>배당</th>
              <th colSpan={1}>기준점</th>
              <th>배팅 결과</th>
            </tr>
          </thead>
          <tbody>
            {virtualDetailBettingLogList.map(virtualGameDetailBettingLog => {
              return (
                <tr>
                  <td>{convertToKstSportsEventDateTime(virtualGameDetailBettingLog.startDate)}</td>
                  <td>{RealVirtualGameSportType[virtualGameDetailBettingLog.sportType]}</td>
                  <td>{VirtualGameLeagueType[virtualGameDetailBettingLog.leagueType]}</td>
                  <td>
                    {virtualGameDetailBettingLog.homeName
                      ? `${virtualGameDetailBettingLog.homeName} vs ${virtualGameDetailBettingLog.awayName}`
                      : '-'}
                  </td>
                  <td>{getFixtureResult(virtualGameDetailBettingLog)}</td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog.marketName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog.selectedBetType === 'RACE_WINNER_PICK'
                      ? `${virtualGameDetailBettingLog.selectedRaceNumber}번 ${
                          virtualGameDetailBettingLog.selectedRaceName
                        } ${VirtualGameBettingType[virtualGameDetailBettingLog.selectedBetType]}`
                      : `${VirtualGameBettingType[virtualGameDetailBettingLog.selectedBetType]}`}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog?.selectedBetPrice || 0}
                  </td>
                  <td>{virtualGameDetailBettingLog.lineNum || '-'}</td>
                  <td>{VirtualDetailBettingLogSettlementType[virtualGameDetailBettingLog.betSettlementType]}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </SportDetailBettingLogWrap>
  )
}

export default VirtualDetailBettingLog

VirtualDetailBettingLog.propTypes = {
  virtualDetailBettingLogList: PropTypes.arrayOf().isRequired,
}

VirtualDetailBettingLog.defaultProps = {}

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #6b6b6b !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #fbf7ea;

      &:hover {
        border: none;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
