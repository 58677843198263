import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { FormButtonToolbar, FormContainer, FormInputWrap } from '../../../../../shared/components/form/FormElements'
import { searchMemberLosingInfo, updateMemberLosingInfo } from '../../../../../utils/api/logManage/logApi'
import {
  MemberLosingTypeEnum,
  MemberLosingTypeEnumSelectOptions,
} from '../../../../../enums/Statistics/memberLosingEnums'
import { MemberResterBankSelect } from '../../../../../shared/components/CustomSelect'
import { decodeAccessToken } from '../../../../../utils/token'
import Loading from '../../../../../shared/components/Loading'

const MemberLosingInfoPage = ({ memberId }) => {
  const [params, setParams] = useState({
    memberId,
  })

  const [loading, setLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority

  const [myLosingInfo, setMyLosingInfo] = useState({})
  const [newLosingInfo, setNewLosingInfo] = useState({})
  const [topPartnerLosingInfo, setTopPartnerLosingInfo] = useState({})
  const [topPartnerExist, setTopPartnerExist] = useState(false)

  // 정산타입
  const [myLosingTypeValue, setMyLosingTypeValue] = useState(null)

  const memberLosingOptions = MemberLosingTypeEnumSelectOptions()

  const onMemberLosingChangeHandler = option => {
    setMyLosingTypeValue(option)
  }

  const fetchhMemberLosingInfo = async () => {
    await searchMemberLosingInfo(params).then(res => {
      setMyLosingTypeValue({
        value: res.data.myLosingType,
        label: MemberLosingTypeEnum[res.data.myLosingType],
      })
      setMyLosingInfo(res.data.myLosingInfo)
      setNewLosingInfo(res.data.myLosingInfo)
      setTopPartnerLosingInfo(res.data.topPartnerLosingInfo)
      setTopPartnerExist(res.data.topPartnerExist)
    })
  }

  const onChangeHandler = (type, newValue) => {
    // Check if the input is a valid number or starts with a decimal
    const regex = /^(\d*\.?\d*)$/

    if (regex.test(newValue)) {
      setNewLosingInfo(prev => ({
        ...prev,
        [type]: newValue,
      }))
    }
  }

  useEffect(() => {
    fetchhMemberLosingInfo()
  }, [])

  const onSubmit = e => {
    if (loading) return
    if (window.confirm(`${memberId}의 루징비율을 변경 하시겠습니까?`)) {
      setLoading(true)
      const body = {
        memberId,
        newLosingInfo,
        losingType: myLosingTypeValue.value,
        // casinoRollingInfo: {
        //   casinoSlotRollingPer,
        //   casinoLiveRollingPer,
        // },
      }
      updateMemberLosingInfo(body)
        .then(res => {
          alert('수정이 완료됐습니다.')
          fetchhMemberLosingInfo()
          setLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          if (errorCode === 'PARTNER-1007') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1016') {
            alert(`${message}`)
          } else if (errorCode === 'MEMBER-1005') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1013') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1015') {
            alert(`${message}`)
          } else {
            alert('수정이 실패했습니다.')
          }
          fetchhMemberLosingInfo()
          setLoading(false)
        })
    }
  }

  return (
    <MemberInfoWrap>
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>
            * 루징비율 수정은 유저 권한이 <span style={{ color: 'red', fontWeight: '900' }}>파트너</span>만 가능합니다.
          </p>
          <p>
            * 루징비율 수정 시 <span style={{ color: 'red', fontWeight: '900' }}>상위 파트너의 루징 비율</span>보다
            같거나 작어야 합니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      {loading && <Loading />}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          myLosingType: myLosingTypeValue,
          newLosingInfo,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <CustomFormContainer horizontal onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <TopPartnerTh colSpan={2}>{memberId} 루징 정산방식</TopPartnerTh>
                  <td colSpan={3}>
                    <Field name="myLosingType">
                      {({ input, meta }) => (
                        <MemberResterBankSelect
                          {...input}
                          value={myLosingTypeValue}
                          // onChange={option => {
                          //   input.onChange(option)
                          //   onMemberLosingChangeHandler(option)
                          // }}
                          options={memberLosingOptions}
                          isDisabled
                          className="react-select"
                          classNamePrefix="react-select"
                          style={{ width: '100%' }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th style={{ color: 'red', fontSize: '14px' }} colSpan={11}>
                    상위 파트너 루징요율
                  </th>
                </tr>
                <tr>
                  <TopPartnerTh>입금-출금</TopPartnerTh>
                  <TopPartnerTh>라이브(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>슬롯(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>미니 일반(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>미니 조합(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>단폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 2폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 3폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 4폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 5폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 다폴(배팅-당첨)</TopPartnerTh>
                </tr>
                <tr>
                  <TopPartnerTh colSpan={3}> 가상축구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상야구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상농구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상경마(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 개경주(배팅-당첨)</TopPartnerTh>
                </tr>
                <tr>
                  <td>
                    <Field name="topPartnerDepositMinusWithdrawalLosingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.depositMinusWithdrawalLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.casinoLiveBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.casinoSlotBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.miniGameSingleBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.miniGameCombinationBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsOneFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsOneFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsTwoFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsThreeFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsFourFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsFiveFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.sportsMultiFolderBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.virtualSoccerGameBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.virtualBaseballGameBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.virtualBasketballGameBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.virtualHorseGameBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="casinoLiveRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={topPartnerLosingInfo?.virtualGreyhoundsGameBettingMinusWinningLosingPer}
                            disabled
                          />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>

                {/* // */}
                <tr style={{ marginTop: '2px' }}>
                  <th style={{ color: 'blue', fontSize: '14px' }} colSpan={11}>
                    {memberId} 유저 루징요율
                  </th>
                </tr>
                <tr>
                  <TopPartnerTh>입금-출금</TopPartnerTh>
                  <TopPartnerTh>슬롯(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>라이브(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>미니 일반(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>미니 조합(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 단폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 2폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 3폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh> 4폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>5폴(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh>다폴(배팅-당첨)</TopPartnerTh>
                </tr>
                <tr>
                  <TopPartnerTh colSpan={3}> 가상축구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상야구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상농구(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 가상경마(배팅-당첨)</TopPartnerTh>
                  <TopPartnerTh colSpan={2}> 개경주(배팅-당첨)</TopPartnerTh>
                </tr>
                <tr>
                  <td>
                    <Field name="depositMinusWithdrawalLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.depositMinusWithdrawalLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('depositMinusWithdrawalLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoSlotBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.casinoSlotBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('casinoSlotBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="casinoLiveBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.casinoLiveBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('casinoLiveBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>

                  <td>
                    <Field name="miniGameSingleBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.miniGameSingleBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('miniGameSingleBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="miniGameCombinationBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.miniGameCombinationBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('miniGameCombinationBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsOneFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsOneFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsOneFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsTwoFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsTwoFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsTwoFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsThreeFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsThreeFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsThreeFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsFourFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsFourFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsFourFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsFiveFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsFiveFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsFiveFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.sportsMultiFolderBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('sportsMultiFolderBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.virtualSoccerGameBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('virtualSoccerGameBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.virtualBaseballGameBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('virtualBaseballGameBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.virtualBasketballGameBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('virtualBasketballGameBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.virtualHorseGameBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('virtualHorseGameBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                  <td colSpan={2}>
                    <Field name="sportsMultiFolderBettingMinusWinningLosingPer">
                      {({ input, meta }) => (
                        <>
                          <CustomInput
                            {...input}
                            type="text"
                            value={newLosingInfo?.virtualGreyhoundsGameBettingMinusWinningLosingPer || 0}
                            onChange={e => {
                              onChangeHandler('virtualGreyhoundsGameBettingMinusWinningLosingPer', e.target.value)
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </table>
            <FormButtonToolbar className="registrationFooter">
              <Button variant="primary" type="submit" style={{ marginRight: '150px' }}>
                루징 비율 변경하기
              </Button>
            </FormButtonToolbar>
          </CustomFormContainer>
        )}
      </Form>
    </MemberInfoWrap>
  )
}

export default MemberLosingInfoPage

MemberLosingInfoPage.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const CustomInput = styled.input`
  border: solid 1px black !important;
`

const CustomFormContainer = styled(FormContainer)`
  max-width: 100%;
`

const TopPartnerTh = styled.th`
  color: white !important;
  background-color: #3c3c3c !important;
`
const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  input {
    border: solid 1px black;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
