import axios from '../base/axios'

// 와일드게임즈 배팅내역 조회
export async function partnerSearchWildGamesBettingLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/holdem/wild-games/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 디테일 배팅 내역 조회
export async function partnerSearchWildGamesDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/holdem/wild-games/detail-betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
