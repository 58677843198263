import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { createOnetoOne, deleteOnetoOneList } from '../../../utils/api/customCenter/customCenterApi'
import { fetchOneToOneList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  ListButton,
  MoneyFormModifyButtomInfo,
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  GoldQuestionButton,
} from './MoneyCustomElement'
import { convertToKstDateTime, convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { OneToOneAnswerStatusEnums } from '../../../enums/Operation/OneToOneEnums'
import OnetoOneRadio from './OnetoOneRadio'
import Loading from '../../../shared/components/Loading'
import OnetoOneWrite from './OnetoOneWrite'
import { WebPageWrap } from '../Note/NoteList'
// import OnetoOneWrite from './OnetoOneWrite'

const OnetoOneList = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [isWriteOnetoOneShow, setWriteOnetoOneShow] = useState(false)

  /**
   *  1:1문의 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [oneToOneParams, setOneToOneParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [checkRadio, setCheckRadio] = useState([])
  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.oneToOneInfo.totalElement,
      apiContent: customCenter.oneToOneInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.oneToOneKey === item.oneToOneKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchOneToOneList = async () => {
    try {
      dispatch(fetchOneToOneList(oneToOneParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchOneToOneList()
  }, [oneToOneParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })
    }
  }

  // 문의하기 버튼
  const onWriteFromHandler = () => {
    setWriteOnetoOneShow(prev => !prev)
  }
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setQuestionLoading(false)
        fetchSearchOneToOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(`${message}`)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
    }
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <WebPageWrap ref={webPageWrapRef}>
      <OneToOneHeaderWrap>
        <OnetoOneSubhead style={{ color: '#fff' }}>* 완료 처리 된 것만 삭제 하실 수 있습니다.</OnetoOneSubhead>
      </OneToOneHeaderWrap>
      {loading || deleteLoading || questionLoading ? (
        <Loading />
      ) : (
        <CustomerCenterContentWrap>
          <ListWrap>
            <ListButton
              onClick={() => {
                setCheckRadio(
                  content.filter(value => value.answerStatus !== 'INCOMPLETE').map(value => value.oneToOneKey),
                )
              }}
              type="button"
            >
              <MoneyFormModifyButtomInfo>전체선택</MoneyFormModifyButtomInfo>
            </ListButton>
            <ListButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <MoneyFormModifyButtomInfo>삭제하기</MoneyFormModifyButtomInfo>
            </ListButton>
            <ListButton onClick={() => onWriteFromHandler()} variant="primary" type="button">
              <MoneyFormModifyButtomInfo>문의하기</MoneyFormModifyButtomInfo>
            </ListButton>
            {/* <GoldQuestionButton onClick={() => onQuestionAccountHandler()} variant="primary" type="button">
              <MoneyFormModifyButtomInfo>계좌문의</MoneyFormModifyButtomInfo>
            </GoldQuestionButton> */}
          </ListWrap>
          {isWriteOnetoOneShow && (
            <OnetoOneWrite
              setWriteOnetoOneShow={setWriteOnetoOneShow}
              fetchSearchOnetoOneList={fetchSearchOneToOneList}
            />
          )}
          <CustomerCenterContentTable>
            <CustomerCenterContentTableHead>
              <CustomerCenterContentTableHeadTr>
                <th style={{ width: '5%' }}>-</th>
                <th style={{ width: '5%' }}>번호</th>
                <th style={{ width: '20%' }}>제목</th>
                <th style={{ width: '50%' }}>내용</th>
                <th style={{ width: '12%' }}>문의시간</th>
                <th style={{ width: '8%' }}>답변</th>
              </CustomerCenterContentTableHeadTr>
            </CustomerCenterContentTableHead>
            <CustomerCenterContentTableBody>
              {content.map(item => (
                <>
                  <CustomerCenterContentTableBodyTr key={item.oneToOneKey}>
                    <CustomerCenterContentBox>
                      <CustomerCenterContenItemBox width="5">
                        <OnetoOneRadio
                          rowInfo={{
                            oneToOneKey: item.oneToOneKey,
                            answerStatus: item.answerStatus,
                          }}
                          checkRadio={checkRadio}
                          setCheckRadio={setCheckRadio}
                        />
                      </CustomerCenterContenItemBox>
                      <CustomerCenterContenItemBox width="5" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                        {item.num}
                      </CustomerCenterContenItemBox>
                      <CustomerCenterContenItemBox width="20">{item.questionTitle}</CustomerCenterContenItemBox>
                      <CustomerCenterContenItemBox
                        width="50"
                        onClick={() => onClickRowHandler(item.oneToOneKey)}
                        style={{ cursor: 'pointer', flexDirection: 'column' }}
                      >
                        {ReactHtmlParser(item.questionContent, { transform: transformFn })}
                      </CustomerCenterContenItemBox>
                      <CustomerCenterContenItemBox width="12" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                        {convertToKstDateTimeSimple(item.registrationDate)}
                      </CustomerCenterContenItemBox>
                      <CustomerCenterContenItemBox width="8" onClick={() => onClickRowHandler(item.oneToOneKey)}>
                        {OneToOneAnswerStatusEnums[item.answerStatus]}
                      </CustomerCenterContenItemBox>
                    </CustomerCenterContentBox>
                  </CustomerCenterContentTableBodyTr>
                  <Collapse in={item.isVisible}>
                    <CustomerCenterContentTableBodyTr>
                      <CustomerCenterContentDetailBox>
                        {item.answerContent === null
                          ? '문의가 접수됐습니다. 잠시만 기다려주세요'
                          : ReactHtmlParser(item.answerContent, { transform: transformFn })}
                      </CustomerCenterContentDetailBox>
                    </CustomerCenterContentTableBodyTr>
                  </Collapse>
                </>
              ))}
            </CustomerCenterContentTableBody>
          </CustomerCenterContentTable>
          {totalElement > 0 && (
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </CustomerCenterContentWrap>
      )}
    </WebPageWrap>
  )
}

export default OnetoOneList

const ListWrap = styled.div`
  display: flex;
`
export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;

  color: #000;
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  height: 70px;
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;

  span {
    font-size: 13px;
  }
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
`
