import styled from 'styled-components'
import { borderLeft, borderRight, left, marginLeft, marginRight, paddingLeft } from '@/utils/directions'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBlue,
  colorBorder,
  colorDustyWhite,
  colorFieldsBorder,
  colorHover,
  colorIcon,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '@/utils/palette'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-time-picker/assets/index.css'

export const PageWrap = styled.div`
  width: 100%;
  min-height: 840px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

// 타이틀
export const PageHeader = styled.div`
  width: 100%;
  height: 35px;

  font-size: 24px;
  line-height: 25px;
  font-weight: bold;

  // text-shadow: 1px 1px 4px #000000;
  color: #333333;

  padding-left: 20px;

  display: flex;
  align-items: center;
`

// 검색 부분 감싸는 wrap
export const PageSubHeaderWrap = styled.div`
  width: 100%;
  height: 40px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #000;
`

// 검색 부분 감싸는 wrap
export const PageSubHeaderInfo = styled.div`
  width: 100%;
  height: 2px;

  // padding-left: 20px;
  display: flex;
`

// 검색
export const PageSubHeaderSearch = styled.div`
  //   width: 500px;
  width: 100%;
  height: 100%;
  margin-right: auto;
  display: flex;
  gap: 10px;
  padding: 4px 0;
`

export const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: min-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  gap: 6px;
`

export const SearchInputLabel = styled.div`
  min-width: max-content;
  font-size: 17px;
  font-weight: 500;
`

export const SearchInput = styled.input`
  width: 130px;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  background: transparent;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s,
    -webkit-box-shadow 0.15s ease-in-out 0s;
`

export const SearchButton = styled.button`
  width: 100px;
  height: 100%;
  background-color: #0077cc;
  color: white;
  padding: 0 10px;
  border: none;
  border-radius: 4px;
  font-size: 21px;
  cursor: pointer;

  // 일부러 넣은 것
  margin-left: 15px;

  &:hover {
    background-color: #0066b3;
  }
`

// 금액 INFO
export const PageSubHeaderAmountInfoWrap = styled.div`
  width: 700px;
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 20px;
  gap: 10px;
`

export const PageSubHeaderAmountInfo = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: 'black';
`

// 테이블 wrap
export const PageTableWrap = styled.div`
  width: 100%;
  min-height: 750px;
`

// DatePicker
export const SearchDatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 14px;
  height: 38px;
  transition: border 0.3s;
  background: transparent;
  display: flex;
  align-items: center;

  input {
    width: 110px;
    height: 30px;
    padding-left: 5px;
    outline: none;
    background: transparent;
    background-color: rgb(255, 255, 255);
    background-image: none;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s,
      -webkit-box-shadow 0.15s ease-in-out 0s;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
    // height: 38px;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: ${colorAccent};
    border-color: ${colorAccent};
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    right: 2px;

    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    ${borderLeft}: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent} !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`

export const SearchSelect = styled(Select)`
  min-width: 200px;
  font-size: 15px;

  .react-select__control {
    height: 30px !important;
    min-height: 30px !important;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      //   background: transparent;
    }
  }

  .react-select__input {
    // height: 25px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    // overflow: hidden;
    overflow-y: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 14px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
