import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import Loading from '@/shared/components/Loading'
import Router from './Router'
import ScrollToTop from './ScrollToTop'
import GlobalStyles from './globalStyles'
import store from '../../redux/store'
import { socket } from '../../utils/socket'

const App = () => {
  // 웹소켓 연결되었을 때
  const onSocketIOConnect = () => {
    // console.log('connect')
  }

  // 웹소켓 연결이 끊어졌을 때
  const onSocketIODisconnect = () => {
    // console.log('disconnect')
  }

  // 웹소켓 연결 코드
  useEffect(() => {
    socket.connect()

    socket.on('connect', onSocketIOConnect)
    socket.on('disconnect', onSocketIODisconnect)

    return () => {
      socket.off('connect', onSocketIOConnect)
      socket.off('disconnect', onSocketIODisconnect)

      socket.disconnect()
    }
  }, [])

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop>
          <div>
            <ThemeProvider
              theme={{
                mode: 'light',
                direction: 'ltr',
              }}
            >
              <GlobalStyles />
              <Router />
            </ThemeProvider>
          </div>
        </ScrollToTop>
      </Provider>
    </BrowserRouter>
  )
}

export default App
