import { useEffect, useState } from 'react'
import axios from '../base/axios'
import { setCookie } from '../../cookie'
import { deleteAccessToken } from '../../token'

// OTP 확인
export async function postOtp(body) {
  const response = await axios({
    url: '/tyson/admin/v1/auth/sign-in/otp',
    method: 'POST',
    data: body,
  }).then(res => {
    // setUserId(body.adminId)

    // addAccessToken(res.data.accessToken)

    // // cookie 에 refresh token 저장
    // setCookie('refreshToken', res.data.refreshToken, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'none',
    // })

    return res.data
  })
  return response
}

// 로그인
async function postSiginIn(body) {
  const response = await axios({
    url: '/tyson/partner/v1/auth/sign-in',
    method: 'POST',
    data: body,
  }).then(res => {
    // setCookie('accessToken', res.data.accessToken, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'none',
    // })

    // setCookie('refreshToken', res.data.refreshToken, {
    //   path: '/',
    //   secure: true,
    //   sameSite: 'none',
    // })

    if (window.location.host.includes('localhost')) {
      setCookie('accessToken', res.data.accessToken, {
        path: '/',
        secure: true,
        sameSite: 'none',
      })

      setCookie('refreshToken', res.data.refreshToken, {
        path: '/',
        secure: true,
        sameSite: 'none',
      })
    } else {
      // eslint-disable-next-line no-useless-escape
      const domain = `.${window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')}`

      setCookie('accessToken', res.data.accessToken, {
        path: '/',
        secure: true,
        domain,
        sameSite: 'none',
      })

      setCookie('refreshToken', res.data.refreshToken, {
        path: '/',
        secure: true,
        domain,
        sameSite: 'none',
      })
    }

    return res
  })
  return response
}

// 로그아웃
async function postSiginOut() {
  const response = await axios({
    url: '/tyson/partner/v1/auth/sign-out',
    method: 'POST',
  }).then(res => {
    // local storage 데이터 전부 삭제
    deleteAccessToken()

    // // cookie 에 refresh token 삭제
    // removeToken()

    return res
  })

  return response
}

export { postSiginIn, postSiginOut }

// 내 정보 조회
export async function searchUserInfo(params) {
  const response = await axios({
    url: '/tyson/partner/v1/auth/my-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 내 정보 수정
export async function updateUserInfo(body) {
  const response = await axios({
    url: '/tyson/partner/v1/auth/my-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 로그인 이력 조회 API
export async function searchSignInList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/sign-in/history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchSignInList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchSignInList() {
    await searchSignInList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchSignInList()
  }, [params])

  return { totalElement, content, fetchSearchSignInList }
}
