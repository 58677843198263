import { Button } from '@/shared/components/Button'
import { Card, CardBody, MyInfoCardSubhead, MyInfoCardTitle, MyInfoCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormFieldButton,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import EyeIcon from 'mdi-react/EyeIcon'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { searchUserInfo, updateUserInfo } from '../../utils/api/authManage/authManageApi'
import { deleteAccessToken } from '../../utils/token'

function MyInfo() {
  const navigate = useHistory()

  const [passwordShown, setPasswordShown] = useState(false)

  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState(null)
  const [userName, setUserName] = useState('')
  const [recommendedCode, setRecommendedCode] = useState(null)

  const [isProcess, setIsProcess] = useState(false)

  useEffect(() => {
    searchUserInfo()
      .then(res => {
        setUserId(res.data.userId)
        setUserName(res.data.userName)
        setRecommendedCode(res.data.recommendedCode)
      })
      .catch(error => {})
  }, [])

  const onSubmit = e => {
    if (isProcess) return

    setIsProcess(true)

    const body = {
      password: !password?.trim() ? null : password.trim(),
      recommendedCode: !recommendedCode?.trim() ? null : recommendedCode.trim(),
    }

    updateUserInfo(body)
      .then(res => {
        setIsProcess(false)
        deleteAccessToken()
        alert('정보가 수정됐습니다, 다시 로그인해주세요.')
        navigate.push('/')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1005') {
          alert(`${message}`)
          setIsProcess(false)
        } else {
          setIsProcess(false)
          alert('정보 수정에 실패했습니다. 잠시후 다시 시도해주세요.')
        }
      })
  }

  return (
    <Card>
      <CardBody>
        <MyInfoCardTitleWrap>
          <MyInfoCardTitle>나의 정보</MyInfoCardTitle>
          <MyInfoCardSubhead>* 정보를 수정해 주세요.</MyInfoCardSubhead>
        </MyInfoCardTitleWrap>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            userId,
            userName,
            recommendedCode,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <>
              <FormContainer horizontal onSubmit={handleSubmit} style={{ maxWidth: '900px' }}>
                <FormGroup>
                  <FormGroupLabel>아이디</FormGroupLabel>
                  <FormGroupField>
                    <Field name="userId">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            disabled
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>비밀번호 (변경시 입력)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="password">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input
                              {...input}
                              type={passwordShown ? 'text' : 'password'}
                              value={input.value}
                              inputMode="latin"
                              onChange={e => {
                                input.onChange(e)
                                setPassword(e.target.value)
                              }}
                              placeholder="변경시 비밀번호를 입력하세요."
                            />
                          </FormInputWrap>
                          <FormFieldButton
                            active={passwordShown}
                            type="button"
                            onClick={() => setPasswordShown(shown => !shown)}
                          >
                            <EyeIcon />
                          </FormFieldButton>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>이름</FormGroupLabel>
                  <FormGroupField>
                    <Field name="userName">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            disabled
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>추천코드</FormGroupLabel>
                  <FormGroupField>
                    <Field name="recommendedCode">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                              setRecommendedCode(e.target.value)
                            }}
                            placeholder="사용하실 추천코드를 입력하세요."
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '-10px' }}>
                  <Button variant="primary" type="submit">
                    수정하기
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  )
}

export default MyInfo
