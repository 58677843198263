export const ZeroBettingEnums = {
  USING: '진행중',
  END: '종료',
}

export const BettingResultEnums = {
  BET: '배팅',
  WIN: '당첨',
  LOSE: '낙첨',
  DRAW: '무효',
  CANCEL: '취소',
  EXCEED_CREDIT: '최대 당첨금 제한',
}

export const BettingResultEnumsSelectOptions = () => {
  return Object.entries(BettingResultEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bettingGameTypeEnums = {
  LIVE: 'LIVE',
  SLOT: 'SLOT',
  BACCARAT: 'BACCARAT',
  DRAGONTIGER: 'DRAGONTIGER',
  TOPCARD: 'TOPCARD',
  BLACKJACK: 'BLACKJACK',
  AMERICANROULETTE: 'AMERICANROULETTE',
  ROULETTE: 'ROULETTE',
  INSTANTROULETTE: 'INSTANTROULETTE',
  CRAPS: 'CRAPS',
  MEGABALL: 'MEGABALL',
  DICE: 'DICE',
  SICBO: 'SICBO',
  HOLDEM: 'HOLDEM',
  POKER: 'POKER',
  SIDEBETCITY: 'SIDEBETCITY',
  FANTAN: 'FANTAN',
  BACBO: 'BACBO',
  ANDARBAHAR: 'ANDARBAHAR',
  MONOPOLYBINGO: 'MONOPOLYBINGO',
  FISHING: 'FISHING',
}

export const bettingGameTypeEnumsSelectOptions = () => {
  return Object.entries(bettingGameTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const HoldemBettingResultEnums = {
  WIN: '당첨',
  LOSE: '낙첨',
}

export const HoldemBettingResultEnumsSelectOptions = () => {
  return Object.entries(HoldemBettingResultEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
