import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import { updateOnetoOne } from '../../../utils/api/operation/operationApi'
import { searchMacroSelect } from '../../../utils/api/systemManage/systemManageApi'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import './OnetoOne.css'

function OnetoOneInfoForm({ onetoOneInfo, fetchSearchOnetoOneList, onClickHandler }) {
  const authority = decodeAccessToken()?.adminAuthority

  const [questionContent, setQuestionContent] = useState(EditorState.createEmpty())
  const [answerContent, setAnswerContent] = useState(EditorState.createEmpty())

  useEffect(() => {
    setQuestionContent(convertFromServer(onetoOneInfo.questionContent))
  }, [onetoOneInfo.questionContent])

  useEffect(() => {
    setAnswerContent(convertFromServer(onetoOneInfo.answerContent))
  }, [onetoOneInfo.answerContent])

  const [answerContentOption, setAnswerContentOption] = useState([])
  const [answerContentOptionValue, setAnswerContentOptionValue] = useState(null)
  const [selectedOnetoOneKey, setSelectedOnetoOneKey] = useState('')

  const onAnswerContentSelectHandler = option => {
    setAnswerContentOptionValue(option)
    setSelectedOnetoOneKey(option.value)
    setAnswerContent(convertFromServer(option.oneToOneContent))
  }

  // macro 1:1 조회
  const macroSelectParams = {
    userId: onetoOneInfo.userId,
    searchType: 'ONETOONE',
  }

  useEffect(() => {
    searchMacroSelect(macroSelectParams).then(res => {
      res.data.content.map(item =>
        setAnswerContentOption(prev => [
          ...prev,
          {
            value: item.macroTitle,
            label: item.macroTitle,
            oneToOneContent: item.macroContent,
          },
        ]),
      )
    })
  }, [])

  // 답변 내용 작성 핸들러
  const onAnswerContentChangeHandler = editorState => {
    setAnswerContent(editorState)
  }

  const onSubmit = e => {
    if (!answerContent.getCurrentContent().hasText()) {
      alert('답변 매크로 선택 또는 답변을 직접 기입해주세요.')
      return
    }
    const body = {
      oneToOneKey: onetoOneInfo.oneToOneKey,
      answerContent: convertFromFront(answerContent),
    }
    updateOnetoOne(body)
      .then(res => {
        alert('답변이 성공적으로 보내졌습니다.')
        onClickHandler()
        fetchSearchOnetoOneList()
      })
      .catch(error => {
        alert('답변 보내기가 실패했습니다. 잠시 후 다시 시도해주세요.')
      })
  }

  const onConfirmHandler = () => {
    onClickHandler()
    fetchSearchOnetoOneList()
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <div style={{ marginBottom: '30px' }}>
            <p>
              * 문의답변 <span style={{ fontWeight: 'bolder', color: 'blue' }}>매크로 기능</span>을 이용하시거나, 직접
              답변내용을 적으실 수 있습니다.{' '}
            </p>
          </div>
          <FormGroup>
            <FormGroupLabel>작성자</FormGroupLabel>
            <FormGroupField>
              <Field name="userId" component="input" type="text" defaultValue={onetoOneInfo.userId} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>문의 제목</FormGroupLabel>
            <FormGroupField>
              <Field
                name="questionTitle"
                component="input"
                type="text"
                defaultValue={onetoOneInfo.questionTitle}
                disabled
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>문의 내용</FormGroupLabel>
            <Editor
              editorState={questionContent}
              wrapperClassName="wrapper"
              editorClassName="onetooneEditor"
              toolbarClassName="onetoOnetoolbar"
              readOnly
            />
          </FormGroup>
          {onetoOneInfo.answerStatusType ? (
            <></>
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>문의 답변 목록</FormGroupLabel>
                <FormGroupField>
                  <OneToOneSelect
                    name="answerContentList"
                    value={answerContentOptionValue}
                    onChange={option => {
                      onAnswerContentSelectHandler(option)
                    }}
                    options={answerContentOption}
                    clearable={false}
                    placeholder="문의 답변을 선택하세요"
                    className="react-select"
                    classNamePrefix="react-select"
                  />
                </FormGroupField>
              </FormGroup>
            </>
          )}
          <FormGroup>
            <FormGroupLabel>답변 내용</FormGroupLabel>
            {onetoOneInfo.answerStatusType ? (
              <>
                <Editor
                  editorState={answerContent}
                  wrapperClassName="wrapper"
                  editorClassName="onetooneEditor"
                  toolbarClassName="onetoOnetoolbar"
                  readOnly={onetoOneInfo.answerStatusType}
                />
              </>
            ) : (
              <>
                <Editor
                  editorState={answerContent}
                  onEditorStateChange={editorState => onAnswerContentChangeHandler(editorState)}
                  wrapperClassName="wrapper"
                  editorClassName="onetooneEditor"
                  toolbarClassName="onetoOnetoolbar"
                  localization={{
                    locale: 'ko',
                  }}
                  readOnly={onetoOneInfo.answerStatusType}
                />
              </>
            )}
          </FormGroup>
          <FormButtonToolbar>
            {onetoOneInfo.answerStatusType ? (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    onConfirmHandler()
                  }}
                >
                  확인
                </Button>
              </>
            ) : (
              <>
                {
                  {
                    SUPER: (
                      <Button variant="primary" type="submit">
                        답변하기
                      </Button>
                    ),
                  }[authority]
                }
              </>
            )}
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default OnetoOneInfoForm

OnetoOneInfoForm.propTypes = {
  onetoOneInfo: PropTypes.shape({
    oneToOneKey: PropTypes.string,
    authority: PropTypes.string,
    userId: PropTypes.string,
    questionTitle: PropTypes.string,
    registrationDate: PropTypes.string,
    questionContent: PropTypes.string,
    answerContent: PropTypes.string,
    answerStatus: PropTypes.string,
    answerStatusType: PropTypes.bool,
  }).isRequired,
  fetchSearchOnetoOneList: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

// 1대1 문의 Select
export const OneToOneSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  // editor 때문에 추가함
  z-index: 101;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

// endregion
