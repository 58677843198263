import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 회원 정보 조회 API
 * @param {memberId}
 * @returns memberInfo
 */

export async function searchUserHoldingAsset(params) {
  const response = await axios({
    url: '/tyson/member/v1/asset/holdingasset',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function searchMemberInfo(params) {
  const response = await axios({
    url: '/tyson/partner/v1/member/management/member-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 추천코드 정보 변경 API
 */
export async function updateMemberRecommendedCode(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/recommended-code',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 롤링 정보 변경 API
 */
export async function updateMemberRollingInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/member-rolling',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 롤링 정보 변경 API
 */
export async function updateMemberZeroRollingInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/member-rolling/zero',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 롤링 정보 변경 API
 */
export async function updateMemberRecoveryRollingInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/member-rolling/recovery',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 강제 로그아웃
 */
export async function updateMemberLogOut(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/log-out',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 정보 변경 API
 */
export async function updateMemberInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/member-info',
    method: 'PUT',
    data: body,
  })

  return response
}

export async function searchPartnerApply(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/apply',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerApply(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartner() {
    await searchPartnerApply(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartner()
  }, [params])

  return { totalElement, content, fetchSearchPartner }
}

export async function searchMember(params) {
  const response = await axios({
    url: '/tyson/partner/v1/member/management',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMember(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMember() {
    await searchMember(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMember()
  }, [params])

  return { totalElement, content, fetchSearchMember }
}

/**
 * 회원 등록
 */
export async function registerMember(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/register',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 등록 - 은행 리스트 조회
 */
export async function searchBankList() {
  const response = await axios({
    url: '/tyson/v1/bank',
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

export async function adminSearchBankSelectList(params) {
  const response = await axios({
    url: '/tyson/v1/bank',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function adminSearchPartnerSelectList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/update-partner',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function adminSearchBankList(params) {
  const response = await axios({
    url: '/tyson/v1/bank/bank-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useAdminSearchBankList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchAdminSearchBankList() {
    await adminSearchBankList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchAdminSearchBankList()
  }, [params])

  return { totalElement, content, fetchAdminSearchBankList }
}

/**
 * 은행 수정
 */
export async function updateBank(body) {
  const response = await axios({
    url: '/tyson/v1/bank',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 은행 추가
 */
export async function createBank(body) {
  const response = await axios({
    url: '/tyson/v1/bank',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원가입 시 상태값 변경
export async function updateMemberRegistrationStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/registration/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 파트너 회원가입 시 상태값 변경
export async function updatePartnerRegistrationStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/apply/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 회원 DB 다운로드
 */
export async function downloadMemberList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/download',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
