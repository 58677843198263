import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MemberPointDepositReceiverTypeEnums } from '../../../enums/MemberHistory/MemberPointDepositEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { RollingBettingTypeEnums } from '../../../enums/PartnerHistory/RollingSettlementEnums'
import MiniGameBettingInfo from '../UserBettingModal/MiniGameBettingInfo'
import CasinoBettingInfo from '../UserBettingModal/CasinoBettingInfo'
import SportBettingInfo from '../UserBettingModal/SportBettingInfo'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import HoldemBettingInfo from '../UserBettingModal/HoldemBettingInfo'
import SportBettingInfoV2 from '../UserBettingModal/SportBettingInfoV2'
import VirtualGameBettingInfo from '../UserBettingModal/VirtualGameBettingInfo'
import WildGamesBettingInfo from '../UserBettingModal/WildGamesBettingInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MemberRollingPointDepositListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    // {
    //   Header: '사이트명',
    //   accessor: 'edgeName',
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 65,
    // },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },

    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer', width: 'maxContent' }}
              >
                {original.memberId}({original.memberName})
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '롤링 발생유저',
      accessor: 'bettingUserId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.receiverType.includes('MINI')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.bettingUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.bettingUserId}
              </span>

              <span>
                <MiniGameBettingInfo
                  bettingUserId={original.bettingUserId}
                  bettingLogKey={original.bettingLogKey}
                  receiverType={original.receiverType}
                />
              </span>
            </span>
          )
        }
        if (original.receiverType.includes('SPORTS')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.bettingUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.bettingUserId}
              </span>
              <SportBettingInfoV2
                bettingUserId={original.bettingUserId}
                bettingLogKey={original.bettingLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('CASINO')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.bettingUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.bettingUserId}
              </span>
              <CasinoBettingInfo
                bettingUserId={original.bettingUserId}
                bettingLogKey={original.bettingLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('HOLDEM')) {
          if (original.agencyName === 'WILD_GAMES') {
            return (
              <span>
                <span
                  onClick={() => {
                    window.open(`/user-info/${original.bettingUserId}`, '_blank')
                  }}
                  style={{
                    color: '#2663a3',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    width: 'maxContent',
                  }}
                >
                  {original.bettingUserId}
                </span>
                <WildGamesBettingInfo
                  bettingUserId={original.bettingUserId}
                  bettingLogKey={original.bettingLogKey}
                  receiverType={original.receiverType}
                />
              </span>
            )
          }

          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.bettingUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.bettingUserId}
              </span>
              <HoldemBettingInfo
                bettingUserId={original.bettingUserId}
                bettingLogKey={original.bettingLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('VIRTUAL_GAME')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.bettingUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.bettingUserId}
              </span>
              <VirtualGameBettingInfo
                bettingUserId={original.bettingUserId}
                bettingLogKey={original.bettingLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        return (
          <span
            onClick={() => {
              window.open(`/user-info/${original.bettingUserId}`, '_blank')
            }}
            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {original.bettingUserId}
          </span>
        )
      },
    },
    {
      Header: '계산 롤링%',
      accessor: 'bettingUserRollingPer',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'black', fontWeight: '600' }}>{original.bettingUserRollingPer}%</span>
      },
    },
    {
      Header: '롤링 타입',
      accessor: 'rollingBettingType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'blue', fontWeight: '500' }}>
            {RollingBettingTypeEnums[original.rollingBettingType]}
          </span>
        )
      },
    },
    {
      Header: '구분',
      accessor: 'receiverType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberPointDepositReceiverTypeEnums[original.receiverType]
      },
    },
    {
      Header: '변경전',
      accessor: 'beforePoint',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '롤링포인트 입금액',
      accessor: 'changePointDeposit',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후',
      accessor: 'afterPoint',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberRollingPointDepositListData
