import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
// import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import { MoneyIncDecTypeEnumsSelectOptions } from '../../../enums/Log/moneyIncDecEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { searchAssetIncDec } from '../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../utils/dateTime'
import LogPageTabs from '../LogPageTabs'
import MoneyIncDecListData from './MoneyIncDecListData'

const MoneyIncDecList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  /**
   머니증차감로그 조회
   */
  const [moneyIncDecparams, setMoneyIncDecParams] = useState({
    page,
    size,
    userAuthority: null,
    userId: null,
    assetType: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchAssetIncDec = async () => {
    await searchAssetIncDec(moneyIncDecparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchAssetIncDec()
  }, [moneyIncDecparams])

  const [moneyIncDecListData, setMoneyIncDecListData] = useState(MoneyIncDecListData(content))
  useEffect(() => {
    setMoneyIncDecListData(MoneyIncDecListData(content))
  }, [content])

  const [rows, setData] = useState(moneyIncDecListData.tableRowsData)
  useEffect(() => {
    setData(moneyIncDecListData.tableRowsData)
  }, [moneyIncDecListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 회원ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 답변
  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = MoneyIncDecTypeEnumsSelectOptions()

  const onSelectMoneyTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      assetType: !searchMoneyType ? null : searchMoneyType,
      userId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  // Tab Key
  const [activeKey, setActiveKey] = useState('2')
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <LogPageTabs activeKey={activeKey} />
          {/* 검색 기능 */}
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>
                * 해당 페이지는 <span style={{ color: 'red', fontWeight: 'bolder' }}>수동</span>으로 회원 및 파트너의
                지갑머니를 증차감한 내역을 확인할 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>자산 종류</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchMoneyType"
                  onChange={option => {
                    onSelectMoneyTypeOptionHandler(option)
                  }}
                  placeholder="종류"
                  value={searchMoneyTypeOptionValue}
                  options={searchMoneyTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={moneyIncDecListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default MoneyIncDecList
