import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 파트너 정산통계 - 총누적 통계 조회  시작
export async function searchPartnerSettlementTotal(params) {
  const response = await axios({
    url: '/tyson/admin/v1/statistics/accumulate/partner-total',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerSettlementTotal(params) {
  const [content, setContent] = useState([])
  const [totalRollingContent, setRollingContent] = useState([])
  const [totalLosingContent, setLosingContent] = useState([])

  async function fetchSearchPartnerSettlementTotal() {
    await searchPartnerSettlementTotal(params).then(response => {
      setContent(response.data.content)
      setRollingContent(response.data.content)
      setLosingContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerSettlementTotal()
  }, [params])

  return { totalRollingContent, totalLosingContent, content, fetchSearchPartnerSettlementTotal }
}
// 파트너 정산통계 - 총누적 통계 조회 끝

// 파트너 정산통계 - 일일 통계 조회  시작
export async function searchPartnerSettlementDaily(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/settlement/statistics/daily',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerSettlementDaily(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerSettlementDaily() {
    await searchPartnerSettlementDaily(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerSettlementDaily()
  }, [params])

  return { totalElement, content, fetchSearchPartnerSettlementDaily }
}
// 파트너 정산통계 - 총누적 통계 조회 끝

// 파트너 롤링비 정산내역 조회 시작
export async function searchPartnerRollingSettlement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/history/rolling/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerRollingSettlement(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerRollingSettlement() {
    await searchPartnerRollingSettlement(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerRollingSettlement()
  }, [params])

  return { totalElement, content, fetchSearchPartnerRollingSettlement }
}
// 파트너 롤링비 정산내역 조회 끝

// 파트너 루징비 정산내역 조회 시작
export async function searchPartnerLosingSettlement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/history/losing/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerLosingSettlement(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerLosingSettlement() {
    await searchPartnerLosingSettlement(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerLosingSettlement()
  }, [params])

  return { totalElement, content, fetchSearchPartnerLosingSettlement }
}
// 파트너 루징비 정산내역 조회 끝

// 파트너  롤링포인트전환내역 조회 시작
export async function searchUserPointExchange(params) {
  const response = await axios({
    url: '/tyson/partner/v1/asset/user/pointexchange',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchUserPointExchange(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchUserPointExchange() {
    await searchUserPointExchange(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchUserPointExchange()
  }, [params])

  return { totalElement, content, fetchSearchUserPointExchange }
}
// 파트너 롤링 포인트전환내역 조회 끝

// 파트너 루징 포인트전환내역 조회 시작
export async function searchPartnerLosingPointExchange(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/pointexchange/losing',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerLosingPointExchange(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerLosingPointExchange() {
    await searchPartnerLosingPointExchange(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerLosingPointExchange()
  }, [params])

  return { totalElement, content, fetchSearchPartnerLosingPointExchange }
}
// 파트너 루징 포인트전환내역 조회 끝

// 파트너 머니입금내역 조회 시작
export async function searchPartnerMoneyDeposit(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/deposit-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerMoneyDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerMoneyDeposit() {
    await searchPartnerMoneyDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerMoneyDeposit()
  }, [params])

  return { totalElement, content, fetchSearchPartnerMoneyDeposit }
}
// 파트너 머니입금내역 조회 끝

// 파트너 머니출금내역 조회 시작
export async function searchPartnerMoneyWithdrawal(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/withdrawal',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerMoneyWithdrawal(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerMoneyWithdrawal() {
    await searchPartnerMoneyWithdrawal(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerMoneyWithdrawal()
  }, [params])

  return { totalElement, content, fetchSearchPartnerMoneyWithdrawal }
}
// 파트너 머니출금내역 조회 끝

// 입금신청 시 상태값 변경
export async function updatePartnerMoneyDepositStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/deposit',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 출금신청 시 상태값 변경
export async function updatePartnerMoneyWithdrawalStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/withdrawal',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
