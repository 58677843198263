import PropTypes from 'prop-types'
import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { MemberDepositStatusEnums, MemberDepositTypeEnums } from '../../../enums/MemberHistory/MemberDepositEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberBettingLogModal from '../../MemberManagement/MemberList/MemberInfo/MemberBettingLog/MemberBettingLogModal'
import ApproveDepositRadio from './ApproveDepositRadio'
import MemberDepositInfo from './MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const DepositListData = (setCheckDepositApprove, content) => {
  const columns = [
    {
      Header: '승인',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <ApproveDepositRadio rowMemberInfo={original} setCheckDepositApprove={setCheckDepositApprove} />
      },
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>

              <span>
                <MemberDepositInfo memberId={original.memberId} moneyDepositKey={original.moneyDepositKey} />{' '}
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '배팅로그',
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MemberBettingLogModal memberId={original.memberId} />
      },
    },
    {
      Header: '직영 파트너명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '신청전 지갑머니',
      accessor: 'beforeMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청전 카지노머니',
      accessor: 'beforeCasinoMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeCasinoMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청액',
      accessor: 'applyMoneyMember',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.applyMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '타입',
      accessor: 'depositType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberDepositTypeEnums[original.depositType]
      },
    },
    {
      Header: '보너스액',
      accessor: 'bonusMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bonusMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최종 지급액',
      accessor: 'paymentMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 75,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.paymentMoney === '0' ? '-' : original.paymentMoney.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '신청일',
      accessor: 'applyDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.applyDate)
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'APPROVE':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
          case 'WAITING':
            return (
              <span style={{ color: 'orange', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
          case 'CANCEL':
            return <span style={{ color: 'red', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
          case 'APPLY':
            return (
              <span style={{ color: 'green', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
          default:
            return (
              <span style={{ color: 'black', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
        }
      },
    },
    {
      Header: '메모',
      accessor: 'memo',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default DepositListData

DepositListData.propTypes = {
  setCheckDepositApprove: PropTypes.func.isRequired,
  rowMemberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    moneyDepositKey: PropTypes.string,
    memberName: PropTypes.string,
    type: PropTypes.string,
    applyMoney: PropTypes.string,
    applyDate: PropTypes.string,
    executeDate: PropTypes.string,
    bonsa: PropTypes.string,
    chongpan: PropTypes.string,
    maejang: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
