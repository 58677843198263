import React, { useState } from 'react'
import Scrollbar from 'react-smooth-scrollbar'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import SidebarContent from './SidebarContent'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { setHideSideBar } from '../../../redux/headerInfoSlice'

const Sidebar = ({ sidebarShow, collapse }) => {
  const dispatch = useDispatch()
  const {
    hideSideBar,
    holdingMoney,
    holdingTotalGameMoney,
    holdingHoldemMoney,
    holdingMemberRollingPoint,
    holdingMemberLosingPoint,
    todayMemberDeposit,
    todayMemberWithdrawal,
    todayPartnerDeposit,
    todayPartnerWithdrawal,
    todayMemberDepositCount,
    todayMemberWithdrawalCount,
    todayPartnerDepositCount,
    todayPartnerWithdrawalCount,
    memberMoneyAmount,
    memberRollingPointAmount,
    memberBettingAwaitingAmount,
    partnerBettingAwaitingAmount,
  } = useSelector(state => {
    const { headerInfo, memberInfo } = state

    return {
      todayMemberDeposit: headerInfo.todayMemberDeposit,
      todayMemberWithdrawal: headerInfo.todayMemberWithdrawal,
      todayPartnerDeposit: headerInfo.todayPartnerDeposit,
      todayPartnerWithdrawal: headerInfo.todayPartnerWithdrawal,
      hideSideBar: headerInfo.hideSideBar,
      holdingMoney: memberInfo.memberHoldingMoney,
      holdingTotalGameMoney: memberInfo.memberTotalGameMoney,
      holdingHoldemMoney: memberInfo.holdemMoneyAmount,
      holdingMemberRollingPoint: memberInfo.memberHoldingRollingPoint,
      holdingMemberLosingPoint: memberInfo.memberHoldingLosingPoint,
      todayMemberDepositCount: headerInfo.todayMemberDepositCount,
      todayMemberWithdrawalCount: headerInfo.todayMemberWithdrawalCount,
      todayPartnerDepositCount: headerInfo.todayPartnerDepositCount,
      todayPartnerWithdrawalCount: headerInfo.todayPartnerWithdrawalCount,
      memberMoneyAmount: headerInfo.memberMoneyAmount,
      memberRollingPointAmount: headerInfo.memberRollingPointAmount,
      memberBettingAwaitingAmount: headerInfo.memberBettingAwaitingAmount,
      partnerBettingAwaitingAmount: headerInfo.partnerBettingAwaitingAmount,
    }
  })

  return (
    <>
      {!hideSideBar ? (
        <SidebarWrap show={sidebarShow} collapse={collapse}>
          <SidebarScroll isMobile={isMobile}>
            <SidebarWrapper>
              <SidebarTopInfoBox>
                <SidebarTopInfo style={{ color: 'greenyellow' }}>
                  <span>회원 보유액</span>
                  <strong>{memberMoneyAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'greenyellow' }}>
                  <span>회원 롤링액</span>
                  <strong>{memberRollingPointAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>회원 배팅대기금액(스포츠)</span>
                  <strong>{memberBettingAwaitingAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>파트너 배팅대기금액(스포츠)</span>
                  <strong>{partnerBettingAwaitingAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#81A9F0' }}>
                  <span>금일 회원 입금</span>
                  <strong>
                    {todayMemberDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberDeposit.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>금일 회원 출금</span>
                  <strong>
                    {todayMemberWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberWithdrawal.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#81A9F0' }}>
                  <span>금일 파트너 입금</span>
                  <strong>
                    {todayPartnerDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerDeposit.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>금일 파트너 출금</span>
                  <strong>
                    {todayPartnerWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerWithdrawal.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="HONOR">
                  <span>내 지갑머니</span>
                  <strong>{holdingMoney?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="GOLD_SLOT">
                  <span>내 카지노 머니</span>
                  <strong>{holdingTotalGameMoney?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="HOLD">
                  <span>내 홀덤머니</span>
                  <strong>{holdingHoldemMoney?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="GOLD_EVO">
                  <span>내 롤링포인트</span>
                  <strong>{holdingMemberRollingPoint?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>내 루징포인트</span>
                  <strong>{holdingMemberLosingPoint?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
              </SidebarTopInfoBox>
              <SidebarContent
                onClick={e => {
                  if (isMobile) {
                    dispatch(setHideSideBar(true))
                  }
                }}
                collapse={collapse}
              />
            </SidebarWrapper>
          </SidebarScroll>
        </SidebarWrap>
      ) : (
        <></>
      )}
    </>
  )
}

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  collapse: PropTypes.bool.isRequired,
}

export default Sidebar

// region STYLES

const SidebarWrapper = styled.div`
  display: block;
`

const SidebarScroll = styled(Scrollbar)`
  width: 240px;
  padding-top: ${props => (props.isMobile ? '25px' : '0px')};
  & > div {
    height: calc(100vh - 110px);
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`

const SidebarWrap = styled.div`
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 240px;

  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  transition: transform 0.3s;
  background: #303030;
  top: 0;
  padding-top: 80px;
  z-index: 99;
  display: block;
  transform: translateX(0);
`

const SidebarBack = styled.button`
  width: 100vw;
  position: fixed;
  display: ${props => (props.show ? 'block' : 'none')};
  background: transparent;
  // border: none;
  top: 60px;
  bottom: 0;
  border: solid 2px red;
  z-index: 300;

  // @media screen and (min-width: 576px) {
  //   display: none;
  // }
`

const getColor = type => {
  switch (true) {
    case type === 'HONOR':
      return '#ff4ddd'
    case type === 'GOLD_SLOT':
      return '#07d2ed'
    case type === 'GOLD_EVO':
      return '#edacb1'
    case type === 'HOLD':
      return '#eee'
    default:
      return '#fff'
  }
}

const SidebarTopInfo = styled.div`
  width: 100%;
  // height: 400px;

  color: ${props => getColor(props.type)};

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  span {
    font-size: 13px;
    font-weight: 700;
  }

  strong {
    font-size: 13px;
    float: right;
  }
`

const SidebarTopInfoBox = styled.div`
  width: 100%;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 7px;
  display: flex;
  flex-direction: column;
`

// endregion
