import { useEffect, useState } from 'react'
import axios from '../base/axios'

// Flag 상태 변경
export async function updateSportsMarketInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/game-info/market',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 스포츠 결과 수동 업데이트
export async function updateSportsEventResult(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/result',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 개인회워 스포츠 결과 수동 업데이트
export async function updateMemberSportsEventResult(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/result/member',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
