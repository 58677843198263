import React from 'react'
import styled from 'styled-components'
import { BettingResultEnums } from '../../../enums/Log/bettingEnums'
import { convertToKstDateTime, convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { colorLightText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { casinoVendorEnums } from '../../../enums/SystemManagement/casinoEnums'
import { MoneyIncDecAuthorityEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberInfo from '../../MemberManagement/PartnerList/MemberInfo/MemberInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const BettingLogListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅(처리)시간',
      accessor: 'bettingTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.bettingTime)
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {MoneyIncDecAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberInfo memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '상위 파트너ID/명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: '배팅전 잔액',
      accessor: 'bettingBeforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingBeforeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리액(배팅 / 당첨)',
      accessor: 'bettingAmountCasino',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.bettingResult === 'BET') {
          return (
            <span style={{ fontWeight: '700', color: 'red' }}>
              {original.processAmount.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return <span style={{ fontWeight: '500' }}>{original.processAmount.toString().replace(commonReg2, ',')}</span>
      },
    },
    {
      Header: '결과',
      accessor: 'bettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <BettingResultWrap bettingResult={original.bettingResult}>
            {BettingResultEnums[original.bettingResult]}
          </BettingResultWrap>
        )
      },
    },

    {
      Header: '배팅번호',
      accessor: 'bettingNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임라운드',
      accessor: 'gameRound',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임종류',
      accessor: 'gameType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.gameType || '-'
      },
    },
    {
      Header: '게임명',
      accessor: 'gameName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.gameName || '-'
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default BettingLogListData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'green'
    default:
      return colorLightText
  }
}

const BettingResultWrap = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${props => getColor(props.bettingResult)};
`
