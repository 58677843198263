export const UserAuthorityEnums = {
  MEMBER: '회원',
  PARTNER: '파트너',
}

export const SettlementSelectEnums = {
  ALL_USER: '직영 전부',
  MEMBER: '직영 회원만',
  PARTNER: '직영 파트너만',
}

export const UserAuthorityEnumsSelectOptions = () => {
  return Object.entries(UserAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const ExchagneDateEnums = {
  EVERY: '항상',
  FIRST: '매월1일',
  HALF: '매월15일',
  FIRSTANDHALF: '매월1일,15일',
}

export const ExchagneDateEnumsSelectOptions = () => {
  return Object.entries(ExchagneDateEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const RecommendCodeStatusEnums = {
  true: '허용',
  false: '비허용',
}

export const RecommendCodeStatusEnumsRadioOptions = () => {
  return Object.entries(RecommendCodeStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'recommendCodeStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'recommendCodeStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserPointExchangeAuto = {
  true: '승인 필요없음',
  false: '승인 필요함',
}

export const UserPointExchangeAutoEnumsRadioOptions = () => {
  return Object.entries(UserPointExchangeAuto).map(([key, value], i) =>
    i === 0
      ? {
          name: 'userPointExchangeAuto',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userPointExchangeAuto',
          label: value,
          radioValue: key,
        },
  )
}

export const MemberStatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  BLOCK: '블록',
  CANCEL: '취소',
}

export const PartnerStatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  CANCEL: '취소',
}

export const MemberStatusEnumsSelectOptions = () => {
  return Object.entries(MemberStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberModifyStatusEnums = {
  NORMAL: '정상',
  BLOCK: '블록',
  WAITING: '대기',
  CANCEL: '취소',
  APPLY: '신청',
}

export const MemberModifyStatusEnumsSelectOptions = () => {
  return Object.entries(MemberModifyStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberDepositLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
}

export const MemberCasinoLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  // SIX: '6LV',
  // SEVEN: '7LV',
  // EIGHT: '8LV',
  // NINE: '9LV',
  // TEN: '10LV',
}
export const MemberMiniGameLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  // SIX: '6LV',
  // SEVEN: '7LV',
  // EIGHT: '8LV',
  // NINE: '9LV',
  // TEN: '10LV',
}
export const MemberSportsLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  // SIX: '6LV',
  // SEVEN: '7LV',
  // EIGHT: '8LV',
  // NINE: '9LV',
  // TEN: '10LV',
}

export const MemberCasinoLevelEnumSelectOptions = () => {
  return Object.entries(MemberCasinoLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberMiniGameLevelEnumSelectOptions = () => {
  return Object.entries(MemberMiniGameLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberSportsLevelEnumSelectOptions = () => {
  return Object.entries(MemberSportsLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
