import axios from 'axios'
import { deleteAccessToken, getAccessToken, setAccessToken } from '../../token'
import { handleReAuthentication } from '../../../socketio/userAuthSocketIOHandler'

// export const API_URL = 'http://localhost:5052'

// 서버
export const API_URL = ''

let isRefreshing = false
let refreshSubscribers = []

const onRefreshed = token => {
  refreshSubscribers.map(callback => callback(token))
}

const addRefreshSubscriber = callback => {
  refreshSubscribers.push(callback)
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
})

axiosInstance.interceptors.request.use(
  config => {
    // 요청 바로 직전
    // axios 설정값에 대해 작성합니다.

    config.headers.Accept = 'application/json'
    config.headers.ContentType = 'application/json'

    config.headers.Authorization = `Bearer ${getAccessToken()}`

    // eslint-disable-next-line no-useless-escape
    config.headers['X-Client-Host'] = window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')

    return config
  },
  error => {
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error)
  },
)

/**
 * 토큰 재발급
 * @see : https://thinkforthink.tistory.com/372
 */
const refreshAccessToken = async () => {
  if (!isRefreshing) {
    isRefreshing = true
    try {
      const response = await handleReAuthentication().then(res => {
        if (!res.status) {
          throw Error
        }

        return res
      })

      const newToken = response.data.accessToken

      setAccessToken(newToken)
      isRefreshing = false
      onRefreshed(newToken)
      refreshSubscribers = []
      return newToken
    } catch (error) {
      isRefreshing = false
      throw error
    }
  }
  return new Promise(resolve => {
    addRefreshSubscriber(token => {
      resolve(token)
    })
  })
}

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest.isRetry) {
      originalRequest.isRetry = true

      try {
        const newToken = await refreshAccessToken()

        originalRequest.headers.Authorization = `Bearer ${newToken}`
        return axiosInstance(originalRequest)
      } catch (err) {
        deleteAccessToken()
        window.location.href = '/login' // 필요 시 로그인 페이지로 리디렉션
      }
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
