export const MoneyAssetTypeEnums = {
  NORMAL_MONEY: '일반머니',
  HOLDEM_MONEY: '홀덤머니',
}

export const MoneyAssetTypeEnumsSelectOptions = () => {
  return Object.entries(MoneyAssetTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MoneyIncDecTypeEnums = {
  MONEY: '지갑 머니',
  MEMBER_POINT: '포인트',
  ROLLINGPOINT: '롤링비',
  LOSINGPOINT: '루징비',
  HOLDEM_MONEY: '홀덤머니',
}

export const MoneyExchangeTypeEnums = {
  ROLLING: '롤링액',
  LOSING: '루징액',
}

export const IncDecTypeEnums = {
  INC: '지급',
  DEC: '회수',
}

export const MoneyIncDecTypeEnumsSelectOptions = () => {
  return Object.entries(MoneyIncDecTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const DepoWithdrawTypeEnums = {
  DEPOSIT: '입금',
  WITHDRAWAL: '출금',
}

export const DepoWithdrawTypeEnumsSelectOptions = () => {
  return Object.entries(DepoWithdrawTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MoneyIncDecAuthorityEnums = {
  MEMBER: '회원',
  PARTNER: '파트너',
}

export const AuthorityTypeEnumsSelectOptions = () => {
  return Object.entries(MoneyIncDecAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
