export const OneToOneAuthorityEnums = {
  PARTNER: '파트너',
  MEMBER: '회원',
}

export const OneToOneAuthoritySelectOptions = () => {
  return Object.entries(OneToOneAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const OneToOneAnswerStatusEnums = {
  COMPLETE: '완료',
  INCOMPLETE: '미완료',
}

export const OneToOneAnswerStatusSelectOptions = () => {
  return Object.entries(OneToOneAnswerStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
