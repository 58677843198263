import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  MiniGameBettingResultEnums,
  MiniGameBettingResultEnumsSelectOptions,
  TokenGameTypeEnums,
  TokenGameTypeEnumsSelectOptions,
} from '../../../enums/Log/miniGameBettingEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import { searchTokenGameBettingLog } from '../../../utils/api/logManage/logApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberInfo from '../../MemberManagement/PartnerList/MemberInfo/MemberInfo'
import BettingManagementTabs from '../BettingManagementTabs'

const TokenGameBettingLogPage = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState(null)

  const [totalElement, setTotalElement] = useState(0)
  const [tokenGameBettingLogs, setTokenGameBettingLogs] = useState([])

  const fetchTokenGameBettingLog = async () => {
    searchTokenGameBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setTokenGameBettingLogs(res.data.content)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    if (params === null) return

    setLoading(true)
    fetchTokenGameBettingLog()
  }, [params])

  const [userAuthority, setUserAuthority] = useState(null)

  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [selectedTokenGameBettingLog, setSelectedTokenGameBettingLog] = useState({})

  // 체크박스
  const onCheckTokenGameBettingLogHandler = tokenGameBettingLog => {
    setSelectedTokenGameBettingLog(prev => {
      // 이미 키가 존재하는지 확인
      if (prev[tokenGameBettingLog.tokenGameBettingLogKey]) {
        // 키가 존재하면 해당 키를 제거
        const { [tokenGameBettingLog.tokenGameBettingLogKey]: value, ...rest } = prev
        return rest
      }
      // 키가 존재하지 않으면 새로운 키 추가
      return { ...prev, [tokenGameBettingLog.tokenGameBettingLogKey]: tokenGameBettingLog }
    })
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = MiniGameBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = TokenGameTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      gameResult: !searchGameResult ? null : searchGameResult,
      miniGameType: !searchGameType ? null : searchGameType,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      page: 0,
    }))
  }

  const getResultComp = miniGameBettingResult => {
    switch (miniGameBettingResult) {
      case 'ING':
        return (
          <button
            type="button"
            style={{
              width: '60px',
              height: '25px',
              background: 'green',
              color: 'white',
              fontWeight: '900',
              border: 'none',
            }}
          >
            {MiniGameBettingResultEnums[miniGameBettingResult]}
          </button>
        )
      case 'WIN':
        return (
          <button
            type="button"
            style={{
              width: '60px',
              height: '25px',
              background: 'blue',
              color: 'white',
              fontWeight: '900',
              border: 'none',
            }}
          >
            {MiniGameBettingResultEnums[miniGameBettingResult]}
          </button>
        )
      case 'LOSE':
        return (
          <button
            type="button"
            style={{
              width: '60px',
              height: '25px',
              background: 'red',
              color: 'white',
              fontWeight: '900',
              border: 'none',
            }}
          >
            {MiniGameBettingResultEnums[miniGameBettingResult]}
          </button>
        )
      default:
        return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
    }
  }

  return (
    <Card>
      <CardBody>
        {loading && <Loading />}
        <BettingManagementTabs activeKey="10" />
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="게임결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임종류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchStartAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchStartAmount}
                  onChange={e => onSearchStartAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchEndAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchEndAmount}
                  onChange={e => onSearchEndAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
        </SearchContainer>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>

        <TokenGameBettingLogContentWrap>
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>상위파트너 (이름)</th>
                <th>유저 (이름)</th>
                <th>게임 종류</th>
                <th>배팅 번호</th>
                <th>배팅 종류</th>
                <th>라운드</th>
                <th>배팅전 머니</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>배팅후 머니</th>
                <th>배당</th>
                <th>당첨금</th>
                <th>배팅 결과</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {tokenGameBettingLogs.length !== 0 &&
                tokenGameBettingLogs.map(tokenGameBettingLog => {
                  return (
                    <>
                      <tr key={tokenGameBettingLogs.tokenGameBettingLogKey}>
                        <td>{tokenGameBettingLog.num}</td>
                        <td>{tokenGameBettingLog.edgeName}</td>
                        <td>{convertToKstSportsEventDateTime(tokenGameBettingLog.bettingAt)}</td>
                        <td>{tokenGameBettingLog.topPartnerName ? <>{tokenGameBettingLog.topPartnerName}</> : '-'}</td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${tokenGameBettingLog.userId}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {tokenGameBettingLog.userId} ({tokenGameBettingLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={tokenGameBettingLog.userAuthority}>
                              {NoteAuthorityEnums[tokenGameBettingLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          <span>
                            <MemberInfo memberId={tokenGameBettingLog.userId} />
                          </span>
                        </td>
                        <td
                          style={{
                            background: 'rgba(237, 222, 88 ,0.5',
                            opacity: '0.6',
                            color: '#945b04',
                            fontWeight: '900',
                          }}
                        >
                          {TokenGameTypeEnums[tokenGameBettingLog.miniGameType]}
                        </td>
                        <td
                          style={{
                            background: 'rgba(237, 222, 88 ,0.5',
                            opacity: '0.6',
                            color: '#945b04',
                            fontWeight: '900',
                          }}
                        >
                          {tokenGameBettingLog.bettingType} ( {tokenGameBettingLog.bettingTypeKr} )
                        </td>
                        <td>{tokenGameBettingLog.transactionId}</td>
                        <td>{tokenGameBettingLog.gameRound}</td>
                        <td>{tokenGameBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {tokenGameBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{tokenGameBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                        <td>{tokenGameBettingLog.bettingPer}</td>
                        <td>
                          <span style={{ color: 'red' }}>
                            {tokenGameBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                          </span>
                        </td>
                        <td>{getResultComp(tokenGameBettingLog.miniGameBettingResult)}</td>
                        <td>
                          {tokenGameBettingLog.showFlag ? (
                            <span>노출</span>
                          ) : (
                            <span style={{ color: 'red' }}>비노출</span>
                          )}
                        </td>
                      </tr>
                    </>
                  )
                })}
              {tokenGameBettingLogs.length === 0 && (
                <tr>
                  <td colSpan={15}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '15px',
                        padding: '20px 0px',
                      }}
                    >
                      최근 게시물이 없습니다.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </TokenGameBettingLogContentWrap>
      </CardBody>
    </Card>
  )
}

export default TokenGameBettingLogPage

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`
const TokenGameBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const CustomTh = styled.th`
  background: green !important;
`

const CustomTh2 = styled.th`
  background: rgb(0, 104, 86) !important;
`

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead`
  th {
    font-size: 12px;
  }
`

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
  font-size: 13px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  cursor: pointer;
  &:hover {
    border: solid 2px red;
  }
  background: lightgray;
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameDeleteBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFoldeShowBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
  color: ${props => (props.showStatus ? 'blue' : 'red')} !important;
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderResultBox = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentCriteria = styled.div`
  font-weight: 500;
  color: green;
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgba(0, 128, 0, 1) !important;
  opacity: 0.6;
  color: white !important;
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-weight: 500;
  color: green;
  width: 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgb(0, 104, 86, 0.6) !important;
  color: white !important;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  width: 9%;
  display: flex;
  // border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  width: 6%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  height: 46px;
  border-left: solid 1px black;
`
