import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ko } from 'date-fns/esm/locale'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { searchMemberMoneyWithdrawalInfo } from '../../../../utils/api/memberHistory/memberHistoryApi'
import { commonReg2 } from '../../../../utils/validate/commonValidate'
import { convertToKst } from '../../../../utils/dateTime'
import MemberMoneyIncDecList from '../../MemberList/MemberMoneyIncDec/MemberMoneyIncDecList'
import MemberMoneyIncDecLisForData from './MemberMoneyIncDecLisForData'

function MemberInfoForm({ memberId, onClickHandler }) {
  const [content, setContent] = useState({})
  // const [dateContent, setDateContent] = useState({})
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  // Set searchFirst to the 1st day of the current month at 00:00:00
  const searchFirst = new Date(currentYear, currentMonth, 1, 0, 0, 0)

  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear
  const searchEnd = new Date(nextYear, nextMonth, 1, 0, 0, 0)

  const [bettingLogparams, setBettingLogParams] = useState({
    memberId,
    startDate: convertToKst(searchFirst),
    endDate: convertToKst(searchEnd),
  })
  const [searchStartDate, setSearchStartDate] = useState(searchFirst)
  const [searchEndDate, setSearchEndDate] = useState(searchEnd)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [dailyContent, setDailyContent] = useState({})
  const [monthContent, setMonthContent] = useState({})
  const [dateContent, setDateContent] = useState({})

  const fetchSearchMoneyInfo = async () => {
    searchMemberMoneyWithdrawalInfo(bettingLogparams).then(res => {
      setContent(res.data)
      const dailyBettingModel = res.data.dailyBettingWinningModel
      const monthBettingModel = res.data.monthBettingWinningModel
      const periodBettingModel = res.data.dateBettingWinningModel
      setDailyContent({
        dailyMoneyDepositAmount: dailyBettingModel.dailyMoneyDepositAmount,
        dailyMoneyBonusAmount: dailyBettingModel.dailyMoneyBonusAmount,
        dailyMoneyDepositCount: dailyBettingModel.dailyMoneyDepositCount,
        dailyMoneyWithdrawalAmount: dailyBettingModel.dailyMoneyWithdrawalAmount,
        dailyMoneyWithdrawlCount: dailyBettingModel.dailyMoneyWithdrawlCount,
        dailySportsBettingAmount: dailyBettingModel.dailySportsBettingAmount,
        dailySportsWinningAmount: dailyBettingModel.dailySportsWinningAmount,
        dailySportsRollingAmount: dailyBettingModel.dailySportsRollingAmount,
        dailyMiniGameBettingAmount: dailyBettingModel.dailyMiniGameBettingAmount,
        dailyMiniGameWinningAmount: dailyBettingModel.dailyMiniGameWinningAmount,
        dailyMiniGameRollingAmount: dailyBettingModel.dailyMiniGameRollingAmount,
        dailyVirtualGameBettingAmount: dailyBettingModel.dailyVirtualGameBettingAmount,
        dailyVirtualGameWinningAmount: dailyBettingModel.dailyVirtualGameWinningAmount,
        dailyVirtualGameRollingAmount: dailyBettingModel.dailyVirtualGameRollingAmount,
        dailyLiveCasinoBettingAmount: dailyBettingModel.dailyLiveCasinoBettingAmount,
        dailyLiveCasinoWinningAmount: dailyBettingModel.dailyLiveCasinoWinningAmount,
        dailyLiveCasinoRollingAmount: dailyBettingModel.dailyLiveCasinoRollingAmount,
        dailySlotCasinoBettingAmount: dailyBettingModel.dailySlotCasinoBettingAmount,
        dailySlotCasinoWinningAmount: dailyBettingModel.dailySlotCasinoWinningAmount,
        dailySlotCasinoRollingAmount: dailyBettingModel.dailySlotCasinoRollingAmount,
        dailyHoldemBettingAmount: dailyBettingModel.dailyHoldemBettingAmount,
        dailyHoldemWinningAmount: dailyBettingModel.dailyHoldemWinningAmount,
        dailyHoldemRollingAmount: dailyBettingModel.dailyHoldemRollingAmount,
      })
      setDateContent({
        dateMoneyDepositAmount: periodBettingModel.dateMoneyDepositAmount,
        dateMoneyBonusAmount: periodBettingModel.dateMoneyBonusAmount,
        dateMoneyDepositCount: periodBettingModel.dateMoneyDepositCount,
        dateMoneyWithdrawalAmount: periodBettingModel.dateMoneyWithdrawalAmount,
        dateMoneyWithdrawlCount: periodBettingModel.dateMoneyWithdrawlCount,
        dateSportsBettingAmount: periodBettingModel.dateSportsBettingAmount,
        dateSportsWinningAmount: periodBettingModel.dateSportsWinningAmount,
        dateSportsRollingAmount: periodBettingModel.dateSportsRollingAmount,
        dateMiniGameBettingAmount: periodBettingModel.dateMiniGameBettingAmount,
        dateMiniGameWinningAmount: periodBettingModel.dateMiniGameWinningAmount,
        dateMiniGameRollingAmount: periodBettingModel.dateMiniGameRollingAmount,
        dateVirtualGameBettingAmount: periodBettingModel.dateVirtualGameBettingAmount,
        dateVirtualGameWinningAmount: periodBettingModel.dateVirtualGameWinningAmount,
        dateVirtualGameRollingAmount: periodBettingModel.dateVirtualGameRollingAmount,
        dateLiveCasinoBettingAmount: periodBettingModel.dateLiveCasinoBettingAmount,
        dateLiveCasinoWinningAmount: periodBettingModel.dateLiveCasinoWinningAmount,
        dateLiveCasinoRollingAmount: periodBettingModel.dateLiveCasinoRollingAmount,
        dateSlotCasinoBettingAmount: periodBettingModel.dateSlotCasinoBettingAmount,
        dateSlotCasinoWinningAmount: periodBettingModel.dateSlotCasinoWinningAmount,
        dateSlotCasinoRollingAmount: periodBettingModel.dateSlotCasinoRollingAmount,
        dateHoldemBettingAmount: periodBettingModel.dateHoldemBettingAmount,
        dateHoldemWinningAmount: periodBettingModel.dateHoldemWinningAmount,
        dateHoldemRollingAmount: periodBettingModel.dateHoldemRollingAmount,
      })
      setContent(res.data)
    })
  }
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchSearchMoneyInfo()
    // fetchSearchDateMoneyInfo()
  }, [memberId, bettingLogparams])

  const adjustTimes = date => {
    if (!date) return
    const adjustedDate = new Date(date)
    adjustedDate.setSeconds(0) // 초만 59로 조정
    return adjustedDate
  }

  const onSearchClickHandler = () => {
    if (!searchStartDate || !searchEndDate) {
      alert('기간 설정은 필수 입니다.')
      return
    }
    const adjustedStartDate = adjustTimes(searchStartDate)
    const adjustedEndDate = adjustTimes(searchEndDate)
    setBettingLogParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(adjustedStartDate),
      endDate: !searchEndDate ? null : convertToKst(adjustedEndDate),
    }))
  }
  const getResultAmount = (num1, num2) => {
    const resultAmount = num1 - num2

    if (resultAmount > 0) {
      return <td style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    if (resultAmount < 0) {
      return <td style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    return <td style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
  }

  const getResultAmount2 = (num1, num2) => {
    const resultAmount = num1 - num2

    if (resultAmount > 0) {
      return (
        <td colSpan={2} style={{ color: 'blue', fontWeight: '500' }}>
          {resultAmount.toString().replace(commonReg2, ',')}
        </td>
      )
    }
    if (resultAmount < 0) {
      return (
        <td colSpan={2} style={{ color: 'red', fontWeight: '500' }}>
          {resultAmount.toString().replace(commonReg2, ',')}
        </td>
      )
    }
    return (
      <td colSpan={2} style={{ color: 'black', fontWeight: '500' }}>
        {resultAmount.toString().replace(commonReg2, ',')}
      </td>
    )
  }

  return (
    <MemberInfoWrap>
      <MemberInfoHeader>
        [{memberId}] 회원의 <span style={{ color: 'red', fontWeight: '600' }}>금일, 기간내</span> 내역입니다.
      </MemberInfoHeader>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
          <p>
            * 아래 금일 및 기간내의 카지노 배팅액/당첨액은{' '}
            <span style={{ fontWeight: 'bolder', color: 'red' }}>타이제외</span> 한 금액입니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <table>
        <tbody>
          <tr>
            <th colSpan={1}>아이디</th>
            <td colSpan={3}>{content?.memberId}</td>
            <th colSpan={1}>회원명</th>
            <td colSpan={1}>{content?.memberName}</td>
          </tr>
          <tr>
            <th colSpan={1} style={{ color: 'yellow' }}>
              금일 입금 횟수
            </th>
            <td>{dailyContent?.dailyMoneyDepositCount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1} style={{ color: 'yellow' }}>
              금일 입금액
            </th>
            <td>{dailyContent?.dailyMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1} style={{ color: 'yellow' }}>
              금일 보너스액
            </th>
            <td>{dailyContent?.dailyMoneyBonusAmount?.toString().replace(commonReg2, ',')}</td>
          </tr>
          <tr>
            <th colSpan={1} style={{ color: 'yellow' }}>
              금일 출금 횟수
            </th>
            <td>{dailyContent?.dailyMoneyWithdrawlCount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1} style={{ color: 'yellow' }}>
              금일 출금액
            </th>
            <td>{dailyContent?.dailyMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'yellow' }}>
              금일 입출 차액
            </th>
            {getResultAmount2(
              Number(dailyContent?.dailyMoneyDepositAmount),
              Number(dailyContent?.dailyMoneyWithdrawalAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 배팅액
            </th>
            <td>{dailyContent?.dailySportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 당첨액
            </th>
            <td> {dailyContent?.dailySportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 롤링액
            </th>
            <td> {dailyContent?.dailySportsRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailySportsBettingAmount),
              Number(dailyContent?.dailySportsWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 카지노 배팅액
            </th>
            <td>{dailyContent?.dailyLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 카지노 당첨액
            </th>
            <td> {dailyContent?.dailyLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 카지노 롤링액
            </th>
            <td> {dailyContent?.dailyLiveCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 카지노 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailyLiveCasinoBettingAmount),
              Number(dailyContent?.dailyLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯 배팅액
            </th>
            <td>{dailyContent?.dailySlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯 당첨액
            </th>
            <td> {dailyContent?.dailySlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯 롤링액
            </th>
            <td> {dailyContent?.dailySlotCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailySlotCasinoBettingAmount),
              Number(dailyContent?.dailySlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 미니 배팅액
            </th>
            <td>{dailyContent?.dailyMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 미니 당첨액
            </th>
            <td> {dailyContent?.dailyMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 미니 롤링액
            </th>
            <td> {dailyContent?.dailyMiniGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 미니 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailyMiniGameBettingAmount),
              Number(dailyContent?.dailyMiniGameWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 가상 배팅액
            </th>
            <td>{dailyContent?.dailyVirtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 가상 당첨액
            </th>
            <td> {dailyContent?.dailyVirtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 가상 롤링액
            </th>
            <td> {dailyContent?.dailyVirtualGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 가상 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailyVirtualGameBettingAmount),
              Number(dailyContent?.dailyVirtualGameWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 배팅액
            </th>
            <td>{dailyContent?.dailyHoldemBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 당첨액
            </th>
            <td> {dailyContent?.dailyHoldemWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 롤링액
            </th>
            <td> {dailyContent?.dailyHoldemRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 수익
            </th>
            {getResultAmount(
              Number(dailyContent?.dailyHoldemBettingAmount),
              Number(dailyContent?.dailyHoldemWinningAmount),
            )}
          </tr>
        </tbody>
      </table>

      <SearchCardTitleWrap>
        <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
          <p>
            * 날짜 검색 시 <span style={{ fontWeight: 'bolder', color: 'red' }}>기간 설정</span>은 일별설정이며,
            필수사항입니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <table>
        <tbody>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 입금 횟수
            </th>
            <td>{dateContent?.dateMoneyDepositCount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 입금액
            </th>
            <td>{dateContent?.dateMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 보너스액
            </th>
            <td>{dateContent?.dateMoneyBonusAmount?.toString().replace(commonReg2, ',')}</td>
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 출금 횟수
            </th>
            <td>{dateContent?.dateMoneyWithdrawlCount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 출금액
            </th>
            <td>{dateContent?.dateMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'pink' }}>
              <span style={{ color: 'pink' }}>기간내</span> 입출 차액
            </th>
            {getResultAmount2(
              Number(dateContent?.dateMoneyDepositAmount),
              Number(dateContent?.dateMoneyWithdrawalAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 스포츠 배팅액
            </th>
            <td>{dateContent?.dateSportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 스포츠 당첨액
            </th>
            <td> {dateContent?.dateSportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 스포츠 롤링액
            </th>
            <td> {dailyContent?.dateSportsRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 스포츠 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateSportsBettingAmount),
              Number(dateContent?.dateSportsWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 카지노 배팅액
            </th>
            <td>{dateContent?.dateLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 카지노 당첨액
            </th>
            <td> {dateContent?.dateLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 카지노 롤링액
            </th>
            <td> {dateContent?.dateLiveCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 카지노 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateLiveCasinoBettingAmount),
              Number(dateContent?.dateLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 슬롯 배팅액
            </th>
            <td>{dateContent?.dateSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 슬롯 당첨액
            </th>
            <td> {dateContent?.dateSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 슬롯 롤링액
            </th>
            <td> {dateContent?.dateSlotCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 슬롯 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateSlotCasinoBettingAmount),
              Number(dateContent?.dateSlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 미니 배팅액
            </th>
            <td>{dateContent?.dateMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 미니 당첨액
            </th>
            <td> {dateContent?.dateMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 미니 롤링액
            </th>
            <td> {dateContent?.dateMiniGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 미니 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateMiniGameBettingAmount),
              Number(dateContent?.dateMiniGameWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 가상 배팅액
            </th>
            <td>{dateContent?.dateVirtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 가상 당첨액
            </th>
            <td> {dateContent?.dateVirtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 가상 롤링액
            </th>
            <td> {dateContent?.dateVirtualGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 가상 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateVirtualGameBettingAmount),
              Number(dateContent?.dateVirtualGameWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 배팅액
            </th>
            <td>{dateContent?.dateHoldemBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 당첨액
            </th>
            <td> {dateContent?.dateHoldemWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 롤링액
            </th>
            <td> {dateContent?.dateHoldemRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={1}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateHoldemBettingAmount),
              Number(dateContent?.dateHoldemWinningAmount),
            )}
          </tr>
        </tbody>
      </table>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
          <p>
            * 해당 유저의 <span style={{ fontWeight: 'bolder', color: 'red' }}>머니증감</span>내역을 확인 하실 수
            있습니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <MemberMoneyIncDecLisForData memberId={memberId} />
    </MemberInfoWrap>
  )
}

export default MemberInfoForm

MemberInfoForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 320px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff !important;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const MemberInfoHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`
