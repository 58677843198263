import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import PointTypeSearchButton from '../../../shared/components/PointTypeSearchButton'
import { convertToKst } from '../../../utils/dateTime'
import StatisticsTabs from '../StatisticsTabs'
import MemberPointExchangeListData from './MemberPointExchangeListData'
import { useSearchUserPointExchange } from '../../../utils/api/partnerHistory/partnerHistoryApi'

const MemberPointExchangeList = () => {
  /**
   * 포인트 전환내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [pointType, setPointType] = useState(null)

  const [params, setParams] = useState({
    pointType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      pointType: !pointType ? null : pointType,
    }))
  }, [pointType])

  const { totalElement, content, fetchSearchUserPointExchange } = useSearchUserPointExchange(params)

  const [pointExchangeListData, setPointExchangeListData] = useState(MemberPointExchangeListData(content))

  useEffect(() => {
    setPointExchangeListData(MemberPointExchangeListData(content))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디 , 수신, 구분 , 상태 , 처리일

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }
  const [activeKey, setActiveKey] = useState('5')
  return (
    <Card>
      <CardBody>
        <StatisticsTabs activeKey={activeKey} />
        <PointTypeSearchButton
          pointType={pointType}
          onClickHandler={e => {
            setPointType(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase
          key="common"
          columns={pointExchangeListData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
        />
      </CardBody>
    </Card>
  )
}

export default MemberPointExchangeList
