import { Button } from '@/shared/components/Button'
import { Card, CardBody } from '@/shared/components/Card'
import Error from '@/shared/components/form/Error'
import { FormContainer } from '@/shared/components/form/FormElements'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchMemberInfo } from '../../redux/memberInfoSlice'
import Loading from '../../shared/components/Loading'
import { createUserMoneyWithdrawal, searchMinimumAmount } from '../../utils/api/partnerManage/partnerManageApi'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MemberHistoryTabs from '../MemberHistory/MemberHistoryTabs'
import { PageHeader, PageSubHeaderInfo, PageSubHeaderWrap, PageWrap } from './PageStyleElements'
import {
  CustomMoneyFormGroupField,
  MoneyFormButtomInfo,
  MoneyFormButton,
  MoneyFormGroup,
  MoneyFormModifyButtomInfo,
  MoneyFormModifyButton,
} from './newForm'

/**
 * 입금 신청
 */
const WithdrawalPage = () => {
  // 자산 조회
  const [writeLoading, setWriteLoading] = useState(false)
  const [params, setParams] = useState({})

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  // 출금 금액
  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState('0')
  const [withdrawlTerm, setWithdrawalMaxTerm] = useState(0)
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  useEffect(() => {
    setMyInfoLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setWithdrawalMaxAmount(res.data.withdrawalMaxAmount)
        setWithdrawalMaxTerm(res.data.withdrawlTerm)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])
  const dispatch = useDispatch()
  const onSubmit = () => {
    if (writeLoading) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }
    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원 단위로 입력이 가능합니다.')
      return
    }

    // if (Number(checkMoney) > Number(withdrawalMaxAmount)) {
    //   alert(`최대 ${withdrawalMaxAmount?.toString().replace(commonReg2, ',')}원 이하로 출금 가능합니다.`)
    //   return
    // }

    if (Number(checkMoney) > Number(holdingMoney)) {
      alert('보유머니를 다시 확인해주세요.')
      return
    }
    const body = {
      applyMoney: checkMoney,
    }
    setWriteLoading(true)
    createUserMoneyWithdrawal(body)
      .then(res => {
        setWriteLoading(false)
        dispatch(fetchMemberInfo())
        setApplyMoney('0')
        onClearClickHandler()
        alert('출금 신청이 완료되었습니다.')
      })
      .catch(error => {
        setWriteLoading(false)
        const { errorCode, message } = error.response.data
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          case 'ASSET-1011':
            alert('출금이 불가능한 시간입니다.')
            break
          case 'ASSET-1005':
            alert(message)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }
      })
  }
  const [activeKey, setActiveKey] = useState('4')
  return (
    <Card>
      <CardBody>
        <PageWrap>
          <MemberHistoryTabs activeKey={activeKey} />
          <PageSubHeaderWrap>
            <PageHeader>출금신청</PageHeader>
          </PageSubHeaderWrap>
          <PageSubHeaderInfo style={{ color: 'red' }}>
            - 계좌거래시 입금/출금 이름이 동일해야 입출금이 가능하오니 정확히 입력해 주세요.
          </PageSubHeaderInfo>
          <MoneyExchangeWrap>
            {writeLoading ? (
              <Loading />
            ) : (
              <MoneyExchangeAllWrapper>
                <Form
                  onSubmit={onSubmit}
                  // validate={partnerMoneyDepositValidate}
                  initialValues={{
                    applyMoney,
                    bankName,
                    userName,
                    accountNum,
                    holdingMoney: holdingMoney?.toString().replace(commonReg2, ','),
                  }}
                >
                  {({ handleSubmit, form: { reset } }) => (
                    <MoneyFormCotainer horizontal onSubmit={handleSubmit}>
                      <MoneyExchangeWrapSecond>
                        <MoneyExchangeInputWrapper>
                          <MoneyExchangeInputLabel>예금주</MoneyExchangeInputLabel>
                          <MoneyExchangeInputDiv>{userName}</MoneyExchangeInputDiv>
                        </MoneyExchangeInputWrapper>
                        <MoneyExchangeInputWrapper>
                          <MoneyExchangeInputLabel style={{ borderLeft: 'solid 1px black' }}>
                            은행명
                          </MoneyExchangeInputLabel>
                          <MoneyExchangeInputDiv>{bankName}</MoneyExchangeInputDiv>
                        </MoneyExchangeInputWrapper>
                      </MoneyExchangeWrapSecond>
                      <MoneyExchangeWrapSecond>
                        <MoneyExchangeInputWrapper>
                          <MoneyExchangeInputLabel>계좌번호</MoneyExchangeInputLabel>
                          <MoneyExchangeInputDiv>{accountNum}</MoneyExchangeInputDiv>
                        </MoneyExchangeInputWrapper>
                      </MoneyExchangeWrapSecond>
                      <MoneyExchangeWrapSecond>
                        <MoneyExchangeInputWrapper>
                          <MoneyExchangeInputLabel>현재 보유머니</MoneyExchangeInputLabel>
                          <MoneyExchangeInputDiv>
                            {holdingMoney?.toString().replace(commonReg2, ',')}
                          </MoneyExchangeInputDiv>
                        </MoneyExchangeInputWrapper>
                      </MoneyExchangeWrapSecond>
                      <MoneyExchangeWrapSecond>
                        <MoneyExchangeInputWrapper>
                          <MoneyExchangeInputLabel>출금 신청금액</MoneyExchangeInputLabel>
                          <Field name="applyMoney">
                            {({ input, meta }) => (
                              <>
                                <MoneyInputWrap>
                                  <input
                                    {...input}
                                    type="text"
                                    style={{ fontSize: '16px' }}
                                    value={applyMoney}
                                    placeholder="숫자만 입력하세요"
                                    onChange={e => {
                                      const { value } = e.target
                                      const onlyNumber = value.replace(/[^0-9]/g, '').replace(commonReg2, ',')
                                      setApplyMoney(onlyNumber)
                                    }}
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </MoneyInputWrap>
                              </>
                            )}
                          </Field>
                        </MoneyExchangeInputWrapper>
                      </MoneyExchangeWrapSecond>
                      <MoneyExchangeWrapSecond style={{ border: 'none' }}>
                        <MoneyFormGroup style={{ justifyContent: 'left' }}>
                          <CustomMoneyFormGroupField>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="10000"
                              onClick={e => {
                                onClickMoneyHandler('10000')
                              }}
                            >
                              <MoneyFormButtomInfo>1만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="50000"
                              onClick={e => {
                                onClickMoneyHandler('50000')
                              }}
                            >
                              <MoneyFormButtomInfo>5만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="100000"
                              onClick={e => {
                                onClickMoneyHandler('100000')
                              }}
                            >
                              <MoneyFormButtomInfo>10만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="500000"
                              onClick={e => {
                                onClickMoneyHandler('500000')
                              }}
                            >
                              <MoneyFormButtomInfo>50만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                          </CustomMoneyFormGroupField>
                        </MoneyFormGroup>
                      </MoneyExchangeWrapSecond>

                      <MoneyExchangeWrapSecond style={{ border: 'none' }}>
                        <MoneyFormGroup style={{ justifyContent: 'left' }}>
                          <CustomMoneyFormGroupField>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="1000000"
                              onClick={e => {
                                onClickMoneyHandler('1000000')
                              }}
                            >
                              <MoneyFormButtomInfo>100만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              name="5000000"
                              onClick={e => {
                                onClickMoneyHandler('5000000')
                              }}
                            >
                              <MoneyFormButtomInfo>500만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormButton
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              onClick={e => {
                                onClickMoneyHandler('10000000')
                              }}
                            >
                              <MoneyFormButtomInfo>1000만원</MoneyFormButtomInfo>
                            </MoneyFormButton>
                            <MoneyFormModifyButton
                              $active
                              size="sm"
                              type="button"
                              variant="outline-secondary"
                              onClick={() => {
                                onClearClickHandler()
                              }}
                            >
                              <MoneyFormModifyButtomInfo>정정</MoneyFormModifyButtomInfo>
                            </MoneyFormModifyButton>{' '}
                          </CustomMoneyFormGroupField>
                        </MoneyFormGroup>
                      </MoneyExchangeWrapSecond>
                      <MoneyButtonWrapper>
                        <ExchangeFormButton variant="primary" type="submit">
                          출금신청
                        </ExchangeFormButton>
                      </MoneyButtonWrapper>
                    </MoneyFormCotainer>
                  )}
                </Form>
              </MoneyExchangeAllWrapper>
            )}
          </MoneyExchangeWrap>
          {/* <PageSubHeaderInfo style={{ fontSize: '15px', marginBottom: '15px' }}>&lt;입금내역&gt;</PageSubHeaderInfo> */}
          {/* <PageTableWrap>
        <ReactTableBase columns={partnerDepositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </PageTableWrap> */}
        </PageWrap>
      </CardBody>
    </Card>
  )
}

export default WithdrawalPage
const MoneyFormCotainer = styled(FormContainer)`
  gap: 15px;
  display: flex;
  flex-direction: column;
`

const MoneyInputWrap = styled.div`
  position: relative;
  width: 100%;
  color: black;
  background-color: white;
`

// 테이블 wrap
const PageTableWrap = styled.div`
  width: 100%;
  min-height: 780px;
`

const ExchangeFormButton = styled(Button)`
  font-weight: 900;
  padding: 5px;
  margin-top: 10px;
  width: 120px;
  height: 50px;
  background-color: #337ab7;
  font-size: 20px;
`

// 검색
export const MoneyExchangeWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  padding: 4px 0;
`
export const MoneyExchangeWrapSecond = styled.div`
  border: solid 1px black;
  width: 500px !important;
  display: flex;
`
export const MoneyExchangeInputWrapper = styled.div`
  border-left: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-collapse: collpase;
`

export const MoneyButtonWrapper = styled.div`
  border-left: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
`

export const MoneyExchangeAllWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const MoneyExchangeInputLabel = styled.div`
  background-color: #d3d3d3;
  border-right: solid 1px black;
  height: 40px;
  padding-top: 7px;
  padding-left: 5px;
  width: 200px;
  font-size: 17px;
  font-weight: 900;
`

export const MoneyExchangeInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  background: transparent;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s,
    -webkit-box-shadow 0.15s ease-in-out 0s;
`

export const MoneyExchangeInputDiv = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  background: transparent;
`
