import { Card, CardBody } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'
import { CustomCol } from '../../shared/components/CustomCol'

const MemberManageMentTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <CustomCol md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <BorderedBottomTabs>
            <Tab.Container defaultActiveKey={activeKey}>
              <TabsWrap>
                <Nav className="nav-tabs">
                  <NavItem>
                    <NavLink
                      eventKey="1"
                      onClick={e => {
                        history.push('/user/management/member')
                      }}
                    >
                      유저 조회(간략)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="3"
                      onClick={e => {
                        history.push('/user/management/partner')
                      }}
                    >
                      유저 조회(세부)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="4"
                      onClick={e => {
                        history.push('/user/management/registration')
                      }}
                    >
                      유저 등록
                    </NavLink>
                  </NavItem>
                </Nav>
              </TabsWrap>
            </Tab.Container>
          </BorderedBottomTabs>
        </CardBody>
      </Card>
    </CustomCol>
  )
}
export default MemberManageMentTabs

MemberManageMentTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
