import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { casinoVendorEnums } from '../../../../../enums/SystemManagement/casinoEnums'
import {
  searchUserCasinoVendorInfo,
  updateUserCasinoVendorInfo,
} from '../../../../../utils/api/memberManage/memberCasinoVendorInfoApi'

const MemberCasinoVendorInfo = ({ memberId }) => {
  const [casinoVendorInfos, setCasinoVendorInfos] = useState({
    liveVendors: [],
    slotVendors: [],
  })

  const fetchUserCasinoVendorInfo = async () => {
    await searchUserCasinoVendorInfo(memberId)
      .then(res => {
        setCasinoVendorInfos({
          liveVendors: res.data.liveVendors,
          slotVendors: res.data.slotVendors,
        })
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchUserCasinoVendorInfo()
  }, [memberId])

  const onCheckVendorIsUsedHandler = (vendorType, switchingVendorName, agencyName, isChecked) => {
    // vendorType에 따라 배열을 선택합니다.
    const vendorList = vendorType === 'LIVE' ? casinoVendorInfos.liveVendors : casinoVendorInfos.slotVendors

    // 배열을 순회하며 해당 업체를 찾습니다.
    const updatedVendorList = vendorList.map(vendor => {
      if (vendor.switchingVendorName === switchingVendorName) {
        return {
          ...vendor,
          agencyShow: {
            ...vendor.agencyShow,
            [agencyName]: isChecked,
          },
        }
      }
      return vendor
    })

    // 상태를 업데이트합니다.
    if (vendorType === 'LIVE') {
      setCasinoVendorInfos(prevState => ({
        ...prevState,
        liveVendors: updatedVendorList,
      }))
    } else {
      setCasinoVendorInfos(prevState => ({
        ...prevState,
        slotVendors: updatedVendorList,
      }))
    }
  }

  const onClickUserCasinoVendorInfoUpdateHandler = () => {
    const body = {
      casinoVendorInfo: JSON.stringify(casinoVendorInfos),
    }

    updateUserCasinoVendorInfo(memberId, body)
      .then(res => {
        fetchUserCasinoVendorInfo()
        alert('변경되었습니다.')
      })
      .catch(error => {
        alert('변경 실패했습니다. 잠시후 다시 시도해주세요.')
      })
  }

  return (
    <>
      <h3 style={{ margin: '15px 0 0 0', fontWeight: '500' }}>카지노 업체 설정</h3>
      <HaederInfo>* {memberId} 유저에게 노출시킬 카지노 업체를 설정할 수 있습니다. </HaederInfo>
      <HaederInfo>* 업체별로 체크 후 변경하기 버튼을 꼭 눌러주세요. </HaederInfo>
      <HaederInfo>* 중복 체크시 유저에게 이중으로 보이니 주의바랍니다. </HaederInfo>

      <UserCasinoVendorInfoUpdateBtn
        variant="secondary"
        type="button"
        size="lg"
        onClick={e => {
          onClickUserCasinoVendorInfoUpdateHandler()
        }}
      >
        변경하기
      </UserCasinoVendorInfoUpdateBtn>

      {casinoVendorInfos.liveVendors.length > 0 && (
        <>
          <AgencyHeaderInfo>[ 라이브 ]</AgencyHeaderInfo>
          <VendorInfoWrap>
            {casinoVendorInfos.liveVendors.map((vendor, index) => (
              <VendorInfoBox key={`${vendor.switchingVendorName}`}>
                <VendorInfoNameBox>
                  <div>{vendor.switchingVendorName}</div>
                  <div>({vendor.switchingVendorNameKr})</div>
                </VendorInfoNameBox>
                <VendorInfoCheckBox>
                  {Object.entries(vendor.agencyShow).map(([key, value]) => {
                    return (
                      <label htmlFor={`venodr${index}`}>
                        <span> {casinoVendorEnums[key]} </span>
                        <input
                          type="checkbox"
                          id={`venodr${index}`}
                          checked={value}
                          onChange={e => {
                            onCheckVendorIsUsedHandler(
                              vendor.vendorType,
                              vendor.switchingVendorName,
                              key,
                              e.target.checked,
                            )
                          }}
                        />
                      </label>
                    )
                  })}
                </VendorInfoCheckBox>
              </VendorInfoBox>
            ))}
          </VendorInfoWrap>
        </>
      )}

      {casinoVendorInfos.slotVendors.length > 0 && (
        <>
          <AgencyHeaderInfo>[ 슬롯 ]</AgencyHeaderInfo>
          <VendorInfoWrap>
            {casinoVendorInfos.slotVendors.map((vendor, index) => (
              <VendorInfoBox key={`${vendor.switchingVendorName}`}>
                <VendorInfoNameBox>
                  <div>{vendor.switchingVendorName}</div>
                  <div>({vendor.switchingVendorNameKr})</div>
                </VendorInfoNameBox>
                <VendorInfoCheckBox>
                  {Object.entries(vendor.agencyShow).map(([key, value]) => {
                    return (
                      <label htmlFor={`venodr${index}`}>
                        <span> {casinoVendorEnums[key]} </span>
                        <input
                          type="checkbox"
                          id={`venodr${index}`}
                          checked={value}
                          onChange={e => {
                            onCheckVendorIsUsedHandler(
                              vendor.vendorType,
                              vendor.switchingVendorName,
                              key,
                              e.target.checked,
                            )
                          }}
                        />
                      </label>
                    )
                  })}
                </VendorInfoCheckBox>
              </VendorInfoBox>
            ))}
          </VendorInfoWrap>
        </>
      )}
    </>
  )
}

export default MemberCasinoVendorInfo

MemberCasinoVendorInfo.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const HaederInfo = styled.p`
  font-size: 14px;
`

const AgencyHeaderInfo = styled.div`
  width: 100%;
  color: black;
  font-size: 25px;
  font-weight: 500;
`

const VendorTypeInfo = styled.div`
  width: 100%;
  color: black;
  font-size: 24px;
  font-weight: 500;

  span {
    font-size: 12px;
    color: red;
  }
`

const VendorInfoWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`

const VendorInfoBox = styled.div`
  display: flex;

  // 첫번째 줄 보더
  &:nth-child(n + 1):nth-child(-n + 4) {
    border-top: 1px solid lightgrey;
  }

  // 맨 왼쪽 보더
  &:nth-child(4n + 1) {
    border-left: 1px solid lightgrey;
  }

  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;

  border-collapse: collapse;
`

const VendorInfoNameBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-right: 1px solid lightgrey;
`

const VendorInfoCheckBox = styled.div`
  min-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  label {
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0 5px;
    align-items: center;

    input {
    }

    span {
      display: block;
      width: max-content;
      color: black;
      font-size: 12px;
    }
  }
`
const UserCasinoVendorInfoUpdateBtn = styled(Button)`
  margin: 10px;
  border: none;
`
