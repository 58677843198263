import React from 'react'
import { IncDecTypeEnums, MoneyIncDecAuthorityEnums, MoneyIncDecTypeEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberInfo from '../../MemberManagement/PartnerList/MemberInfo/MemberInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyIncDecListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '행위자 ID',
      accessor: 'executeId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저ID(명)',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberInfo memberId={original.userId} />
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {MoneyIncDecAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '증감 종류',
      accessor: 'incDecType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return IncDecTypeEnums[original.incDecType]
      },
    },
    {
      Header: '자산 종류',
      accessor: 'assetType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MoneyIncDecTypeEnums[original.assetType]
      },
    },
    {
      Header: '변경전 머니',
      accessor: 'beforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '증차감액',
      accessor: 'changeAmountinc',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후 머니',
      accessor: 'afterAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyIncDecListData
