import { commonReg7 } from '../commonValidate'

export const UserAssetIncDecValidate = values => {
  const errors = {}

  // if (!values.moneyIncDecAmount || commonReg7.test(values.moneyIncDecAmount)) {
  //   errors.moneyIncDecAmount = '숫자만 입력 가능합니다.'
  // }

  if (values.assetType === '' || !values.assetType) {
    errors.assetType = '일반/홀덤을 선택하세요.'
  }

  return errors
}
