import { Button } from '@/shared/components/Button'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import Error from '@/shared/components/form/Error'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { ExchagneDateEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { fetchMemberInfo } from '../../../redux/memberInfoSlice'
import Loading from '../../../shared/components/Loading'
import { searchUserSettlementAuto } from '../../../utils/api/partnerManage/partnerAssetApi'
import { createPartnerPointExchange } from '../../../utils/api/partnerManage/partnerManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import StatisticsTabs from '../../Statistics/StatisticsTabs'

const PartnerPointExchangeForm = () => {
  const dispatch = useDispatch()
  const { hideSideBar, holdingMoney, holdingTotalGameMoney, holdingMemberRollingPoint, holdingMemberLosingPoint } =
    useSelector(state => {
      const { headerInfo, memberInfo } = state

      return {
        hideSideBar: headerInfo.hideSideBar,
        holdingMoney: memberInfo.memberHoldingMoney,
        holdingTotalGameMoney: memberInfo.memberTotalGameMoney,
        holdingMemberRollingPoint: memberInfo.memberHoldingRollingPoint,
        holdingMemberLosingPoint: memberInfo.memberHoldingLosingPoint,
      }
    })

  // 자산 조회
  const [writeLoading, setWriteLoading] = useState(false)
  const [userExchangeDate, setUserExchangeDate] = useState('')
  const [isTodayExchangeDate, setTodayExchangeDate] = useState(false)

  const fetchSearchUserSettlementAuto = async () => {
    searchUserSettlementAuto({}).then(res => {
      setUserExchangeDate(res.data.userExchangeDate)
      setTodayExchangeDate(res.data.isTodayExchangeDate)
    })
  }

  useEffect(() => {
    fetchSearchUserSettlementAuto()
  }, [])

  // 신청포인트
  const [applyPoint, setApplyPoint] = useState('0')

  const onClickPointHandler = name => {
    setApplyPoint(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyPoint('0')
  }

  const onLosingSubmit = () => {
    if (writeLoading) return
    if (!isTodayExchangeDate) {
      alert(`회원님의 루징정산일은 ${ExchagneDateEnums[userExchangeDate]}입니다.`)
      return
    }

    if (Number(applyPoint.toString().replaceAll(',', '')) > Number(holdingMemberLosingPoint)) {
      alert('보유 루징포인트를 다시 확인해주세요.')
      return
    }
    const body = {
      pointType: 'LOSING',
      applyPoint: applyPoint.toString().replaceAll(',', ''),
    }
    setWriteLoading(true)
    createPartnerPointExchange(body)
      .then(res => {
        setWriteLoading(false)
        alert('루징포인트 전환신청이 완료되었습니다.')
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('루징포인트 전환신청이 실패했습니다. 다시 시도해주세요.')
        }
        setWriteLoading(false)
        dispatch(fetchMemberInfo())
      })
  }

  const onRollingSubmit = () => {
    if (writeLoading) return
    if (Number(applyPoint.toString().replaceAll(',', '')) > Number(holdingMemberRollingPoint)) {
      alert('보유 롤링포인트를 다시 확인해주세요.')
      return
    }
    const body = {
      pointType: 'ROLLING',
      applyPoint: applyPoint.toString().replaceAll(',', ''),
    }
    setWriteLoading(true)
    createPartnerPointExchange(body)
      .then(res => {
        setWriteLoading(false)
        alert('포인트 전환신청이 완료되었습니다.')
        dispatch(fetchMemberInfo())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환신청이 실패했습니다. 다시 시도해주세요.')
        }
        setWriteLoading(false)
        alert('포인트 전환신청이 실패했습니다. 다시 시도해주세요.')
        dispatch(fetchMemberInfo())
      })
  }

  const onSubmit = () => {}
  // tab key
  const [activeKey, setActiveKey] = useState('6')

  return (
    <Card>
      <CardBody>
        <StatisticsTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>* 롤링/루징 포인트 전환 시, 지갑머니로 전환됩니다.</p>
          </div>
        </SearchCardTitleWrap>
        {writeLoading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={{
              partnerId: decodeAccessToken()?.userId,
              holdingMoney: holdingMoney?.toString().replace(commonReg2, ','),
              holdingMemberRollingPoint: holdingMemberRollingPoint?.toString().replace(commonReg2, ','),
              holdingMemberLosingPoint: holdingMemberLosingPoint?.toString().replace(commonReg2, ','),
              applyPoint,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit} style={{ maxWidth: '900px' }}>
                <FormGroup>
                  <FormGroupLabel>아이디</FormGroupLabel>
                  <FormGroupField>
                    <Field name="partnerId">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input {...input} type="text" disabled />
                            {meta.error && <Error error={meta.error} top />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>현재 지갑머니</FormGroupLabel>
                  <FormGroupField>
                    <Field name="holdingMoney">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input {...input} type="text" value={input.value} disabled />
                            {meta.error && <Error error={meta.error} top />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>현재 롤링포인트</FormGroupLabel>
                  <FormGroupField>
                    <Field name="holdingMemberRollingPoint">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input {...input} type="text" value={input.value} disabled />
                            {meta.error && <Error error={meta.error} top />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>현재 루징포인트</FormGroupLabel>
                  <FormGroupField>
                    <Field name="holdingMemberLosingPoint">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input {...input} type="text" value={input.value} disabled />
                            <p>
                              * {decodeAccessToken()?.userId}의 루징포인트 정산은 &nbsp;
                              <span style={{ color: 'red', fontWeight: '600' }}>
                                {ExchagneDateEnums[userExchangeDate]}
                              </span>{' '}
                              가능합니다.
                            </p>
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>전환 포인트</FormGroupLabel>
                  <FormGroupField>
                    <Field name="applyPoint">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={input.value}
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setApplyPoint(onlyNumber)
                              }}
                            />
                            {meta.error && meta.touched && <Error error={meta.error} />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupField>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="50000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      5만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="100000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      10만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="500000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      50만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="1000000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      100만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="5000000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      500만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      name="10000000"
                      onClick={e => {
                        onClickPointHandler(e.target.name)
                      }}
                    >
                      1000만원
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        onClearClickHandler()
                      }}
                    >
                      정정
                    </Button>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupField>
                    <Button style={{ background: 'green' }} variant="primary" onClick={onLosingSubmit}>
                      루징금 전환하기
                    </Button>
                    <Button style={{ background: 'blue' }} variant="primary" onClick={onRollingSubmit}>
                      롤링금 전환하기
                    </Button>
                  </FormGroupField>
                </FormGroup>
              </FormContainer>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  )
}

export default PartnerPointExchangeForm
