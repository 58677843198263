import React from 'react'
import PropTypes from 'prop-types'
import { isMobile, MobileView, BrowserView } from 'react-device-detect'
import styled from 'styled-components'

const MainWrapper = ({ children }) => (
  <CustomDiv>
    {children}
    {/* <BrowserView>{children}</BrowserView>
    <MobileView>
      <MobileViewWrap>
        <MobileViewInfoWrap>
          <MobileViewInfo>
            현재 모바일 화면은 지원하지 않습니다. <br />
            불편을 드려서 죄송합니다. <br />
            데스크탑을 이용해주세요.
          </MobileViewInfo>
        </MobileViewInfoWrap>
      </MobileViewWrap>
    </MobileView> */}
  </CustomDiv>
)

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainWrapper

const CustomDiv = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1260px;
`

const MobileViewWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: lightgray;
`

const MobileViewInfoWrap = styled.div`
  width: 100%;
  max-width: 400px;

  height: 200px;
  max-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MobileViewInfo = styled.p`
  font-size: 23px;
  line-height: 50px;
  font-weight: 400;

  color: black;

  text-align: center;
`
