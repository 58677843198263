import { Card, CardBody, SearchCardSubhead, SearchCardTitleWrap } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'
import {
  OneToOneAnswerStatusEnums,
  OneToOneAnswerStatusSelectOptions,
  OneToOneAuthoritySelectOptions,
} from '../../../../../enums/Operation/OneToOneEnums'
import { useSearchOnetoOneList, deleteOnetoOneList } from '../../../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../../../utils/dateTime'

import Loading from '../../../../../shared/components/Loading'
import OnetoOneListData from '../../../../Operation/OnetoOne/OnetoOneListData'
import OnetoOneInfoModal from '../../../../Operation/OnetoOne/OnetoOneInfoModal'

const MemberHistoryOneToOne = ({ memberId }) => {
  // 문의 내용 자세히 모달
  const [isOnetoOneInfoModalShow, setOnetoOneInfoModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  /**
   1:1문의 조회
   */
  const [oneToOneparams, setOneToOneParams] = useState({
    page,
    size,
    userId: memberId,
    authority: null,
    answerStatus: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchOnetoOneList } = useSearchOnetoOneList(oneToOneparams)
  const [checkRadio, setCheckRadio] = useState([])
  const [onetoOneInfo, setOnetoOneInfo] = useState({})

  const onTitleClickRowHandler = () => {
    window.scrollTo(10, 0)
    setOnetoOneInfoModalShow(true)
  }

  const [onetoOneListData, setOnetoOneListData] = useState(
    OnetoOneListData(content, fetchSearchOnetoOneList, checkRadio, setCheckRadio, onTitleClickRowHandler),
  )
  useEffect(() => {
    setOnetoOneListData(
      OnetoOneListData(content, fetchSearchOnetoOneList, checkRadio, setCheckRadio, onTitleClickRowHandler),
    )
  }, [content, checkRadio])

  const [rows, setData] = useState(onetoOneListData.tableRowsData)
  useEffect(() => {
    setData(onetoOneListData.tableRowsData)
  }, [onetoOneListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setOnetoOneInfo(rowValue.original)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 답변
  const [searchAnswerStatus, setSearchAnswerStatus] = useState('')

  const [searchAnswerStatusOptionValue, setSearchAnswerStatusOptionValue] = useState(null)
  const searchAnswerStatusOption = OneToOneAnswerStatusSelectOptions()

  const onSelectAnswerStatusOptionHandler = option => {
    if (option === null) {
      setSearchAnswerStatusOptionValue(null)
      setSearchAnswerStatus(null)
      return
    }
    setSearchAnswerStatusOptionValue(option)
    setSearchAnswerStatus(option.value)
  }

  // 문의일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setOneToOneParams(prev => ({
      ...prev,
      page: 0,
      userId: memberId,
      answerStatus: !searchAnswerStatus ? null : searchAnswerStatus,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
    }
  }
  return (
    <>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>
            * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 문의내용을 자세히 볼 수 있습니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>답변 상태</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchAnswerStatus"
              placeholder="답변 상태"
              onChange={option => {
                onSelectAnswerStatusOptionHandler(option)
              }}
              value={searchAnswerStatusOptionValue}
              options={searchAnswerStatusOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        {deleteLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ backgroundColor: 'lightGreen' }}
              variant="outline-secondary"
              onClick={() => {
                setCheckRadio(content.map(value => value.oneToOneKey))
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              style={{ backgroundColor: 'red' }}
              variant="outline-secondary"
              onClick={() => {
                onDeleteHandler()
              }}
            >
              삭제하기
            </Button>
          </SearchButtonWrap>
        )}
      </SearchContainer>
      <ReactTableBase columns={onetoOneListData.tableHeaderData} data={rows} tableConfig={tableConfig} />

      {/* 문의 내용 자세히 보기 모달 */}
      {isOnetoOneInfoModalShow && (
        <OnetoOneInfoModal
          onetoOneInfo={{
            authority: onetoOneInfo?.authority,
            oneToOneKey: onetoOneInfo?.oneToOneKey,
            userId: onetoOneInfo?.userId,
            questionTitle: onetoOneInfo?.questionTitle,
            registrationDate: onetoOneInfo?.registrationDate,
            questionContent: onetoOneInfo?.questionContent,
            answerContent: onetoOneInfo?.answerContent,
            answerStatus: onetoOneInfo?.answerStatus,
            answerStatusType: OneToOneAnswerStatusEnums[onetoOneInfo?.answerStatus] === '완료',
          }}
          fetchSearchOnetoOneList={fetchSearchOnetoOneList}
          isOnetoOneInfoModalShow={isOnetoOneInfoModalShow}
          setOnetoOneInfoModalShow={setOnetoOneInfoModalShow}
        />
      )}
    </>
  )
}

export default MemberHistoryOneToOne

MemberHistoryOneToOne.propTypes = {
  memberId: PropTypes.string,
}

MemberHistoryOneToOne.defaultProps = {
  memberId: null,
}
