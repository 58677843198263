export const HoldemResultStatusEnums = {
  WIN: '당첨',
  LOSE: '낙첨',
}

export const HoldemResultStatusEnumsSelectOptions = () => {
  return Object.entries(HoldemResultStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const getRevolutionGroupType = groupType => {
  switch (groupType) {
    case 'CASH':
      return '캐시 게임'
    case 'TOURNAMENT':
      return '토너먼트'
    case 'FREE TOURNAMENT':
      return '토너먼트'
    default:
      return '-'
  }
}
