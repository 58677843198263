import React from 'react'
import { isMobile } from 'react-device-detect'
import BeatLoader from 'react-spinners/BeatLoader'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const CustomAlert = ({ onClickCloseHandler }) => {
  return (
    <AlertOverlay isMobile={isMobile}>
      <LoadingIconWrap isMobile={isMobile}>
        <LoadingHeader>
          <LoadingHeaderLogo src={`${process.env.PUBLIC_URL}/img/popup/popup-logo.png`} alt="" />
          <CloseBtn onClick={() => onClickCloseHandler()}>
            <img src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
          </CloseBtn>
        </LoadingHeader>
        <LoadingInfoWrap>
          <LoadingInfo>쪽지함을 먼저 확인부탁드립니다.</LoadingInfo>
          <CheckButton onClick={() => onClickCloseHandler()}>확인</CheckButton>
        </LoadingInfoWrap>
      </LoadingIconWrap>
    </AlertOverlay>
  )
}

export default CustomAlert

CustomAlert.propTypes = {
  onClickCloseHandler: PropTypes.func.isRequired,
}

const CheckButton = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: rgba(123, 128, 140, 0.7);
  }
  width: 100px;
  height: 30px;
  color: #fff;
  background: rgb(123, 128, 140);

  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AlertOverlay = styled.div`
  position: ${props => (props.isMobile ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
`

const LoadingIconWrap = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
  height: 200px;

  background: #000000;

  display: flex;
  flex-direction: column;
  border: 2px solid rgb(123, 128, 140);
  z-index: 900;

  ${props =>
    props.isMobile &&
    `
    position: absolute;
    top: 35vh;
    width: 100%;
    width: calc(100% - 10px);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 300;
`}
`
const LoadingIcon = styled(BeatLoader)`
  position: relative;
  top: 40px;
  margin: 0 auto;
`

const LoadingInfoWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const LoadingInfo = styled.p`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  width: 100%;
  color: #fff;
  // background: linear-gradient(to right, #fcf4cb, #fddb2a);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`

const LoadingHeader = styled.div`
  height: 60px;
  border-bottom: 2px solid rgb(123, 128, 140);

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const LoadingHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

export const CloseBtn = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: rgb(123, 128, 140);
  }
  position: absolute;
  right: 2%;
  width: 40px;
  height: 40px;
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
`
