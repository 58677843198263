import moment from 'moment-timezone'

/**
 * @param {date} Date
 * @returns 한국 시간으로 변경된 날짜
 */
export const convertToKst = (date, format = null) => {
  return moment.tz(date, 'Asia/Seoul').format(format)
}

export const convertToKstDate = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD')
}

export const convertToKstByFormat = (date, format = 'YYYY-MM-DD HH:mm') => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format(format)
}

export const convertToKstMiniGameDateTime = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('MM월DD일  HH:mm:ss')
}

export const convertToKstDateTime = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD  HH:mm:ss')
}

export const convertToKstDateTimeSimple = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('YY-MM-DD HH:mm')
}

export const convertToKstDateTimePicker = date => {
  if (!date) {
    return date
  }
  return moment.tz(date, 'Asia/Seoul').format('YYYY-MM-DD  HH:mm:ss')
}

/**
 * 스포츠 경기 시간
 */
export const convertToKstSportsEventDateTime = date => {
  if (!date) {
    return '-'
  }
  return moment.tz(date, 'Asia/Seoul').format('MM월DD일  HH:mm')
}
