import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import {
  BettingResultStatusEnumsSelectOptions,
  sportResultStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import { searchSportsBettingLog, searchSportsBettingLogV2 } from '../../../utils/api/logManage/logApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import BettingManagementTabs from '../BettingManagementTabs'
import SportsDetailBettingContent from './SportsDetailBettingContent'
import MemberInfo from '../../MemberManagement/PartnerList/MemberInfo/MemberInfo'
import { UserAuthority } from '../../../enums/authority'
import SportDetailBettingLog from './SportDetailBettingLog'
import { SportMarketType } from '../../../enums/Sport/SportMarketEnums'

const SportBettingLogList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    userId: null,
    sportsResultStatus: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])
  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [sportsBettingLogList, setSportsBettingLogList] = useState([])
  const fetchSearchSportsMarket = async () => {
    await searchSportsBettingLogV2(bettingLogparams)
      .then(res => {
        const newSportsBettingLogList = res.data.sportsBettingLogList.map(item => ({
          ...item,
          isVisible: false,
        }))
        setSportsBettingLogList(newSportsBettingLogList)
        setTotalElement(res.data.totalElements)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchSportsMarket()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      sportsResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  // 특정 배팅로그 펼치기
  const onClickSportsBettingLogHandler = sportsBettingLogKey => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.sportsBettingLogKey === sportsBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  // 전체 배팅로그 펼치기 / 접기
  const onClickAllSportsBettingLogCollapseHandler = collapse => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        return {
          ...item,
          isVisible: collapse,
        }
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('2')
  return (
    <Card>
      <CardBody>
        <BettingManagementTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지에서는 <span style={{ color: 'red' }}>본인, 직영 및 하부회원, 하부트너의</span> 배팅 내역만
              확인 할 수 있습니다.
            </p>
            <p>* 행을 클릭하면, 해당 유저의 자세한 배팅내역을 볼 수 있습니다.</p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SportsGameListTableWrap>
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>상위파트너 (이름)</th>
                <th>유저 (이름)</th>
                <th>배팅전 머니</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>배팅후 머니</th>
                <th>타입</th>
                <th>폴더</th>
                <th>배당</th>
                <th>예상/최종 당첨금</th>
                <th>결과</th>
              </tr>
            </thead>
            <tbody>
              {sportsBettingLogList.length !== 0 &&
                sportsBettingLogList.map(sportsBettingLog => {
                  return (
                    <>
                      <tr
                        key={sportsBettingLog.sportsBettingLogKey}
                        onClick={e => {
                          onClickSportsBettingLogHandler(sportsBettingLog.sportsBettingLogKey)
                        }}
                      >
                        <td>{sportsBettingLog.num}</td>
                        <td>{sportsBettingLog.edgeName}</td>
                        <td>{convertToKstSportsEventDateTime(sportsBettingLog.bettingAt)}</td>
                        <td>
                          {sportsBettingLog.topUserId ? (
                            <>
                              {sportsBettingLog.topUserId} ({sportsBettingLog.topUserName})
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${sportsBettingLog.userId}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {sportsBettingLog.userId} ({sportsBettingLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={sportsBettingLog.userAuthority}>
                              {UserAuthority[sportsBettingLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          {/* <span>
                          <span>
                            <MemberDepositInfo memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberNote memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={sportsBettingLog.userId} />
                          </span>
                        </span> */}
                        </td>
                        <td>{sportsBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {sportsBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{sportsBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                        <CustomTd sportMarketType={sportsBettingLog.sportMarketType}>
                          {SportMarketType[sportsBettingLog.sportMarketType]}
                        </CustomTd>
                        <td>{sportsBettingLog.folderSize} 폴더</td>
                        <td>{sportsBettingLog.totalOddValue}</td>

                        <td>
                          <span style={{ color: 'blue' }}>
                            {sportsBettingLog.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                          </span>
                          <span style={{ margin: '0 5px' }}>/</span>
                          <span style={{ color: 'red' }}>
                            {sportsBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                          </span>
                        </td>
                        <td>
                          <CustomButton type="button" bettingResult={sportsBettingLog.sportsResultStatus}>
                            {sportResultStatusEnums[sportsBettingLog.sportsResultStatus]}
                          </CustomButton>
                        </td>
                      </tr>
                      {sportsBettingLog.isVisible && (
                        <SportDetailBettingLog
                          sportsDetailBettingLogList={sportsBettingLog.sportsDetailBettingLogList}
                          bonsuFolder={{
                            bonusFolderNum: sportsBettingLog.bonusFolderNum,
                            bonusFolderPer: sportsBettingLog.bonusFolderPer,
                          }}
                        />
                      )}
                    </>
                  )
                })}

              {sportsBettingLogList.length === 0 && (
                <tr>
                  <td colSpan={13}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '15px',
                        padding: '20px 0px',
                      }}
                    >
                      최근 게시물이 없습니다.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default SportBettingLogList

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    case bettingResult === 'CANCEL':
      return 'red'
    default:
      return 'black'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const SportsGameListTableHead = styled.thead`
  th {
    font-size: 12px;
  }
`

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
  font-size: 13px;
  border-left: solid 1px black;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  background-color: lightgray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderNumBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderResultBox = styled.div`
  width: 11%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentCriteria = styled.div`
  font-weight: 500;
  color: green;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 14%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGamePartnerBox = styled.div`
  width: 14%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  width: 9%;
  display: flex;
  // border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  width: 8%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  height: 46px;
`
