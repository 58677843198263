import { colorBackgroundBody } from '@/utils/palette'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import isLogin from '../../enums/authority'
import BettingLogList from '../BettingManagement/CasinoBettingLog/BettingLogList'
import RevolutionBettingLogDetailPage from '../BettingManagement/HoldemBettingLog/RevolutionBettingLogDetailPage'
import RevolutionBettingLogPage from '../BettingManagement/HoldemBettingLog/RevolutionBettingLogPage'
import RevolutionBuyInOutPage from '../BettingManagement/HoldemBettingLog/RevolutionBuyInOutPage'
import MiniGameBettingLogList from '../BettingManagement/MiniGameBettingLog/MiniGameBettingLogList'
import RealTimeBettingLogList from '../BettingManagement/RealTimeBettingLog/RealTimeBettingLogList'
import SportBettingLogList from '../BettingManagement/SportBettingLog/SportBettingLogList'
import WildGamesBettingLogDetailPage from '../BettingManagement/WildGamesBettingLog/WildGamesBettingLogDetailPage'
import WildGamesBettingLogPage from '../BettingManagement/WildGamesBettingLog/WildGamesBettingLogPage'
import DashBoardListPage from '../Dashboard/DashBoardListPage'
import Layout from '../Layout/index'
import MoneyIncDecList from '../Log/MoneyIncDec/MoneyIncDecList'
import MoneyDepoWithdrawList from '../Log/moneyDepoWithdrawLog/MoneyDepoWithdrawList'
import LogIn from '../LogIn/Login'
import MemberInfoPage from '../MemberManagement/MemberList/MemberInfo/MemberInfoPage'
import PartnerList from '../MemberManagement/PartnerList/PartnerList'
import UserRegistrationPage from '../MemberManagement/UserRegistraion/UserRegistrationPage'
import MyInfo from '../MyInfo/MyInfo'
import PartnerLosingPointDepositList from '../Statistics/LosingSettlementHistory/PartnerLosingPointDepositList'
import MemberPointExchangeList from '../Statistics/PointExchangeHistory/MemberPointExchangeList'
import MemberRollingPointDepositList from '../Statistics/RollingSettlementHistory/MemberRollingPointDepositList'
import SettlementStatistics from '../Statistics/SettlementStatistics/SettlementStatistics'
import MainWrapper from './MainWrapper'
import PartnerPointExchangeForm from '../PartnerManagement/PartnerOnlyMenu/PartnerPointExchangeForm'
import SettlementList from '../Statistics/SettlementStatisticsV2/SettlementList'
import MemberList from '../MemberManagement/MemberList/MemberList'
import VirtualGameBettingLogList from '../BettingManagement/VirtualGameBettingLog/VirtualGameBettingLogList'
import DepositPage from '../MoneyManagement/DepositPage'
import WithdrawalPage from '../MoneyManagement/WithdrawalPage'
import TokenGameBettingLogPage from '../BettingManagement/TokenGameBettingLog/TokenGameBettingLogPage'
import MoneyExchangeRateList from '../MemberHistory/MoneyExchangeRateHistory/MoneyExchangeRateList'

/**
 * react-router Docs
 * @see : https://v5.reactrouter.com/web/guides/quick-start
 */

/**
 * 로그인 하지 않은 사용자에게 공개된 URL 관리
 * @props : restricted - 로그인 사용자가 접근하지 말아야하는 URL 제한 : 로그인 페이지
 * @wee : 로그인 한 상태에서 로그인 URL 접근하면 회원 조회 페이지로 redirect
 */
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (isLogin() && restricted ? <Redirect to="/" /> : <Component {...props} />)} />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.bool.isRequired,
}

/**
 * 로그인한 사용자에게 공개된 URL 관리
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

const Router = () => {
  const { hideSideBar } = useSelector(state => {
    const { headerInfo } = state
    return {
      hideSideBar: headerInfo.hideSideBar,
    }
  })
  return (
    <MainWrapper>
      <main>
        <Switch>
          <PublicRoute restricted component={LogIn} path="/login" />
          <PrivateRoute component={RealTimeBettingLogList} path="/management/log/real-time-betting" exact />
          <PrivateRoute component={MemberInfoPage} path="/user-info/:memberId" exact />
          <div>
            <Layout />
            <ContainerWrap hideSideBar={hideSideBar}>
              {/* 메인 페이지 */}
              <PrivateRoute component={DashBoardListPage} path="/" exact />
              {/* MY 페이지 */}
              <PrivateRoute component={MyInfo} path="/myinfo" exact />

              {/* 파트너 롤링 포인트 전환신청 */}
              <PrivateRoute component={PartnerPointExchangeForm} path="/partner/management/pointexchange" exact />

              <PrivateRoute component={MemberList} path="/user/management/member" exact />

              {/* 대시보드 */}
              <PrivateRoute component={DashBoardListPage} path="/statistics" exact />

              {/* 파트너 조회 */}
              <PrivateRoute component={PartnerList} path="/user/management/partner" exact />
              {/* 유저 등록 */}
              <PrivateRoute component={UserRegistrationPage} path="/user/management/registration" exact />

              {/* 머니 이동내역 */}

              {/* 롤링 포인트 입금내역(회원+파트너용) */}
              <PrivateRoute component={MemberRollingPointDepositList} path="/user/history/pointdeposit" exact />
              {/* 루징 포인트 입금내역(파트너용) */}
              <PrivateRoute component={PartnerLosingPointDepositList} path="/user/history/losing-pointdeposit" exact />
              {/* 포인트 전환내역 */}
              <PrivateRoute component={MemberPointExchangeList} path="/user/history/pointexchange" exact />

              {/* ========================================== */}
              {/* =============== 통계 ===============  */}
              {/* 업체별 롤링 통계 */}
              <PrivateRoute component={SettlementStatistics} path="/statistics/rolling" exact />
              <PrivateRoute component={SettlementList} path="/statistics/rolling2" exact />
              {/* 입금 신청 */}
              <PrivateRoute component={DepositPage} path="/partner/moneydeposit" exact />
              {/* 출금 신청 */}
              <PrivateRoute component={WithdrawalPage} path="/partner/moneywithdrawal" exact />
              {/* 업체별 루징 통계
              <PrivateRoute component={PartnerLosingStatistics} path="/statistics/losing" exact /> */}

              {/* ========================================== */}
              {/* =============== 배팅내역 관리 ===============  */}
              {/* 실시간 배팅로그 */}
              {/* <PrivateRoute component={RealTimeBettingLogList} path="/management/log/real-time-betting" exact /> */}
              {/* 스포츠 배팅로그 */}
              <PrivateRoute component={SportBettingLogList} path="/management/log/sport-betting" exact />
              {/* 카지노 배팅로그 */}
              <PrivateRoute component={BettingLogList} path="/management/log/betting" exact />
              <PrivateRoute component={TokenGameBettingLogPage} path="/management/log/tokengame-betting" exact />

              {/* 미니게임 배팅로그 */}
              <PrivateRoute component={MiniGameBettingLogList} path="/management/log/minigame-betting" exact />
              <PrivateRoute component={VirtualGameBettingLogList} path="/management/log/virtualgame-betting" exact />
              <PrivateRoute
                component={RevolutionBettingLogPage}
                path="/management/log/holdem/revolution/betting-log"
                exact
              />
              <PrivateRoute
                component={RevolutionBettingLogDetailPage}
                path="/management/log/holdem/revolution/betting-detail-log"
                exact
              />
              <PrivateRoute
                component={RevolutionBuyInOutPage}
                path="/management/log/holdem/revolution/buyinout-log"
                exact
              />

              <PrivateRoute
                component={WildGamesBettingLogPage}
                path="/management/log/holdem/wild-games/betting-log"
                exact
              />
              <PrivateRoute
                component={WildGamesBettingLogDetailPage}
                path="/management/log/holdem/wild-games/betting-detail-log"
                exact
              />

              {/* 입출금로그 */}
              <PrivateRoute component={MoneyDepoWithdrawList} path="/management/log/moneydepowithdraw" exact />
              {/* 머니증차감로그 */}
              <PrivateRoute component={MoneyIncDecList} path="/management/log/moneyinc" exact />
              {/* 환수율 내역 */}
              <PrivateRoute component={MoneyExchangeRateList} path="/user/history/moneyexchange-rate" exact />
            </ContainerWrap>
          </div>
        </Switch>
      </main>
    </MainWrapper>
  )
}

export default Router

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 100px;
  margin-left: -40px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  padding-left: ${props => (props.hideSideBar ? `30px` : `290px`)};
  background: ${colorBackgroundBody};
`

// endregion
