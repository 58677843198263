import axios from '../base/axios'

// 홀덤 배팅내역 조회
export async function searchRevolutionHoldemBettingLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/holdem/revolution/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 디테일 배팅 내역 조회
export async function searchRevolutionHoldemDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/holdem/revolution/detail-betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 디테일 배팅 내역 조회
export async function searchRevolutionHoldemBuyInOutLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/holdem/revolution/buyinout-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
