import styled from 'styled-components'
import { Collapse } from 'react-bootstrap'
import { GiMoneyStack } from 'react-icons/gi'

/**
 * 새로운 코드
 */
// Header Wrap
export const CustomerCenterHeaderWrap = styled.div`
  // height: 60px;

  padding: 20px 10px 10px 20px;

  display: flex;
  // justify-content: center;
  align-items: center;

  // border: 1px solid red;
  border-top: 1px solid #edae07;
  border-bottom: 1px solid #edae07;
`

export const MobileMoneyHeaderSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  color: #000;
`

// Header Wrap
export const MobileCustomerCenterHeaderWrap = styled.div`
  padding: 15px 10px;
  width: 100% !important;
  display: flex;
  align-items: center;
  background: #1e1e21;
  border-radius: 6px;
`

export const MobileCompHistoryHeaderWrap = styled.div`
  height: 50px;
  padding: 3px 0 0 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  // border: 1px solid red;
  border-bottom: 1px solid #edae07;
`

export const MobileAttendanceHeaderWrap = styled.div`
  padding: 15px 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-bottom: 1px solid #525252;
`

export const MobileMoneyDepositHeaderWrap = styled.div`
  padding: 15px 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-bottom: 1px solid #525252;
`

export const MobileMoneyWithdrawalHeaderWrap = styled.div`
  padding: 15px 10px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-bottom: 1px solid #525252;
`

// header info
export const CustomerCenterHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;

  /* 엘로우폰트 */

  color: #ffc01a;
`

export const MobileCustomerCenterHeaderInfo = styled.span`
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 700 !important;
`

// content Wrap
export const CustomerCenterContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

// content Wrap
export const MobileCustomerCenterContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // margin-top: 02px
  justify-content: center;

  padding: 10px 0px;

  gap: 10px;
`

// sign up form
export const CustomerCenterFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`

// sign up form
export const MobileCustomerCenterFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 13px;
`

// sign up form group
export const CustomerCenterFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

export const MobileCustomerCenterFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

// sign up form label
export const CustomerCenterFormLabel = styled.div`
  width: 160px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

export const MobileCustomerCenterFormLabel = styled.div`
  width: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: center;
`

export const CustomCenterFormField = styled.div`
  width: 920px;
  display: flex;
`

export const MobileCustomCenterFormField = styled.div`
  width: 290px;
  display: flex;
`
export const CustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

// sign up form input wrap
export const CustomCenterFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  height: 35px;
`

// sign up form input wrap
export const CustomCenterFormBottomWrap = styled.div`
  width: 100%;
  // border: 1px solid #4a4a4a;
  position: relative;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
`

// sign up form input
export const CustomCenterFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;

  color: black;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// sign up form input
export const CustomCenterTextArea = styled.textarea`
  width: 100%;
  padding: 10px 10px;
  height: 300px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: black;
  background: #c2c3c4;
  border: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

/**
 * 아래 부터 예전 코드
 */
// export const MoneyButtonFormFieldButton = styled(FormFieldButton)`
//   width: 80px;
//   border-radius: 7%;
//   font-size: 12px;
//   font-weight: 900 !important;
//   background: ${props => (props.$active ? 'lightBlue' : 'lightYellow')};
//   display: inline-block;
//   margin: 0px 5px;
//   margin-bottom: 10px;
//   margin-top: -5px;
// `
// export const MoneyCustomFormInputWrap = styled(FormInputWrap)`
//   width: 90%;
// `

// 버튼 안에 텍스트
export const MoneyFormModifyButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: center;

  color: white !important;
`

export const CustomPopupMoneyHeaderWrap = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid rgb(36, 36, 36);
  border-bottom: 1px solid rgb(36, 36, 36);
  height: 60px;
  background: rgb(22, 23, 26);
  display: flex;
  gap: 20px;
  padding-left: 20px;
  align-items: center;
`

export const CustomPopupHeaderWrap = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid rgb(36, 36, 36);
  border-bottom: 1px solid rgb(36, 36, 36);
  height: 60px;
  background: rgb(22, 23, 26);
  display: flex;
  gap: 20px;
  padding-left: 20px;
  align-items: center;
`

export const CustomPopupHeaderDivWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  gap: 5px;
`

// export const CustomMobileTopWrap = styled.div`
// width: 100%;
// border-top: 1px solid rgb(36, 36, 36);
// border-bottom: 1px solid rgb(36, 36, 36);
// height: 60px;
// background: rgb(22, 23, 26);
// display: flex;
// `

// 버튼 안에 텍스트
export const MobileTopWrap = styled.div`
  background: #161719;
  width: 100%;
  min-height: calc(100vh - 180px);
  padding: 0px;
  padding-bottom: 0px;
`

export const MobileTableWrap = styled.div`
  width: 100%;
`

export const ListButton = styled.button`
  width: 120px;
  height: 40px;
  margin-left: 10px;
  border-radius: 0;
  background: grey;
  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

export const GoldQuestionButton = styled.button`
  width: 120px;
  height: 40px;
  margin-left: 10px;
  border-radius: 0;
  background: grey;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

export const MobileListButtonWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  padding-left: 5px;
  gap: 5px;
`

export const MobileListButton = styled.button`
  width: 60px;
  height: 30px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

export const MobileQuestionAccountButton = styled.button`
  width: 60px;
  height: 30px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

export const MoneyApplyButton = styled.button`
  width: 140px;
  height: 40px;
  margin-bottom: 5px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

export const MyInfoApplyButton = styled.button`
  width: 20%;
  height: 40px;
  font-size: 12px;
  color: #000;
  font-weight: bolder;

  border-radius: 5%;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  margin-left: 5px;
  margin-top: 5px;
  &:hover {
    color: black;
  }
`

export const CustomCenterApplyButton = styled.button`
  width: 10%;
  height: 40px;
  font-size: 12px;
  color: #000;
  font-weight: bolder;

  border-radius: 5%;
  display: inline-block;
  margin: 0px;
  padding: 0px;
  margin-left: 5px;
  margin-top: 5px;
  &:hover {
    color: black;
  }
`

export const CompApplyButton = styled.button`
  width: 170px;
  height: 50px;
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`
export const CompInfoContent = styled.span`
  width: max-content;
  margin-top: 8px;
  font-size: 12px;
  color: white;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 457px) {
    width: 10%;
  }
`

export const CompInfoWrap = styled.div`
  margin: 0;
  justify-content: center;
  display: flex;

  svg {
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }

  @media screen and (max-width: 520px) {
    svg {
      width: 30px;
      height: 30px;
      margin-top: 4px;
    }
  }

  // @media screen and (max-width: 468px) {
  //   svg {
  //     width: 25px;
  //     height: 25px;
  //   }
  // }
`

export const MoneyInfoWrap = styled.div`
  margin: 25px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 440px) {
    flex-wrap: wrap;
  }
`

export const MoneyInfoContent = styled.span`
  font-size: ${props => (props.isMobile ? '12px' : '12px')};
`

export const MoneyInfoGameMoney = styled(GiMoneyStack)`
  color: green !important;
`

export const MoneyInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;

  &:not(:last-child) {
    margin-right: 15px;
  }

  ${MoneyInfoGameMoney} {
    color: #000;
    width: 45px;
    height: 45px;
  }

  svg {
    width: 40px;
    height: 40px;
    color: #000;
    margin: 0 5px;
  }
`
