export const SocketIOEventName = {
  CREATE: 'CREATE',
  FIND: 'FIND',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

  DUPLICATE_LOGIN: 'DUPLICATE_LOGIN',
  LOST_SESSION: 'LOST_SESSION',
  REFRESH_SESSION: 'REFRESH_SESSION',
  UPDATED_SETTING: 'UPDATED_SETTING',

  REFRESH_SPORTS_DATA: 'REFRESH_SPORTS_DATA',
}
