import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../../shared/components/Card'
import { MemberResterBankSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { FormInputWrap } from '../../../../shared/components/form/FormElements'
import { searchMemberInfo } from '../../../../utils/api/memberManage/memberManageApi'
import { convertToKstDateTime } from '../../../../utils/dateTime'
import { commonReg2 } from '../../../../utils/validate/commonValidate'
import { memberInfoValidate } from '../../../../utils/validate/memberManage/memberInfoValidate'

const MemberInfoUpdate = ({ memberId }) => {
  const [infoLoading, setInfoLoading] = useState(false)

  const [memberInfo, setMemberInfo] = useState({})

  const fetchMemberInfo = async () => {
    setInfoLoading(true)
    await searchMemberInfo({
      memberId,
    })
      .then(res => {
        setMemberInfo(res.data.content)
        setInfoLoading(false)
      })
      .catch(error => {
        setInfoLoading(false)
      })
  }

  useEffect(() => {
    fetchMemberInfo()
  }, [memberId])

  const onSubmit = e => {}

  return (
    <>
      <MemberInfoWrap>
        <Form
          onSubmit={onSubmit}
          validate={memberInfoValidate}
          initialValues={{
            memberInfo,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <form onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <th>아이디</th>
                    <td>{memberInfo?.memberId}</td>
                    <th>이름</th>
                    <td>{memberInfo?.memberName}</td>
                    <th>닉네임</th>
                    <td>{memberInfo?.nickName}</td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      <Field name="phoneNum">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={memberInfo?.phoneNum}
                              readOnly
                              style={{ width: '100%' }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </td>
                    <th>가입일</th>
                    <td>{convertToKstDateTime(memberInfo?.registrationDate)}</td>
                    <th>최근 접속일</th>
                    <td>{convertToKstDateTime(memberInfo?.connectionDate)}</td>
                  </tr>
                  <tr>
                    <th>유저 권한</th>
                    <td>
                      {' '}
                      <CustomAuthorityButton authority={memberInfo.userAuthority}>
                        {NoteAuthorityEnums[memberInfo.userAuthority]}
                      </CustomAuthorityButton>
                    </td>
                    <th>보유 롤링 포인트</th>
                    <CustomTd moneyAmount={memberInfo?.rollingPointAmount}>
                      {memberInfo?.rollingPointAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                    <th>보유 루징 포인트</th>
                    <CustomTd moneyAmount={memberInfo?.losingPointAmount}>
                      {memberInfo?.losingPointAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </tr>
                  <tr>
                    <th>보유 머니</th>
                    <CustomTd moneyAmount={memberInfo?.moneyAmount}>
                      {memberInfo?.moneyAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                    <th>카지노 머니</th>
                    <CustomTd moneyAmount={memberInfo?.gameMoney}>
                      {memberInfo?.gameMoney?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                    <th>홀덤 머니</th>
                    <CustomTd moneyAmount={memberInfo?.holdemMoneyAmount}>
                      {memberInfo?.holdemMoneyAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                  </tr>
                  <tr>
                    <th style={{ color: 'orange' }}>총 배팅액</th>
                    <td>{memberInfo?.totalBettingAmount?.toString().replace(commonReg2, ',')}원</td>
                    <th style={{ color: 'orange' }}>총 당첨액</th>
                    <td>{memberInfo?.totalWinningAmount?.toString().replace(commonReg2, ',')}원</td>
                    <th style={{ color: 'orange', fontWeight: '600' }}>총 배팅액 - 총 당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.totalBettingProfitAmount}>
                      {memberInfo?.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                  </tr>
                  <DepositTr>
                    <th>총 입금액(일반/홀덤)</th>
                    <td>
                      {memberInfo?.totalDepositAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {memberInfo?.totalHoldemDepositAmount?.toString().replace(commonReg2, ',')}원
                    </td>
                    <th>총 출금액(일반/홀덤)</th>
                    <td>
                      {memberInfo?.totalWithdrawalAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {memberInfo?.withdrawalHoldemAmount?.toString().replace(commonReg2, ',')}원
                    </td>
                    <th style={{ fontWeight: '600' }}>총 입금액 - 총 출금액(일반/홀덤)</th>
                    <CustomTd moneyAmount={memberInfo?.totalMoneyProfitAmount}>
                      {memberInfo?.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')} /{' '}
                      {memberInfo?.totalHoldemMoneyProfitAmount?.toString().replace(commonReg2, ',')}원
                    </CustomTd>
                  </DepositTr>
                  <LiveGameTr>
                    <th>라이브 총 배팅액</th>
                    <td>{memberInfo?.liveGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>라이브 총 당첨액</th>
                    <td>{memberInfo?.liveGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>라이브 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.liveGameProfitAmount}>
                      {memberInfo?.liveGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </LiveGameTr>
                  <SlotGameTr>
                    <th>슬롯 총 배팅액</th>
                    <td>{memberInfo?.slotGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>슬롯 총 당첨액</th>
                    <td>{memberInfo?.slotGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>슬롯 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.slotGameProfitAmount}>
                      {memberInfo?.slotGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </SlotGameTr>
                  <SportsGameTr>
                    <th>스포츠 총 배팅액</th>
                    <td>{memberInfo?.sportsGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>스포츠 총 당첨액</th>
                    <td>{memberInfo?.sportsGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>스포츠 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.sportsGameProfitAmount}>
                      {memberInfo?.sportsGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </SportsGameTr>
                  <MiniGameTr>
                    <th>미니게임 총 배팅액</th>
                    <td>{memberInfo?.miniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>미니게임 총 당첨액</th>
                    <td>{memberInfo?.miniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>미니게임 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.miniGameProfitAmount}>
                      {memberInfo?.miniGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </MiniGameTr>
                  <VirtualGameTr>
                    <th>가상게임 총 배팅액</th>
                    <td>{memberInfo?.virtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>가상게임 총 당첨액</th>
                    <td>{memberInfo?.virtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>가상게임 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.virtualGameProfitAmount}>
                      {memberInfo?.virtualGameProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </VirtualGameTr>
                  <HoldemTr>
                    <th>홀덤 총 배팅액</th>
                    <td>{memberInfo?.holdemBettingAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>홀덤 총 당첨액</th>
                    <td>{memberInfo?.holdemWinningAmount?.toString().replace(commonReg2, ',')}</td>
                    <th>홀덤 배팅액-당첨액</th>
                    <CustomTd moneyAmount={memberInfo?.holdemProfitAmount}>
                      {memberInfo?.holdemProfitAmount?.toString().replace(commonReg2, ',')}
                    </CustomTd>
                  </HoldemTr>
                  <FriendTr>
                    <th>
                      피추천인 수<br /> <span style={{ fontSize: '10px' }}>(추천코드를 통해 들어온 회원)</span>
                    </th>
                    <td>{memberInfo?.recommendedPersonCount?.toString().replace(commonReg2, ',')}명</td>
                    <th style={{ color: '#66fae1' }}>추천인</th>
                    <td>{memberInfo?.friendId ? `${memberInfo?.friendId} [ ${memberInfo?.friendName} ]` : '-'}</td>
                    <th>-</th>
                    <td>-</td>
                  </FriendTr>
                </tbody>
              </table>
            </form>
          )}
        </Form>
      </MemberInfoWrap>
      {infoLoading && <Loading />}
    </>
  )
}

export default MemberInfoUpdate

MemberInfoUpdate.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const checkNumber = checkMoney => {
  return Math.sign(checkMoney)
}

const CustomTd = styled.td`
  color: ${props => (checkNumber(props.moneyAmount) > 0 ? 'red' : 'blue')};
  font-weight: 600;
`

const SlotGameTr = styled.tr`
  th {
    color: #59a6f7 !important;
  }
`

const HoldemTr = styled.tr`
  th {
    color: #a966fa !important;
  }
`

const DepositTr = styled.tr`
  th {
    color: #f26b83 !important;
  }
`
const LiveGameTr = styled.tr`
  th {
    color: #ec54f7 !important;
  }
`

const SportsGameTr = styled.tr`
  th {
    color: #35f01d !important;
  }
`
const VirtualGameTr = styled.tr`
  th {
    color: #ff600a !important;
  }
`

const MiniGameTr = styled.tr`
  th {
    color: yellow !important;
  }
`

const FriendTr = styled.tr`
  th {
    color: #66fae1 !important;
  }
`

const CustomFormInputWrap = styled(FormInputWrap)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomSelect = styled(MemberResterBankSelect)`
  width: 90%;
  border: solid 1px black;
  font-size: 14px;
`

const MemberInfoButtonWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  button {
    width: 100px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    outline: none;
  }
`

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 400px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
