import { Button } from '@/shared/components/Button'
import { CustomDatePickerWrap } from '@/shared/components/CustomDatePicker'
import { MemberCouponSelect } from '@/shared/components/CustomSelect'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Form, Field } from 'react-final-form'
import { searchCouponSelectList, publishMemberCoupon } from '@/utils/api/operation/operationApi'
import { convertToKst } from '@/utils/dateTime'
import Loading from '../../../../shared/components/Loading'
import { decodeAccessToken } from '../../../../utils/token'
import { memberCouponValidate } from '../../../../utils/validate/memberManage/couponValidate'

function MemberCouponForm({ memberId, onClickHandler }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [couponLoading, setCouponLoading] = useState(false)
  const [couponMoney, setCouponMoney] = useState('')
  const [couponTitle, setCouponTitle] = useState('')
  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  const [couponExpireDate, setCouponExpireDate] = useState(null)
  const onCouponExpireDateChange = date => {
    setCouponExpireDate(date)
  }

  const onSubmit = e => {
    if (couponLoading) return
    if (Number(holdingMoney) < Number(couponMoney)) {
      alert('내 보유머니를 다시 확인해주세요.')
      return
    }
    if (couponMoney <= 0) {
      alert('쿠폰머니를 다시 확인해주세요.')
      return
    }
    const body = {
      userId: memberId,
      couponMoney,
      couponExpireDate: null,
    }
    setCouponLoading(true)
    publishMemberCoupon(body)
      .then(res => {
        setCouponLoading(false)
        onClickHandler()
        alert('쿠폰이 발행되었습니다.')
        window.location.reload()
      })
      .catch(error => {
        setCouponLoading(false)
        alert('쿠폰 발행에 실패했습니다.')
        window.location.reload()
      })
  }

  return (
    <>
      {couponLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginTop: '-15px', marginBottom: '10px' }}>
            <p>
              * 쿠폰 지급 시 <span style={{ color: 'red' }}>내 보유머니</span>에서 차감이 됩니다.
            </p>
          </div>
          <Form onSubmit={onSubmit} validate={memberCouponValidate} initialValues={{ couponMoney }}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>내 보유머니</FormGroupLabel>
                  <FormGroupField>
                    <input name="memberId" type="text" value={holdingMoney} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>지급 아이디</FormGroupLabel>
                  <FormGroupField>
                    <input name="memberId" type="text" value={memberId} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>금액(원)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="couponMoney">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={couponMoney}
                            onChange={e => {
                              input.onChange(e)
                              setCouponMoney(e.target.value)
                            }}
                            placeholder="숫자만 입력하세요"
                          />
                          {meta.error && meta.touched && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '0px' }}>
                  <Button variant="primary" type="submit">
                    발행하기
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default MemberCouponForm

MemberCouponForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}
