import Tooltip from '@/shared/components/Tooltip'
import React, { useEffect, useState } from 'react'
import { FaEnvelope, FaUser } from 'react-icons/fa'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoVolumeHighSharp, IoVolumeMute } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Portal from './Portal'
import isLogin from '../../../enums/authority'
import { fetchHeaderInfo, setHideSideBar } from '../../../redux/headerInfoSlice'
import { fetchMemberInfo, fetchMemberInfoAction, reAuthenticationAction } from '../../../redux/memberInfoSlice'
import { deleteOnetoOneRedis } from '../../../utils/api/operation/operationApi'
import useAudio from '../../../utils/audioHook'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import Badge from './Badge'
import {
  MobileSecondTopbarContainer,
  MobileTopbarTextInfo,
  SecondTopbarContainer,
  TopbarCenter,
  TopbarContainer,
  TopbarTextInfo,
  TopbarTextWrap,
} from './BasicTopbarComponents'
import TopbarProfile from './PartnerTopbarProfile'
import CustomAlert from './CustomAlert'
import { decodeAccessToken } from '../../../utils/token'
import NoteList from '../../Operation/Note/NoteList'
import OnetoOneList from '../../Operation/OnetoOne/OnetoOneList'

function PartnerTopbar() {
  const loginCheck = isLogin()
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    // 경로가 '/statistics/rolling2'인 경우에만 실행
    if (
      location.pathname === '/statistics/rolling2' ||
      location.pathname === '/statistics/rolling' ||
      location.pathname === '/user/management/partner'
    ) {
      // setHideSideBar 액션을 dispatch하여 hideSideBar 상태를 true로 설정
      dispatch(setHideSideBar(true))
    } else {
      dispatch(setHideSideBar(false))
    }
  }, [dispatch, location.pathname]) // useE

  const [alarmSrc, setAlarmSrc] = useState(null)

  // HIA = Header Info Arudio
  const [isMuteAudio, setIsMuteAudio] = useState(JSON.parse(sessionStorage.getItem('HIA')) ?? false)

  const [play, pause, paused, audioRef] = useAudio(alarmSrc, {
    // playbackRate: 2,
  })

  const history = useHistory()
  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const onClickBtnHandler = path => {
    pause()

    if (path.includes('/management/operation/onetoone')) {
      deleteOnetoOneRedis()
    }

    history.push(path)
  }

  const {
    holdingMoney,
    holdingTotalGameMoney,
    holdingMemberRollingPoint,
    holdingMemberLosingPoint,
    hideSideBar,
    newMember,
    totalMember,
    newPartner,
    totalPartner,
    todayMemberDeposit,
    todayMemberWithdrawal,
    todayPartnerDeposit,
    todayPartnerWithdrawal,
    todayMemberDepositCount,
    todayMemberWithdrawalCount,
    todayPartnerDepositCount,
    todayPartnerWithdrawalCount,
    noteRedisSize,
  } = useSelector(state => {
    const { headerInfo, memberInfo } = state

    return {
      hideSideBar: headerInfo.hideSideBar,
      newMember: headerInfo.newMember,
      totalMember: headerInfo.totalMember,
      newPartner: headerInfo.newPartner,
      totalPartner: headerInfo.totalPartner,
      todayMemberDeposit: headerInfo.todayMemberDeposit,
      todayMemberWithdrawal: headerInfo.todayMemberWithdrawal,
      todayPartnerDeposit: headerInfo.todayPartnerDeposit,
      todayPartnerWithdrawal: headerInfo.todayPartnerWithdrawal,
      holdingMoney: memberInfo.memberHoldingMoney,
      noteRedisSize: memberInfo.noteRedisSize,
      holdingTotalGameMoney: memberInfo.memberTotalGameMoney,
      holdingMemberRollingPoint: memberInfo.memberHoldingRollingPoint,
      holdingMemberLosingPoint: memberInfo.memberHoldingLosingPoint,
      todayMemberDepositCount: headerInfo.todayMemberDepositCount,
      todayMemberWithdrawalCount: headerInfo.todayMemberWithdrawalCount,
      todayPartnerDepositCount: headerInfo.todayPartnerDepositCount,
      todayPartnerWithdrawalCount: headerInfo.todayPartnerWithdrawalCount,
    }
  })

  const [openModalShow, setOpenModalShow] = useState(false)
  const [title, setTitle] = useState('')
  const onCloseOpenModal = () => {
    setOpenModalShow(false)
  }

  const [openModalShow2, setOpenModalShow2] = useState(false)
  const [title2, setTitle2] = useState('')
  const onCloseOpenModal2 = () => {
    setOpenModalShow2(false)
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (noteRedisSize > 0) {
  //       setNoteAlertShow(true)
  //     } else {
  //       setNoteAlertShow(false)
  //     }
  //   }, 1000)

  //   return () => clearInterval(interval)
  // }, [noteRedisSize, title])

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      setTitle('')
      onCloseOpenModal(e)
      onCloseOpenModal2(e)
    }
  }

  const close = e => {
    if (title === 'NOTE' || title === 'ONETOONE') {
      dispatch(fetchMemberInfo())
    }
    onCloseOpenModal(e)
    onCloseOpenModal2(e)
  }

  const onClickCloseHandler = () => {
    setNoteAlertShow(false)
    setOpenModalShow(true)
  }

  const changeButton = () => {
    dispatch(setHideSideBar(!hideSideBar))
  }

  const [flag, setFlag] = useState(false)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFlag(prev => !prev)
    }, 4000) // 5000ms = 5 seconds, adjust to suit your needs

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    if (!isLogin()) return

    // const pathName = location.pathname
    // const newWindow = pathName.includes('real-time-betting') || pathName.includes('user-info')
    dispatch(reAuthenticationAction({ newWindow: true }))

    const fetchHeaderPollingInfoData = async () => {
      try {
        dispatch(fetchHeaderInfo())
        dispatch(fetchMemberInfoAction())
      } catch (error) {
        console.log('error ', error)
      }
    }
    fetchHeaderPollingInfoData()

    const intervalId = setInterval(fetchHeaderPollingInfoData, 5000)

    return () => {
      clearInterval(intervalId) // 컴포넌트 언마운트 시 인터벌 정리
    }
  }, [dispatch, loginCheck])

  return (
    <>
      {isMobile ? (
        <>
          <TopbarContainer>
            {noteRedisSize > 0 && (
              <CustomAlert
                info={`안녕하세요. ${decodeAccessToken()?.nickName}님`}
                onClickCloseHandler={onClickCloseHandler}
              />
            )}
            <TopbarCenter>
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: '20px',
                  marginLeft: '20px',
                  color: 'black',
                  fontSize: '20px',
                  gap: '15px',
                  width: '250px',
                  cursor: 'pointer',
                  justifyContent: 'left',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span>메뉴</span>
                  <AiOutlineMenu style={{ color: 'black' }} size={30} onClick={changeButton} />
                </div>

                <button
                  type="button"
                  style={{
                    color: '#000',
                    fontWeight: '700',
                    width: 'max-content',
                    fontSize: '13px',
                  }}
                  onClick={e => {
                    setOpenModalShow2(true)
                  }}
                >
                  1:1문의
                </button>
                <CustomFaEnvelope
                  size={25}
                  style={{ color: 'grey' }}
                  onClick={e => {
                    setOpenModalShow(true)
                  }}
                />
                {!noteRedisSize ? '' : <CustomBadge bg="danger">N</CustomBadge>}
              </div>
              <TopbarProfile />
            </TopbarCenter>
          </TopbarContainer>
          {/* 2전째 topbar 추가됨에 따라 Sidebar-SidebarWrap  의 padding-top , Router-ContainerWrap 의 padding-top 수정 필요 */}
          <MobileSecondTopbarContainer>
            <MobileCustomDIv>
              <TopbarTextWrap>
                {/* color : 색상, 색상 코드도 가능 */}
                <Tooltip text="금일 회원 가입수" placement="bottom">
                  <MobileTopbarTextInfo color="#ff4545" onClick={() => onClickBtnHandler('/user/management/partner')}>
                    [ 금일 가입회원: {newMember.toString().replace(commonReg2, ',')} /{' '}
                    {totalMember?.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
              </TopbarTextWrap>
              <TopbarTextWrap>
                <Tooltip text="금일 회원 입금액" placement="bottom">
                  <MobileTopbarTextInfo
                    color="#ff4545"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 회원입금: {todayMemberDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberDeposit.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 회원 출금액" placement="bottom">
                  <MobileTopbarTextInfo
                    color="#ff4545"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 회원출금: {todayMemberWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberWithdrawal.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
              </TopbarTextWrap>
            </MobileCustomDIv>
            <MobileCustomDIv>
              <TopbarTextWrap>
                <Tooltip text="금일 파트너 가입수" placement="bottom">
                  <MobileTopbarTextInfo color="#36ff6f" onClick={() => onClickBtnHandler('/user/management/partner')}>
                    [ 금일 가입파트너: {newPartner.toString().replace(commonReg2, ',')} /{' '}
                    {totalPartner.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 파트너 입금액" placement="bottom">
                  <MobileTopbarTextInfo
                    color="#36ff6f"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 파트너입금: {todayPartnerDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerDeposit.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 파트너 출금액" placement="bottom">
                  <MobileTopbarTextInfo
                    color="#36ff6f"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 파트너출금: {todayPartnerWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerWithdrawal.toString().replace(commonReg2, ',')} ]
                  </MobileTopbarTextInfo>
                </Tooltip>
              </TopbarTextWrap>
            </MobileCustomDIv>
          </MobileSecondTopbarContainer>
        </>
      ) : (
        <>
          <TopbarContainer>
            {noteRedisSize > 0 && (
              <CustomAlert
                info={`안녕하세요. ${decodeAccessToken()?.nickName}님`}
                onClickCloseHandler={onClickCloseHandler}
              />
            )}
            <TopbarCenter>
              <div style={{ display: 'flex' }}>
                <button
                  type="button"
                  style={{ color: '#000', fontWeight: '700', marginRight: '20px' }}
                  onClick={e => {
                    setOpenModalShow2(true)
                  }}
                >
                  1:1문의
                </button>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFaEnvelope
                  size={25}
                  style={{ color: 'grey' }}
                  onClick={e => {
                    setOpenModalShow(true)
                  }}
                />
                {!noteRedisSize ? '' : <CustomBadge bg="danger">N</CustomBadge>}
                <TopbarProfile />
              </div>
            </TopbarCenter>
          </TopbarContainer>
          {/* 2전째 topbar 추가됨에 따라 Sidebar-SidebarWrap  의 padding-top , Router-ContainerWrap 의 padding-top 수정 필요 */}
          <SecondTopbarContainer>
            <div
              style={{
                display: 'flex',
                // position: 'absolute',
                left: '20px',
                marginLeft: '20px',
                color: 'white',
                fontSize: '20px',
                gap: '5px',
                width: '200px',
                cursor: 'pointer',
              }}
            >
              <span
                onClick={() => {
                  window.location.href = '/statistics'
                }}
                style={{ marginRight: '50px' }}
              >
                파트너
              </span>

              <AiOutlineMenu style={{ color: 'white' }} size={30} onClick={changeButton} />
            </div>
            <CustomDIv>
              <TopbarTextWrap>
                {/* color : 색상, 색상 코드도 가능 */}
                <Tooltip text="금일 회원 총 가입수" placement="bottom">
                  <TopbarTextInfo color="#ff4545" onClick={() => onClickBtnHandler('/user/management/partner')}>
                    [ 금일 가입회원: {newMember.toString().replace(commonReg2, ',')} /{' '}
                    {totalMember?.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>

                <Tooltip text="금일 회원 총 입금액" placement="bottom">
                  <TopbarTextInfo
                    color="#ff4545"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 회원입금: {todayMemberDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberDeposit.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 회원 총 출금액" placement="bottom">
                  <TopbarTextInfo
                    color="#ff4545"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 회원출금: {todayMemberWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberWithdrawal.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>
              </TopbarTextWrap>

              <TopbarTextWrap>
                <Tooltip text="금일 파트너 총 가입수" placement="bottom">
                  <TopbarTextInfo color="#36ff6f" onClick={() => onClickBtnHandler('/user/management/partner')}>
                    [ 금일 가입파트너: {newPartner.toString().replace(commonReg2, ',')} /{' '}
                    {totalPartner.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 파트너 총 입금액" placement="bottom">
                  <TopbarTextInfo
                    color="#36ff6f"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 파트너입금: {todayPartnerDepositCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerDeposit.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>
                <Tooltip text="금일 파트너 총 출금액" placement="bottom">
                  <TopbarTextInfo
                    color="#36ff6f"
                    onClick={() => onClickBtnHandler('/management/log/moneydepowithdraw')}
                  >
                    [ 금일 파트너출금: {todayPartnerWithdrawalCount.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerWithdrawal.toString().replace(commonReg2, ',')} ]
                  </TopbarTextInfo>
                </Tooltip>
              </TopbarTextWrap>
            </CustomDIv>
          </SecondTopbarContainer>
        </>
      )}
      {openModalShow && (
        <Portal elementId="game-modal">
          <SlotCasinoGameModalOverlay visible={openModalShow} />
          <SlotCasinoGameModalWrapper
            className="game-container"
            onClick={onMaskClick}
            tabIndex="-1"
            visible={openModalShow}
          >
            <SlotCasinoGameModalInner tabIndex="0" isMobile={isMobile}>
              <SlotCasinoGameModalHeader>
                <SlotCasinoHeaderInfo>쪽지함</SlotCasinoHeaderInfo>
                <SlotCasinoGameModalCloseButton
                  onClick={close}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SlotCasinoGameModalHeader>

              <SlotCasinoGameModalContentWrap>
                {/* <MoneyDepositForm /> */}
                <NoteList />
              </SlotCasinoGameModalContentWrap>
            </SlotCasinoGameModalInner>
          </SlotCasinoGameModalWrapper>
        </Portal>
      )}
      {openModalShow2 && (
        <Portal elementId="game-modal">
          <SlotCasinoGameModalOverlay visible={openModalShow2} />
          <SlotCasinoGameModalWrapper
            className="game-container"
            onClick={onMaskClick}
            tabIndex="-1"
            visible={openModalShow2}
          >
            <SlotCasinoGameModalInner tabIndex="0" isMobile={isMobile}>
              <SlotCasinoGameModalHeader>
                <SlotCasinoHeaderInfo>1:1문의</SlotCasinoHeaderInfo>
                <SlotCasinoGameModalCloseButton
                  onClick={close}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SlotCasinoGameModalHeader>

              <SlotCasinoGameModalContentWrap>
                {/* <MoneyDepositForm /> */}
                <OnetoOneList />
              </SlotCasinoGameModalContentWrap>
            </SlotCasinoGameModalInner>
          </SlotCasinoGameModalWrapper>
        </Portal>
      )}
    </>
  )
}

export default PartnerTopbar

const CustomFaEnvelope = styled(FaEnvelope)`
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`

const CustomDIv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MobileCustomDIv = styled.div`
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 10px;
`

const CustomIoVolumeHighSharp = styled(IoVolumeHighSharp)`
  width: 30px;
  height: 30px;
  color: red;
  cursor: pointer;
`
const CustomIoVolumeMute = styled(IoVolumeMute)`
  width: 30px;
  height: 30px;
  color: red;
  cursor: pointer;
`
const CustomBadge = styled(Badge)`
  cursor: pointer;
`

const SlotCasinoGameModalContentWrap = styled.div`
  padding: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 500px;
  // border-left: solid 1px #7b808c;
`

const SlotCasinoGameModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  // overflow: hidden;
  overflow: auto;
  outline: 0;
`
const SlotCasinoGameModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`

const SlotCasinoGameModalInner = styled.div`
  box-sizing: border-box;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;

  width: 600px;
  margin: 0 auto;
  margin-top: ${props => (props.isMobile ? '50px' : '10px')};
  margin-left: ${props => props.isMobile && '50px'};
  margin-bottom: 100px;
  border: 1px solid transparent; /* Start with a transparent border */
  border-image: linear-gradient(to right, #ffd700, #ffcc00, #fcf4cb, #fddb2a, #fddb2a) 1;
  padding-bottom: 20px;
  // border-left: none;
  // border-right: none;
`

const SlotCasinoGameModalHeader = styled.div`
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent; /* Start with a transparent border */
  border-image: linear-gradient(to right, #ffd700, #ffcc00, #fcf4cb, #fddb2a, #fddb2a) 1;
`

// 닫기 아이콘
const SlotCasinoGameModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`
const SlotCasinoHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  /* 엘로우폰트 */

  color: white;
`
