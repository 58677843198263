import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 한명 회원 머니로그 조회
 */
export async function searchOneMemberMoneyLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/one-money-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchOneMemberMoneyLog(params) {
  const [memberMoneyLogTotalElement, setMemberMoneyLogTotalElement] = useState(0)
  const [memberMoneyLogContent, setMemberMoneyLogContent] = useState([])

  async function fetchSearchOneMemberMoneyLog() {
    await searchOneMemberMoneyLog(params).then(response => {
      setMemberMoneyLogTotalElement(response.data.totalElement)
      setMemberMoneyLogContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchOneMemberMoneyLog()
  }, [params])

  return { memberMoneyLogTotalElement, memberMoneyLogContent, fetchSearchOneMemberMoneyLog }
}

/**
 * 회원 정보 변경 API
 */
export async function updateMemberZeroBetting(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/zero-betting',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 회원 공배팅 로그 조회
 */
export async function searchMemberZeroBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/zero-betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberZeroBettingLog(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberZeroBettingLog() {
    await searchMemberZeroBettingLog(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberZeroBettingLog()
  }, [params])

  return { totalElement, content, fetchSearchMemberZeroBettingLog }
}

/**
 * 전체 회원 머니로그 조회
 */
export async function searchMemberMoneyLog(params) {
  const response = await axios({
    url: '/tyson/partner/v1/log/management/money-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyLog(params) {
  const [memberMoneyLogTotalElement, setMemberMoneyLogTotalElement] = useState(0)
  const [memberMoneyLogContent, setMemberMoneyLogContent] = useState([])

  async function fetchSearchMemberMoneyLog() {
    await searchMemberMoneyLog(params).then(response => {
      setMemberMoneyLogTotalElement(response.data.totalElement)
      setMemberMoneyLogContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyLog()
  }, [params])

  return { memberMoneyLogTotalElement, memberMoneyLogContent, fetchSearchMemberMoneyLog }
}

/**
 * 회원 배팅 로그 조회
 */
export async function searchMemberBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberBettingLog(params) {
  const [memberBettingLogTotalElement, setMemberBettingLogTotalElement] = useState(0)
  const [memberBettingLogContent, setMemberBettingLogContent] = useState([])

  async function fetchSearchMemberBettingLog() {
    await searchMemberBettingLog(params).then(response => {
      setMemberBettingLogTotalElement(response.data.totalElement)
      setMemberBettingLogContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberBettingLog()
  }, [params])

  return { memberBettingLogTotalElement, memberBettingLogContent, fetchSearchMemberBettingLog }
}

// 게임사 조회
export async function searchVendorList() {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/vendor-list',
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}
