export const sportResultStatusEnums = {
  WIN: '적중',
  DRAW: '적특',
  LOSE: '미적중',
  ING: '진행중',
  CANCEL: '취소',
}

export const SportsMarketTypeEnums = {
  DOMESTIC: '국내형',
  OVERSEAS: '해외형',
  SPECIAL: '스페셜',
  LIVE: '라이브',
}

export const sportSettlementStatusEnums = {
  true: '완료',
  false: '미완료',
}

export const sportOnlyOneFolderEnums = {
  [true]: '허용',
  [false]: '제재',
}

export const SportsOnlyOneRadioOptions = () => {
  return Object.entries(sportOnlyOneFolderEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'sportsOnlyOneFolder',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'sportsOnlyOneFolder',
          label: value,
          radioValue: key,
        },
  )
}

export const BettingResultStatusEnumsSelectOptions = () => {
  return Object.entries(sportResultStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SettlemenResulttStatusEnumsSelectOptions = () => {
  return Object.entries(sportSettlementStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const settlementAutoStatusEnums = {
  AUTO: '자동',
  MANUAL: '수동',
}

export const SettlementStatusEnumsSelectOptions = () => {
  return Object.entries(settlementAutoStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SettlementStatusEnumsRadioOptions = () => {
  return Object.entries(settlementAutoStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'settlementStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'settlementStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const SportTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
  ETC: '기타',
}

export const SportSecondTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
}

export const SportTypeEnumsSelectOptions = () => {
  return Object.entries(SportTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSecondTypeEnumsSelectOptions = () => {
  return Object.entries(SportSecondTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MarketTypeEnums = {
  SOCCER_WIN_DRAW_LOSE: '	승무패	',
  SOCCER_DOUBLE_CHANCE: '	승무패(더블찬스)	',
  SOCCER_ALL_SCORE: '	양팀모두득점	',
  SOCCER_WIN_LOSE: '	승패	',
  SOCCER_EXACT_SCORE: '	정확한 스코어	',
  SOCCER_HALF_FULL: '	하프타임/풀타임 승무패	',
  SOCCER_WIN_DRAW_LOSE_ALL_SCORE: '	승무패/양팀모두득점	',
  SOCCER_HANDICAP_PLUS: '	핸디캡	',
  SOCCER_GOAL_UNDER_OVER_PLUS: '	언더/오버	',
  SOCCER_GOAL_ODD_EVEN: '	골 홀/짝	',
  SOCCER_FIRST_HALF_ALL_SCORE: '	전반전 양팀모두득점	',
  SOCCER_SECOND_HALF_ALL_SCORE: '	후반전 양팀모두득점	',
  SOCCER_SECOND_HALF_UNDER_OVER: '	후반전 언더오버	',
  SOCCER_WIN_DRAW_LOSE_UNDER_OVER: '	승무패/언더오버	',
  SOCCER_UNDER_OVER_ALL_SCORE: '	언더오버/양팀모두득점	',
  SOCCER_HOME_UNDER_OVER: '	홈/언더오버	',
  SOCCER_AWAY_UNDER_OVER: '	원정/언더오버	',
  SOCCER_HALF_WIN_DRAW_LOSE: '	전반전 승무패	',
  SOCCER_HALF_DOUBLE_CHANCE: '	전반전 더블찬스	',
  SOCCER_HALF_EXACT_SCORE: '	전반전 정확한스코어	',
  SOCCER_HALF_HANDICAP_PLUS: '	전반전 핸디캡	',
  SOCCER_HALF_UNDER_OVER_ASIAN_PLUS: '	전반전 언더오버	',
  SOCCER_EXACT_SCORE_ASIAN: '	정확한 골	',
  SOCCER_FIRST_HALF_EXACT_SCORE: '	전반전 정확한 골	',
  SOCCER_SECOND_HALF_EXACT_SCORE: '	후반전 정확한 골	',
  SOCCER_FIRST_HALF_GOAL_ODD_EVEN: '	전반전 골 홀짝	',
  SOCCER_SECOND_HALF_GOAL_ODD_EVEN: '	후반전 골 홀짝	',
  SOCCER_SECOND_WIN_DRAW_LOSE: '	후반전 승무패	',
  SOCCER_FIRST_HALF_WIN_DRAW_LOSE_UNDER_OVER: '	전반전 승무패/언더오버	',
  SOCCER_FIRST_HALF_WIN_DRAW_LOSE_ALL_SCORE: '	전반전 승무패/양팀모두득점	',
  SOCCER_FIRST_SCORE_TEAM: '	첫 득점 팀	',
  SOCCER_LAST_SCORE_TEAM: '	마지막 득점 팀	',
  SOCCER_SCORE_SUCCESS_TEAM: '	득점 성공팀	',
  SOCCER_HANDICAP_WIN_DRAW_LOSE_PLUS: '	핸디캡 승무패	',
  FOOTBALL_WIN_LOSE: '	승패	',
  FOOTBALL_WIN_LOSE_NOT_EXTENSION: '	연장포함 핸디캡	',
  FOOTBALL_UNDER_OVER_NOT_EXTENSION: '	연장포함 언더오버	',
  FOOTBALL_FIRST_HALF_WIN_LOSE: '	전반전 승패	',
  FOOTBALL_FIRST_HALF_HANDICAP: '	전반전 핸디캡	',
  FOOTBALL_FIRST_HALF_UNDER_OVER: '	전반전 언더오버	',
  FOOTBALL_FIRST_QUARTER_WIN_LOSE: '	1쿼터 승패	',
  FOOTBALL_FIRST_QUARTER_HANDICAP: '	1쿼터 핸디캡	',
  FOOTBALL_FIRST_QUARTER_UNDER_OVER: '	1쿼터 언더오버	',
  BOXING_UFC_WIN_LOSE: '	승패	',
  TENNIS_WIN_LOSE: '	승패	',
  TENNIS_HANDICAP: '	핸디캡	',
  TENNIS_EXACT_SET_SCORE: '	정확한 세트 결과	',
  TENNIS_FIRST_SET_WIN_LOSE: '	1세트 승패	',
  TENNIS_FIRST_SET_EXACT_SCORE: '	1세트 정확한 스코어	',
  TENNIS_FIRST_SET_UNDER_OVER: '	1세트 언더오버	',
  TENNIS_UNDER_OVER: '	언더오버	',
  BASEBALL_WIN_LOSE: '	승패	',
  BASEBALL_SCOROE_ODD_EVEN: '	점수 홀짝	',
  BASEBALL_HANDICAP_NOT_EXTENSION_PLUS: '	연장포함 핸디캡	',
  BASEBALL_UNDER_OVER_NOT_EXTENSION_PLUS: '	연장포함 언더오버	',
  BASEBALL_FIRST_INNING_WIN_DRAW_LOSE: '	1이닝 승무패	',
  BASEBALL_THIRD_INNING_HANDICAP: '	3이닝 합계 핸디캡	',
  BASEBALL_THIRD_INNING_UNDER_OVER: '	3이닝 합계 언더오버	',
  BASEBALL_FIFTH_INNING_HANDICAP: '	5이닝 합계 핸디캡	',
  BASEBALL_FIFTH_INNING_UNDER_OVER: '	5이닝 합계 언더오버	',
  BASEBALL_SEVENTH_INNING_HANDICAP: '	7이닝 합계 핸디캡	',
  BASEBALL_SEVENTH_INNING_UNDER_OVER: '	7이닝 합계 언더오버	',
  BASEBALL_FIRST_INNING_HOME_SCORE: '	1이닝 홈 득점	',
  BASEBALL_FIRST_INNING_AWAY_SCORE: '	1이닝 원정 득점	',
  BASEBALL_FIRST_INNING_SCORE: '	1이닝 득점	',
  BASEBALL_PITCHER_FOURBALL_UNDER_OVER: '	볼넷 언더오버	',
  ICE_HOCKEY_WIN_LOSE: '	승패	',
  ICE_HOCKEY_WIN_DRAW_LOSE: '	승무패	',
  ICE_HOCKEY_HANDICAP_3WAY: '	핸디캡 3-Way	',
  ICE_HOCKEY_UNDER_OVER_3WAY: '	언더오버 3-Way	',
  ICE_HOCKEY_DOUBLE_CHANCE: '	더블찬스	',
  ICE_HOCKEY_EXACT_SCORE: '	정확한스코어	',
  ICE_HOCKEY_SCORE_ODD_EVEN: '	홀짝	',
  ICE_HOCKEY_HANDICAP_PLUS: '	핸디캡	',
  ICE_HOCKEY_UNDER_OVER_PLUS: '	언더오버	',
  ICE_HOCKEY_FIRST_PERIOD_WIN_LOSE: '	1피리어드 승패	',
  ICE_HOCKEY_FIRST_PERIOD_HANDICAP: '	1피리어드 핸디캡	',
  ICE_HOCKEY_FIRST_PERIOD_UNDER_OVER: '	1피리어드 언더오버	',
  ICE_HOCKEY_FIRST_PERIOD_WIN_DRAW_LOSE: '	1피리어드 승무패	',
  ICE_HOCKEY_FIRST_PERIOD_DOUBLE_CHANCE: '	1피리어드 더블찬스	',
  BASKETBALL_WIN_LOSE: '	승패	',
  BASKETBALL_FIRST_HALF_WIN_LOSE: '	전반전 승패	',
  BASKETBALL_FIRST_HALF_HANDICAP: '	전반전 핸디캡	',
  BASKETBALL_FIRST_HALF_UNDER_OVER: '	전반전 언더오버	',
  BASKETBALL_FIRST_QUARTER_WIN_LOSE: '	1쿼터 승패	',
  BASKETBALL_FIRST_QUARTER_HANDICAP: '	1쿼터 핸디캡	',
  BASKETBALL_FIRST_QUARTER_UNDER_OVER: '	1쿼터 언더오버	',
  BASKETBALL_FIRST_QUARTER_WIN_DRAW_LOSE: '	1쿼터 승무패	',
  BASKETBALL_SECOND_QUARTER_WIN_DRAW_LOSE: '	2쿼터 승무패	',
  BASKETBALL_THIRD_QUARTER_WIN_DRAW_LOSE: '	3쿼터 승무패	',
  BASKETBALL_FOURTH_QUARTER_WIN_DRAW_LOSE: '	4쿼터 승무패	',
  BASKETBALL_SECOND_QUARTER_HANDICAP: '	2쿼터 핸디캡	',
  BASKETBALL_SECOND_QUARTER_UNDER_OVER: '	2쿼터 언더오버	',
  BASKETBALL_THIRD_QUARTER_HANDICAP: '	3쿼터 핸디캡	',
  BASKETBALL_THIRD_QUARTER_UNDER_OVER: '	3쿼터 언더오버	',
  BASKETBALL_FOURTH_QUARTER_HANDICAP: '	4쿼터 핸디캡	',
  BASKETBALL_FOURTH_QUARTER_UNDER_OVER: '	4쿼터 언더오버	',
  BASKETBALL_HANDICAP_NOT_EXTENSION_PLUS: '	연장포함 핸디캡	',
  BASKETBALL_UNDER_OVER_NOT_EXTENSION_PLUS: '	연장포함 언더오버	',
  BASKETBALL_SCORE_RANGE_3_WAY: '	총 점수 범위(3_WAY)	',
  BASKETBALL_SCORE_RANGE_8_WAY: '	총 점수 범위(8_WAY)	',
  BASKETBALL_SCORE_RANGE_PLAYER: '	선수 득점 언더오버	',
  HANDBALL_WIN_DRAW_LOSE: '	승무패	',
  HANDBALL_HANDICAP: '	핸디캡	',
  HANDBALL_UNDER_OVER: '	언더오버	',
  VOLLEYBALL_WIN_LOSE: '	승패	',
  VOLLEYBALL_HANDICAP: '	핸디캡	',
  VOLLEYBALL_UNDER_OVER: '	언더오버	',
  VOLLEYBALL_ODD_EVEN: '	홀짝	',
  VOLLEYBALL_EXACT_SET_SCORE: '	정확한 세트스코어	',
  VOLLEYBALL_HOME_UNDER_OVER: '	홈_언더오버	',
  VOLLEYBALL_AWAY_UNDER_OVER: '	원정_언더오버	',
  VOLLEYBALL_FIRST_SET_WIN_LOSE: '	1세트 승패	',
  VOLLEYBALL_FIRST_SET_HANDICAP: '	1세트 핸디캡	',
  VOLLEYBALL_FIRST_SET_UNDER_OVER: '	1세트 언더오버	',
  VOLLEYBALL_FIRST_SET_ODD_EVEN: '	1세트 홀짝	',
  VOLLEYBALL_FIRST_SET_DEUCE_EXIST: '	1세트 듀스 여부	',
  VOLLEYBALL_FIRST_SET_EXACT_SCORE: '	1세트 정확한스코어	',
  VOLLEYBALL_HANDICAP_ALL_SCORE: '	총 점수 핸디캡	',
  TABLEBALL_WIN_LOSE: '	승패	',
  ESPORTS_WIN_LOSE: '	승패	',
  ESPORTS_HANDICAP: '	핸디캡	',
  ESPORTS_UNDER_OVER: '	언더오버	',
  ESPORTS_FIRST_SET_FIRST_DRAGON: '	1세트 첫용	',
  ESPORTS_FIRST_SET_FIRST_BARON: '	1세트 첫바론	',
  ESPORTS_FIRST_SET_FIRST_TOWER: '	1세트 첫타워	',
  ESPORTS_FIRST_SET_FIRST_KILL: '	1세트 첫킬	',
  ESPORTS_FIRST_SET_KILL_HANDICAP: '	킬 1세트 핸디캡	',
  ESPORTS_FIRST_SET_KILL_UNDER_OVER: '	킬 1세트 언더오버	',
  ESPORTS_FIRST_SET_WIN_LOSE: '	1세트 승패	',
  ESPORTS_FIRST_SET_GAME_TIME: '	1세트 경기시간 언더오버	',
}

export const eventStatusEnums = {
  경기종료: '경기종료',
  경기취소: '경기취소',
  경기지연: '경기지연',
  경기전: '경기전',
  경기중: '경기중',
}

export const EventStatusEnumsSelectOptions = () => {
  return Object.entries(eventStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SoccerScoreEnums = {
  1: '전반 획득점수',
  2: '후반 획득점수',
  et: '연장 획득점수',
  pen: '승부차기 점수',
  f_g: '첫 득점(0,1)',
  l_g: '마지막 득점(0,1)',
}

export const FootballScoreEnums = {
  1: '1쿼터 획득점수',
  2: '2쿼터 획득점수',
  3: '3쿼터 획득점수',
  4: '4쿼터 획득점수',
  et: '연장 획득점수',
}

export const TennisScoreEnums = {
  '1_set': '1세트 획득점수',
  '2_set': '2세트 획득점수',
  '3_set': '3세트 획득점수',
  '4_set': '4세트 획득점수',
  '5_set': '5세트 획득점수',
}

export const BoxingScoreEnums = {
  score: '승패(1,0)',
}

export const BaseballScoreEnums = {
  1: '1이닝 획득점수',
  2: '2이닝 획득점수',
  3: '3이닝 획득점수',
  4: '4이닝 획득점수',
  5: '5이닝 획득점수',
  6: '6이닝 획득점수',
  7: '7이닝 획득점수',
  8: '8이닝 획득점수',
  9: '9이닝 획득점수',
  et: '연장 획득점수',
}

export const IceHockeyScoreEnums = {
  1: '1PR 획득점수',
  2: '2PR 획득점수',
  3: '3PR 획득점수',
  et: '연장 획득점수',
  f_g: '첫 득점(0,1)',
  l_g: '마지막 득점(0,1)',
}

export const BasektBallScoreEnums = {
  1: '1쿼터 획득점수',
  2: '2쿼터 획득점수',
  3: '3쿼터 획득점수',
  4: '4쿼터 획득점수',
  et: '연장 획득점수',
}

export const HandBallScoreEnums = {
  1: '전반 획득점수',
  2: '후반 획득점수',
  3: '연장 획득점수',
}

export const VolleyBallScoreEnums = {
  1: '1세트 획득점수',
  2: '2세트 획득점수',
  3: '3세트 획득점수',
  4: '4세트 획득점수',
  5: '5세트 획득점수',
}

export const TableTennisScoreEnums = {
  '1_set': '1세트 획득점수',
  '2_set': '2세트 획득점수',
  '3_set': '3세트 획득점수',
  '4_set': '4세트 획득점수',
  '5_set': '5세트 획득점수',
  '6_set': '6세트 획득점수',
  '7_set': '7세트 획득점수',
}

export const ESportsScoreEnums = {
  1: '1세트 승패(0,1)',
  2: '2세트 승패(0,1)',
  3: '3세트 승패(0,1)',
  4: '4세트 승패(0,1)',
  5: '5세트 승패(0,1)',

  k1: '1세트 킬수',
  k2: '2세트 킬수',
  k3: '3세트 킬수',
  k4: '4세트 킬수',
  k5: '5세트 킬수',

  f_k1: '1세트 첫 킬(0,1)',
  f_t1: '1세트 첫 타워(0,1)',
  f_b1: '1세트 첫 바론(0,1)',
  f_d1: '1세트 첫 용(0,1)',
  t_1: '1세트 언오버',
}

export const EtcScoreEnums = {
  score: '승패(0,1)',
}

export const getScoreEnums = sportsType => {
  switch (sportsType) {
    case 'SOCCER':
      return SoccerScoreEnums
    case 'BASEBALL':
      return BaseballScoreEnums
    case 'BASKETBALL':
      return BasektBallScoreEnums
    case 'VOLLEYBALL':
      return VolleyBallScoreEnums
    case 'AMERICAN_FOOTBALL':
      return FootballScoreEnums
    case 'ICE_HOCKEY':
      return IceHockeyScoreEnums
    case 'BOXING':
      return BoxingScoreEnums
    case 'HANDBALL':
      return HandBallScoreEnums
    case 'TABLE_TENNIS':
      return TableTennisScoreEnums
    case 'TENNIS':
      return TennisScoreEnums
    case 'E_SPORTS':
      return ESportsScoreEnums
    case 'ETC':
      return EtcScoreEnums
    default:
      return null
  }
}
