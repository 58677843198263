import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 자산 조회 API
export async function searchUserSettlementAuto(params) {
  const response = await axios({
    url: '/tyson/partner/v1/partner/management/user-settlement-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 자산 조회 API
export async function searchUserHoldingAsset(params) {
  const response = await axios({
    url: '/tyson/v1/asset/holdingasset',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchUserHoldingAsset(params) {
  const [content, setContent] = useState([])
  async function fetchSearchUserHoldingAsset() {
    await searchUserHoldingAsset(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchUserHoldingAsset()
  }, [params])

  return { content, fetchSearchUserHoldingAsset }
}
