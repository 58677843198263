export const casinoVendorEnums = {
  HONOR_LINK: 'HONOR 링크',
  GOLD_LINK_SLOT: 'GOLD 링크 슬롯',
  SWIX: '스윅스',
  PHAROS: '파로스',
}

export const CasinoVendorEnumsEnumsSelectOptions = () => {
  return Object.entries(casinoVendorEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
