import {
  CustomDepositStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import { RiCoupon2Line, RiBarChart2Fill } from 'react-icons/ri'
import { BsGraphUp } from 'react-icons/bs'

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { CouponButton, CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import MemberInfoForm from './MemberInfoForm'

function MemberInfo({ memberId }) {
  const [isMemberCouponModalShow, setMemberCouponModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberCouponModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <CouponButton role={Button} onClick={() => onClickHandler()}>
        <Tooltip text="최근동향" place="bottom">
          <span>
            <CustomIcon style={{ color: 'red' }} />
          </span>
        </Tooltip>
      </CouponButton>
      <CustomDepositStyledModal show={isMemberCouponModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>{memberId} 최근 동향</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberInfoForm memberId={memberId} onClickHandler={onClickHandler} />
        </CustomModalBody>
      </CustomDepositStyledModal>
    </CustomBtnTableWrap>
  )
}

export default MemberInfo

MemberInfo.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const CustomIcon = styled(RiBarChart2Fill)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
`
