import { Button } from '@/shared/components/Button'
import {
  CustomCheckboxCustom,
  CustomCheckboxInput,
  CustomCheckboxLabel,
  CustomCheckboxWrap,
} from '@/shared/components/CustomCheckBox'
import { MemberResterBankSelect, PartnerRegisterSelect } from '@/shared/components/CustomSelect'
import Error from '@/shared/components/form/Error'
import {
  CustomFormButtonToolbar,
  FormContainer,
  FormFieldButton,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
  PartnerRegisterFormGroupIcon,
} from '@/shared/components/form/FormElements'
import CheckIcon from 'mdi-react/CheckIcon'
import EyeIcon from 'mdi-react/EyeIcon'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import ko from 'date-fns/locale/ko'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../../../utils/palette'
import { convertToKst } from '../../../utils/dateTime'
import Loading from '../../../shared/components/Loading'
import { searchBankList } from '../../../utils/api/memberManage/memberManageApi'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'
import { Card, CardBody, SearchCardTitleWrap } from '../../../shared/components/Card'
import { UserAuthoritySelectOptions } from '../../../enums/authority'
import { registerPartner, searchPartners } from '../../../utils/api/partnerManage/partnerRegisterApi'
import {
  MemberCasinoLevelEnumSelectOptions,
  MemberMiniGameLevelEnumSelectOptions,
  MemberSportsLevelEnumSelectOptions,
} from '../../../enums/MemberManage/MemberInfoEnums'
import { downPartnerRegisterValidate } from '../../../utils/validate/partnerManage/partnerRegisterValidate'

const UserRegistrationPage = () => {
  const [registrationLoading, setRegistrationLoading] = useState(false)

  const [partnerId, setPartnerId] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [partnerPassword, setPartnerPassword] = useState('')
  const [nickName, setNickName] = useState('')
  const [userBirthDay, setUserBirthDay] = useState('')

  const [phoneNum, setPhoneNum] = useState('')

  // 예금주
  const [accountHolder, setAccountHolder] = useState('')

  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  // 유저 타입
  const [userAuthority, setUserAuthority] = useState(null)
  const [userAuthorityOption, setUserAuthorityOption] = useState(UserAuthoritySelectOptions())
  const onChangeUserAuthorityHandler = option => {
    if (option == null) {
      setUserAuthority(null)
      return
    }

    setUserAuthority(option)
  }

  // 상위 본사는 무조건 여기서
  useEffect(() => {
    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })
  }, [])

  const onSubmit = e => {
    if (registrationLoading) return
    const body = {
      userAuthority: e.userAuthority.value,
      userId: e.partnerId,
      nickName: e.nickName,
      userBirthDay: !userBirthDay ? null : convertToKst(userBirthDay),
      userPassword: e.partnerPassword,
      userPhoneNum: phoneNum || '',
      accountHolder: e.accountHolder,
      accountNum: accountNum || '',
      bankName: bankNameOptionValue?.label || '',
    }

    if (window.confirm(`${e.partnerId} 유저를 가입 하시겠습니까?`)) {
      setRegistrationLoading(true)
      registerPartner(body)
        .then(res => {
          setRegistrationLoading(false)
          alert(`${e.partnerId} 유저 등록이 성공했습니다.\n 관리자 승인 후 로그인 가능합니다.`)
          window.location.reload()
        })
        .catch(error => {
          setRegistrationLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SIGN-1002') {
            alert(`${message}`)
          } else {
            alert(`${e.partnerId} 유저 등록이 실패했습니다.`)
          }
        })
    }
  }

  return (
    <>
      {registrationLoading ? (
        <Loading />
      ) : (
        <>
          <Card>
            <CardBody>
              <MemberManageMentTabs activeKey="4" />

              <Col md={8} lg={8} xl={8}>
                <SearchCardTitleWrap>
                  <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <p>
                      * <span style={{ color: 'blue' }}>파트너 생성 시</span> 하위 파트너가 되고,
                      <span style={{ color: 'blue' }}>회원 생성 시</span> 직영회원이 됩니다.
                    </p>
                    <p>
                      * <span style={{ fontWeight: 'bolder' }}>유저 등록 시</span> 관리자가 승인을 해야 로그인이
                      가능합니다.
                    </p>
                  </div>
                </SearchCardTitleWrap>
                <Form
                  onSubmit={onSubmit}
                  initialValues={{
                    partnerId,
                    partnerPassword,
                    userAuthority,
                    userBirthDay,
                    nickName,
                    accountHolder,
                  }}
                  validate={downPartnerRegisterValidate}
                >
                  {({ handleSubmit, form: { reset } }) => (
                    <FormContainer horizontal onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>유저 타입</FormGroupLabel>
                        <FormGroupField>
                          <Field name="userAuthority">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <MemberResterBankSelect
                                  {...input}
                                  value={userAuthority}
                                  onChange={option => {
                                    onChangeUserAuthorityHandler(option)
                                  }}
                                  options={userAuthorityOption}
                                  placeholder="유저 타입을 선택하세요"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  isClearable
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>유저 아이디</FormGroupLabel>
                        <FormGroupField>
                          <Field name="partnerId">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  style={{ border: 'solid 1px black' }}
                                  value={partnerId}
                                  autoComplete="new-password"
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                                    if (onlyEnglish.length > 15) {
                                      return
                                    }
                                    setPartnerId(onlyEnglish)
                                  }}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                        <CustomFormGroupDescription>
                          15글자 미만 영문 및 숫자의 조합만 가능합니다.
                        </CustomFormGroupDescription>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>비밀번호</FormGroupLabel>
                        <FormGroupField>
                          <Field name="partnerPassword">
                            {({ input, meta }) => (
                              <>
                                <FormInputWrap>
                                  <input
                                    {...input}
                                    type="text"
                                    style={{ border: 'solid 1px black' }}
                                    inputMode="latin"
                                    value={partnerPassword}
                                    autoComplete="new-password"
                                    onChange={e => {
                                      setPartnerPassword(e.target.value)
                                    }}
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </FormInputWrap>
                              </>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>닉네임</FormGroupLabel>
                        <FormGroupField>
                          <Field name="nickName">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={nickName}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    setNickName(e.target.value)
                                  }}
                                />
                                {meta.touched && meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>생년월일</FormGroupLabel>
                        <FormGroupField>
                          <Field name="userBirthDay">
                            {({ input, meta }) => (
                              <>
                                <DatePickerWrap>
                                  <DatePicker
                                    selected={userBirthDay}
                                    onChange={date => setUserBirthDay(date)}
                                    dateFormat="yyyy.MM.dd"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    dropDownMode="select"
                                    popperPlacement="bottom-start"
                                    placeholderText="필수사항은 아닙니다."
                                    locale={ko}
                                  />
                                </DatePickerWrap>
                                {meta.touched && meta.error && <Error error={meta.error} />}
                              </>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>연락처</FormGroupLabel>
                        <FormGroupField>
                          <Field name="phoneNum">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={phoneNum}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setPhoneNum(onlyNumber)
                                  }}
                                  placeholder="없으면 비워두세요"
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>예금주(이름)</FormGroupLabel>
                        <FormGroupField>
                          <Field name="accountHolder">
                            {({ input, meta }) => (
                              <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={accountHolder}
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    setAccountHolder(e.target.value)
                                  }}
                                  placeholder="없으면 비워두세요"
                                />
                                {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>은행명</FormGroupLabel>
                        <FormGroupField>
                          <Field name="bankName">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <MemberResterBankSelect
                                  {...input}
                                  value={bankNameOptionValue}
                                  onChange={option => {
                                    // input.onChange(option)
                                    onChangeBankNameHandler(option)
                                  }}
                                  options={bankNameOption}
                                  placeholder="없으면 비워두세요"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>계좌번호</FormGroupLabel>
                        <FormGroupField>
                          <Field name="accountNum">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  style={{ border: 'solid 1px black' }}
                                  value={accountNum}
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setAccountNum(onlyNumber)
                                  }}
                                  placeholder="없으면 비워두세요"
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                      <CustomFormButtonToolbar>
                        <Button variant="primary" type="submit">
                          등록하기
                        </Button>
                      </CustomFormButtonToolbar>
                    </FormContainer>
                  )}
                </Form>
              </Col>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default UserRegistrationPage

const CustomFormGroupDescription = styled(FormGroupDescription)`
  font-size: 12px;
`
const DatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 14px;
  line-height: 26px;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid #4a4a4a;
  z-index: 101;

  input {
    width: 100%;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: rgb(211, 211, 211);
    border: transparent;
    background: transparent;
    outline: none;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: lightgrey;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: black !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__month-dropdown-container {
    .react-datepicker__month-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__month-dropdown {
    border: 2px solid lightgrey;
    color: black;
  }

  .react-datepicker__year-dropdown {
    border: 2px solid lightgrey;
    color: black;

    .react-datepicker__navigation {
      top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
      display: none;
    }

    .react-datepicker__navigation--years-previous {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container {
    .react-datepicker__year-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`
