export const VirtualResultStatusEnums = {
  WIN: '적중',
  DRAW: '적특',
  LOSE: '미적중',
  ING: '진행중',
  CANCEL: '취소',
}

export const VirtualDetailBettingLogSettlementType = {
  CANCELLED: '취소',
  NOT_SETTLED: '진행중',
  LOSER: '낙첨',
  WINNER: '적중',
  REFUND: '적특',
}

export const VirtualGameFixtureStatus = {
  NSY: '경기전',
  IN_PROGRESS: '경기 중',
  FINISHED: '경기 종료',
  INVALID: '',
}

export const VirtualBettingResultStatusEnumsSelectOptions = () => {
  return Object.entries(VirtualResultStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const VirtualGameSportType = {
  VIRTUAL_SOCCER: '가상 축구',
  VIRTUAL_BASKETBALL: '가상 농구',
  VIRTUAL_BASEBALL: '가상 야구',
  // VIRTUAL_AMERICAN_FOOTBALL: '가상 미식축구',
  // VIRTUAL_TENNIS: '가상 테니스',
  // VIRTUAL_CRICKET: '가상 크리켓',
  // VIRTUAL_DARTS: '가상 다트',
  VIRTUAL_GREYHOUNDS: '개 경주',
  VIRTUAL_HORSE: '경마',
  // VIRTUAL_MOTOR: '자동차 경주',
  // VIRTUAL_SPEEDWAY: '오토바이 경주',
  // VIRTUAL_TROTTING: '마차 경주',
  // VIRTUAL_CYCLING: '싸이클 경주',
}

export const RealVirtualGameSportType = {
  VIRTUAL_SOCCER: '가상 축구',
  VIRTUAL_BASKETBALL: '가상 농구',
  VIRTUAL_BASEBALL: '가상 야구',
  VIRTUAL_GREYHOUNDS: '개 경주',
  VIRTUAL_HORSE: '경마',
}

export const VirtualGameBaseballLeagueType = {
  VIRTUAL_BRANDON_FIELD: 'Brandon Field',
  VIRTUAL_NORTH_STADIUM: 'North Stadium',
}

export const VirtualGameSoccerLeagueType = {
  VIRTUAL_EURO_CUP: 'Euro Cup',
  VIRTUAL_PREMIERSHIP: 'Premiership',
  VIRTUAL_SUPERLEAGUE: 'Superleague',
  VIRTUAL_WORLD_CUP: 'World Cup',
  // VIRTUAL_EXPRESS_CUP: 'Express Cup',
}

export const VirtualGameBasketballLeagueType = {
  VIRTUAL_BAKER_GARDENS: 'Baker Gardens',
  VIRTUAL_WATERPORT_ARENA: 'Waterport Arena',
}

export const VirtualGameLeagueType = {
  /**
   * 가상 축구
   * */
  VIRTUAL_EURO_CUP: 'Euro Cup',
  VIRTUAL_PREMIERSHIP: 'Premiership',
  VIRTUAL_SUPERLEAGUE: 'Superleague',
  VIRTUAL_WORLD_CUP: 'World Cup',
  // VIRTUAL_EXPRESS_CUP: 'Express Cup',

  /**
   * 가상 농구
   * */
  VIRTUAL_BAKER_GARDENS: 'Baker Gardens',
  VIRTUAL_WATERPORT_ARENA: 'Waterport Arena',

  /**
   * 가상 야구
   * */
  VIRTUAL_BRANDON_FIELD: 'Brandon Field',
  VIRTUAL_NORTH_STADIUM: 'North Stadium',

  /**
   * 가상 미식축구
   * */
  VIRTUAL_BRADWELL_FIELD: 'Bradwell Field',
  VIRTUAL_V_PLAY_NFL: 'V-Play NFL',
  VIRTUAL_QUEENSWAY_PARK: 'Queensway Park',

  /**
   * 가상 테니스
   * */
  VIRTUAL_ROCKVILL_OPEN: 'Rockvill Open',

  /**
   * 가상 크리켓
   * */
  VIRTUAL_CLAYTON_OVAL: 'Clayton Oval',
  VIRTUAL_GREENWAY_FIELDS: 'Greenway Fields',

  /**
   * 가상 다트
   * */
  VIRTUAL_THE_GATEHOUSE: 'The Gatehouse',

  /**
   * 개 경주
   * */
  VIRTUAL_BENNETT_PARK: 'Bennett Park',
  VIRTUAL_GOLDENHILL_PARK: 'Goldenhill Park',
  VIRTUAL_HILLSIDE_PARK: 'Hillside Park',

  /**
   * 말 경주
   * */
  VIRTUAL_BRITANNIA_WAY: 'Britannia Way',
  VIRTUAL_FESTIVAL_DOWNS: 'Festival Downs',
  VIRTUAL_VICTORIA_PARK: 'Victoria Park',
  VIRTUAL_NATIONAL_PARK: 'National Park',
  VIRTUAL_TRENTHAM_PARK: 'Trentham Park',

  /**
   * 자동차 경주
   * */
  VIRTUAL_MITCHELL_CIRCUIT: 'Mitchell Circuit',

  /**
   * 오토바이 경주
   * */
  VIRTUAL_STERLING_STADIUM: 'Sterling Stadium',

  /**
   * 마차 경주
   * */
  VIRTUAL_ETRURIA_GARDENS: 'Etruria Gardens',

  /**
   * 싸이클 경주
   * */
  VIRTUAL_FRANKLIN_VELODROME: 'Franklin Velodrome',
}

export const VirtualGameBettingType = {
  WIN_DRAW_LOSE_HOME_PICK: '홈승',
  WIN_DRAW_LOSE_DRAW_PICK: '무승부',
  WIN_DRAW_LOSE_AWAY_PICK: '원정승',
  HANDICAP_HOME_PICK: '홈승',
  HANDICAP_AWAY_PICK: '원정승',
  UNDER_OVER_UNDER_PICK: '언더',
  UNDER_OVER_OVER_PICK: '오버',
  RACE_ODD_EVEN_ODD_PICK: '홀',
  RACE_ODD_EVEN_EVEN_PICK: '짝',
  DOUBLE_CHANCE_HOME_OR_DRAW: '홈승 또는 무승부',
  DOUBLE_CHANCE_HOME_OR_AWAY: '홈승 또는 원정승',
  DOUBLE_CHANCE_DRAW_OR_AWAY: '무승부 또는 원정승',
  EXACT_TOTAL_GOALS_ZERO: '0 골',
  EXACT_TOTAL_GOALS_ONE: '1 골',
  EXACT_TOTAL_GOALS_TWO: '2 골',
  EXACT_TOTAL_GOALS_THREE: '3 골',
  EXACT_TOTAL_GOALS_FOUR: '4 골',
  RACE_WINNER_PICK: '우승',
}

export const FixtureBetResult = {
  HOME: '홈승',
  AWAY: '원정승',
  DRAW: '무승부',
  UNDER: '언더',
  OVER: '오버',
}
