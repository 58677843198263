import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { MemberStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberInfo from '../PartnerList/MemberInfo/MemberInfo'
import { decodeAccessToken } from '../../../utils/token'
import MemberHistoryMoneyIncDec from './MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyIncDec'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o
const checkNumber = checkMoney => {
  return Math.sign(checkMoney)
}

const MemberListData = (content, setModalShow, setWhatModalShow, setModalUserId) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'NORMAL':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'WAITING':
            return <span style={{ color: 'orange', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'CANCEL':
            return <span style={{ color: 'red', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'BLOCK':
            return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'APPLY':
            return <span style={{ color: 'green', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
        }
      },
    },
    {
      Header: '유저 ID / 이름 / 닉네임 ',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500', fontSize: '15px' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                (&nbsp;{original.memberId} / {original.memberName}/ {original.nickName}&nbsp;)
              </span>
              <span>
                <MemberInfo memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
      width: 115,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '지인 ID',
      accessor: 'friendId',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.friendId || '-'
      },
    },
    {
      Header: '직영 파트너ID/명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
    },
    {
      Header: '연락처',
      accessor: 'userPhoneNum',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.userPhoneNum || '-'
      },
    },
    {
      Header: '지급',
      accessor: 'isMoneyIncExchange',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (decodeAccessToken()?.isMoneyIncExchange && original.status === 'NORMAL') {
          return (
            <CustomButton
              style={{ background: 'green', width: '50px', height: '30px', fontSize: '14px' }}
              onClick={() => {
                setModalShow(true)
                setWhatModalShow('ASSET')
                setModalUserId(original.memberId)
              }}
            >
              지급
            </CustomButton>
          )
        }
        return <span style={{ color: 'black', fontWeight: '500' }}>-</span>
      },
    },
    {
      Header: '회수',
      accessor: 'isMoneyDecExchange',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (decodeAccessToken()?.isMoneyDecExchange && original.status === 'NORMAL') {
          return (
            <CustomButton
              style={{ background: 'orange', width: '50px', height: '30px', fontSize: '14px' }}
              onClick={() => {
                setModalShow(true)
                setWhatModalShow('ASSET_DEC')
                setModalUserId(original.memberId)
              }}
            >
              회수
            </CustomButton>
          )
        }
        return <span style={{ color: 'black', fontWeight: '500' }}>-</span>
      },
    },
    {
      Header: '지갑 머니',
      accessor: 'moneyAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.moneyAmount > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.moneyAmount?.toString()?.replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.moneyAmount?.toString()?.replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '카지노 머니',
      accessor: 'gameMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.gameMoney > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.gameMoney?.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.gameMoney?.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '홀덤 머니',
      accessor: 'holdemMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.holdemMoney > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.holdemMoney?.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.holdemMoney?.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '포인트',
      accessor: 'pointAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.pointAmount?.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '총 입금',
      accessor: 'totalDepositAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalDepositAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 출금',
      accessor: 'totalWithdrawalAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row

        return original.totalWithdrawalAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 입금-총 출금',
      accessor: 'totalMoneyProfitAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (checkNumber(original.totalMoneyProfitAmount)) {
          case 1: {
            return (
              <span style={{ color: 'blue', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          case -1: {
            return (
              <span style={{ color: 'red', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          default:
            return (
              <span style={{ color: 'black', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
        }
      },
    },
    {
      Header: '총 배팅',
      accessor: 'totalBettingAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalBettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 당첨',
      accessor: 'totalWinningAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalWinningAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 배팅-총 당첨',
      accessor: 'totalBettingProfitAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (checkNumber(original.totalBettingProfitAmount)) {
          case 1: {
            return (
              <span style={{ color: 'blue', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          case -1: {
            return (
              <span style={{ color: 'red', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          default:
            return (
              <span style={{ color: 'black', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
        }
      },
    },
    {
      Header: '카지노 접속현황',
      accessor: 'currentAccessGame',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.currentAccessGame) {
          return <span style={{ color: 'red' }}>접속중</span>
        }
        return <span style={{ color: 'blue' }}>미접속</span>
      },
    },
    {
      Header: '카지노 접속게임',
      accessor: 'lastCasinoAgency',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.lastCasinoAgency) {
          return (
            <span>
              {original.lastCasinoVendor} - {original.lastCasinoGame}
            </span>
          )
        }
        return <span>-</span>
      },
    },
    {
      Header: '최근 로그인일',
      accessor: 'lastLoginDate',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <div>{convertToKstSportsEventDateTime(original.lastLoginDate)}</div>
      },
    },
    {
      Header: '가입일',
      accessor: 'signUpDate',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <div>{convertToKstSportsEventDateTime(original.signUpDate)}</div>
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberListData

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
const CustomButton = styled.button`
  cursor: pointer;
  border: none;
  color: #fff;
`
