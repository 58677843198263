import { Card, CardBody } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EdgeSearchButton from '../../shared/components/EdgeSearchButton'
import DailyAssetStatisticsList from './DailyAssetStatisticsList/DailyAssetStatisticsList'
import DailyBettingStatisticsList from './DailyBettingStatistics/DailyBettingStatisticsList'
import StatisticsTabs2 from './DashBoardTabs'
import TotalAccumulateList from './TotalAccumulateList/TotalAccumulateList'

const DashBoardListPage = () => {
  // tab key
  const [activeKey, setActiveKey] = useState('1')

  return (
    <Card>
      <CardBody>
        <StatisticsTabs2 activeKey={activeKey} />
        <>
          <TotalAccumulateList />
          <DailyAssetStatisticsList />
          <DailyBettingStatisticsList />
        </>
      </CardBody>
    </Card>
  )
}

export default DashBoardListPage
