export const MemberPointDepositReceiverTypeEnums = {
  COMP_POINT_ADMIN_BONUS: '관리자 보너스',
  CASINO_COMP_POINT: '카지노 콤프 포인트',
  CASINO_ROLLING_POINT: '카지노 롤링 포인트',
  CASINO_DIRECT_SUB_USER_ROLLING_POINT: '카지노 직영회원 롤링 포인트',
  CASINO_SUB_USER_ROLLING_POINT: '카지노 하부회원 롤링 포인트',
  LIVE_CASINO_FRIEND_BENEFIT_POINT: '라이브 추천인 포인트',
  SLOT_CASINO_FRIEND_BENEFIT_POINT: '슬롯 추천인 포인트',
  MINI_GAME_ROLLING_POINT: '미니게임 롤링 포인트',
  MINI_GAME_DIRECT_SUB_USER_ROLLING_POINT: '미니게임 직영회원 롤링 포인트',
  MINI_GAME_SUB_USER_ROLLING_POINT: '미니게임 하부회원 롤링 포인트',
  MINI_GAME_LOSS_POINT: '미니게임 낙첨 포인트',
  MINI_GAME_COMP_POINT: '미니게임 콤프 포인트',
  MINI_GAME_FRIEND_BENEFIT_POINT: '미니게임 추천인 포인트',
  SPORTS_ROLLING_POINT: '스포츠 롤링 포인트',
  SPORTS_DIRECT_SUB_USER_ROLLING_POINT: '스포츠 직영회원 롤링 포인트',
  SPORTS_SUB_USER_ROLLING_POINT: '스포츠 하부회원 롤링 포인트',
  SPORTS_LOSS_POINT: '스포츠 낙첨 포인트',
  SPORTS_COMP_POINT: '스포츠 콤프 포인트',
  SPORTS_FRIEND_BENEFIT_POINT: '스포츠 추천인 포인트',
  ATTENDANCE_BONUS_POINT: '출석 보너스 포인트',
  MONEY_DEPOSIT_WITHDRAWAL_LOSING_POINT: '입출 관련 루징 포인트',
  MONEY_DEPOSIT_WITHDRAWAL_DIRECT_SUB_USER_LOSING_POINT: '입출 관련 직영회원 루징 포인트',
  MONEY_DEPOSIT_WITHDRAWAL_SUB_USER_LOSING_POINT: '입출 관련 하부회원 루징 포인트',
  CASINO_LOSING_POINT: '카지노 루징 포인트',
  CASINO_DIRECT_SUB_USER_LOSING_POINT: '카지노 직영회원 루징 포인트',
  CASINO_SUB_USER_LOSING_POINT: '카지노 하부회원 루징 포인트',
  MINI_GAME_LOSING_POINT: '미니게임 루징 포인트',
  MINI_GAME_DIRECT_SUB_USER_LOSING_POINT: '미니게임 직영회원 루징 포인트',
  MINI_GAME_SUB_USER_LOSING_POINT: '미니게임 하부회원 루징 포인트',
  SPORTS_LOSING_POINT: '스포츠 루징 포인트',
  SPORTS_DIRECT_SUB_USER_LOSING_POINT: '스포츠 직영회원 루징 포인트',
  SPORTS_SUB_USER_LOSING_POINT: '스포츠 하부회원 루징 포인트',
  PAYBACK_BONUS_POINT: '페이백 포인트',
  HOLDEM_COMP_POINT: '홀덤 콤프 포인트',
  HOLDEM_LOSS_POINT: '홀덤 낙첨 포인트',
  HOLDEM_FRIEND_BENEFIT_POINT: '홀덤 추천인 포인트',
  HOLDEM_ROLLING_POINT: '홀덤 롤링 포인트',
  HOLDEM_DIRECT_SUB_USER_ROLLING_POINT: '홀덤 직영회원 롤링 포인트',
  HOLDEM_SUB_USER_ROLLING_POINT: '홀덤 하부회원 롤링 포인트',
  HOLDEM_LOSING_POINT: '홀덤 루징 포인트',
  HOLDEM_DIRECT_SUB_USER_LOSING_POINT: '홀덤 직영회원 루징 포인트',
  HOLDEM_SUB_USER_LOSING_POINT: '홀덤 하부회원 루징 포인트',
  FRIEND_COMP_POINT: '지인추천 포인트',
}

export const MemberPointDepositReceiverTypeEnumsSelectOptions = () => {
  return Object.entries(MemberPointDepositReceiverTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberPointSearchTypeEnums = {
  BONUS: '보너스',
  ROLLING: '롤링 포인트',
  LOSING: '루징 포인트',
  COMP: '콤프 포인트',
  LOSS: '낙첨 포인트',
  BENEFIT: '추천인 포인트',
}

export const MemberPointSearchTypeEnumsSelectOptions = () => {
  return Object.entries(MemberPointSearchTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
