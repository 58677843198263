import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { Button } from 'react-bootstrap'
import { ko } from 'date-fns/esm/locale'
import DatePicker from 'react-datepicker'
import MemberStatisticsBettingLiveCasino from './MemberStatisticsBettingLiveCasino'
import MemberStatisticsBettingMini from './MemberStatisticsBettingMini'
import MemberStatisticsBettingSlotCasino from './MemberStatisticsBettingSlotCasino'
import { searchMemberBettingStatistics } from '../../../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../../../utils/dateTime'
import MemberStatisticsBettingSports from './MemberStatisticsBettingSports'

const MemberStatisticsBetting = ({ memberId }) => {
  const endDate = new Date()
  const startDate = new Date()
  startDate.setMonth(endDate.getMonth() - 1)
  const [bettingStatisticsParams, setBettingStatisticsParams] = useState({
    memberId,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
  })

  const [liveCasino, setLiveCasino] = useState({})
  const [slotCasino, setSlotCasino] = useState({})
  const [miniGame, setMiniGame] = useState({})
  const [sportsGame, setSportsGame] = useState({})

  useEffect(() => {
    searchMemberBettingStatistics(bettingStatisticsParams)
      .then(res => {
        setLiveCasino(res.data.liveCasinoBettingStatistics)
        setSlotCasino(res.data.slotCasinoBettingStatistics)
        setMiniGame(res.data.miniGameBettingStatistics)
        setSportsGame(res.data.sportsGameBettingStatistics)
      })
      .catch(error => {})
  }, [bettingStatisticsParams])

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingStatisticsParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  return (
    <>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm:00"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>카지노(라이브) 배팅통계</h3>
      <MemberStatisticsBettingLiveCasino liveCasino={liveCasino} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>카지노(슬롯) 배팅통계</h3>
      <MemberStatisticsBettingSlotCasino slotCasino={slotCasino} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>미니게임 배팅통계</h3>
      <MemberStatisticsBettingMini miniGame={miniGame} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>스포츠 배팅통계</h3>
      <MemberStatisticsBettingSports sportsGame={sportsGame} />
    </>
  )
}

export default MemberStatisticsBetting

MemberStatisticsBetting.propTypes = {
  memberId: PropTypes.string.isRequired,
}
