import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const MemberHistoryTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            {/* <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/partner/moneydeposit')
                }}
              >
                입금신청
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/partner/moneywithdrawal')
                }}
              >
                출금신청
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/log/moneydepowithdraw')
                }}
              >
                입출금 내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/log/moneyexchange-rate')
                }}
              >
                환수율 내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/log/moneyinc')
                }}
              >
                머니 증감내역
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default MemberHistoryTabs

MemberHistoryTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
