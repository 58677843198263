import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { deleteNoteList, updateNoteStatus } from '../../../utils/api/customCenter/customCenterApi'
import { fetchNoteList } from '../../../redux/customCenterSlice'
import { fetchNoteSize } from '../../../redux/memberInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstByFormat, convertToKstDateTime } from '../../../utils/dateTime'
import { NoteReadStatusEnums } from '../../../enums/Operation/NoteEnums'
import NoteRadio from './NoteRadio'

const NoteList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const dispatch = useDispatch()
  const [noteParams, setNoteParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(true)

  const [content, setContent] = useState([])
  const { totalElement, apiContent, noReadSize } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noteInfo.totalElement,
      apiContent: customCenter.noteInfo.content,
      noReadSize: customCenter.noteInfo.noReadSize,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.noteKey === item.noteKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchNoteList = async () => {
    try {
      dispatch(fetchNoteList(noteParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchNoteList()
  }, [noteParams])

  const [checkRadio, setCheckRadio] = useState([])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = noteKey => {
    const idx = content.findIndex(item => item.noteKey === noteKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === noteKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 쪽지를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }
  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <WebPageWrap ref={webPageWrapRef}>
      <OneToOneHeaderWrap>
        <CustomerCenterHeaderInfo>쪽지함</CustomerCenterHeaderInfo>
        <OnetoOneSubhead>* 읽음 처리 된 것만 삭제 하실 수 있습니다.</OnetoOneSubhead>
      </OneToOneHeaderWrap>

      <CustomerCenterContentWrap style={{ paddingTop: '7px' }}>
        <ListWrap>
          <ListButton
            onClick={() => {
              setCheckRadio(content.map(value => value.noteKey))
            }}
            type="button"
          >
            <MoneyFormModifyButtomInfo>전체선택</MoneyFormModifyButtomInfo>
          </ListButton>
          <ListButton onClick={() => onDeleteHandler()} variant="primary" type="button">
            <MoneyFormModifyButtomInfo>삭제하기</MoneyFormModifyButtomInfo>
          </ListButton>
        </ListWrap>
        <CustomerCenterContentTable>
          <CustomerCenterContentTableHead>
            <CustomerCenterContentTableHeadTr>
              <th style={{ width: '10%' }}>선택</th>
              <th style={{ width: '10%' }}>번호</th>
              <th style={{ width: '60%' }}>제목</th>
              <th style={{ width: '12%' }}>수신일</th>
              <th style={{ width: '8%' }}>상태</th>
            </CustomerCenterContentTableHeadTr>
          </CustomerCenterContentTableHead>
          <CustomerCenterContentTableBody>
            {content.map(item => (
              <>
                <CustomerCenterContentTableBodyTr key={item.noteKey}>
                  <CustomerCenterContentBox>
                    <CustomerCenterContenItemBox width="10">
                      <NoteRadio
                        rowInfo={{
                          noteKey: item.noteKey,
                          status: item.status,
                        }}
                        setCheckRadio={setCheckRadio}
                        checkRadio={checkRadio}
                      />
                    </CustomerCenterContenItemBox>
                    <CustomerCenterContenItemBox width="10">{item.num}</CustomerCenterContenItemBox>
                    <CustomerCenterContenItemBox
                      width="60"
                      onClick={() => onClickRowHandler(item.noteKey)}
                      style={{ cursor: 'pointer' }}
                    >
                      {item.noteTitle}
                    </CustomerCenterContenItemBox>
                    <CustomerCenterContenItemBox width="12" onClick={() => onClickRowHandler(item.noteKey)}>
                      {convertToKstByFormat(item.sendDate, 'MM-DD HH:mm')}
                    </CustomerCenterContenItemBox>
                    <CustomerCenterContenItemBox width="8" onClick={() => onClickRowHandler(item.noteKey)}>
                      {NoteReadStatusEnums[item.status]}
                    </CustomerCenterContenItemBox>
                  </CustomerCenterContentBox>
                </CustomerCenterContentTableBodyTr>
                <Collapse in={item.isVisible}>
                  <CustomerCenterContentTableBodyTr>
                    <CustomerCenterContentDetailBox>
                      {ReactHtmlParser(item.sendContent, { transform: transformFn })}
                    </CustomerCenterContentDetailBox>
                  </CustomerCenterContentTableBodyTr>
                </Collapse>
              </>
            ))}
          </CustomerCenterContentTableBody>
        </CustomerCenterContentTable>
        {totalElement > 0 && (
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        )}
      </CustomerCenterContentWrap>
    </WebPageWrap>
  )
}

export default NoteList

const ListWrap = styled.div`
  display: flex;
`

export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  color: #000;
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  height: 70px;
  border-bottom: 1px solid #7b808c;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;

  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;

  span {
    font-size: 13px;
  }
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
`

export const CustomerCenterContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 0px 1px;

  gap: 10px;
`

export const CustomerCenterHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* 엘로우폰트 */

  background: linear-gradient(to right, #fcf4cb, #fddb2a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const ListButton = styled.button`
  width: 120px;
  height: 40px;
  margin-left: 10px;
  border-radius: 0;

  border-radius: 0;
  background: linear-gradient(180deg, #455963, #0a0e10);
  border: 1px solid #7b808c;
`

export const MoneyFormModifyButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  text-align: center;

  color: white !important;
`

export const WebPageWrap = styled.div`
  background: transparent;
  width: 100%;

  border: 1px solid #7b808c;
  border-left: none;
  border-right: none;
  border-bottom: none;
`
