import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchUserHoldingAsset } from '../utils/api/memberManage/memberManageApi'
import { searchAnswerCompleteOneToOne, searchNoReadNoteCount } from '../utils/api/customCenter/customCenterApi'

// 유저 데이터
export const fetchMemberInfoAction = createAction('FETCH_MEMBER_INFO')

// 재발급
export const reAuthenticationAction = createAction('RE_AUTHENTICATION')

export const fetchMemberInfo = createAsyncThunk('memberInfo/fetchMemberInfo', async () => {
  const res = await searchUserHoldingAsset()
  return res.data
})

export const fetchNoteSize = createAsyncThunk('memberInfo/fetchNoteSize', async () => {
  const res = await searchNoReadNoteCount()
  return res.data
})

export const fetchOneToOneSize = createAsyncThunk('memberInfo/fetchOneToOneSize', async () => {
  const res = await searchAnswerCompleteOneToOne()
  return res.data
})

const memberInfoSlice = createSlice({
  name: 'memberInfo',
  initialState: {
    memberHoldingMoney: 0,
    memberTotalGameMoney: 0,
    holdemMoneyAmount: 0,
    memberHoldingRollingPoint: 0,
    memberHoldingLosingPoint: 0,
    noteRedisSize: 0,
    oneToOneRedisSize: 0,
    userId: '',
    userName: '',
    bankName: '',
    accountNum: '',
    accountHolder: '',
    depositLevel: '',
    miniGameBettingInfo: {},
    sportsBettingInfo: {},
    noteMessageInfo: '',
    oneToOneMessageInfo: '',
    currentAccessGame: false,
    tempCurrentAccessGame: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      const {
        edgeName,
        userId,
        userName,
        nickName,
        bankName,
        accountNum,
        accountHolder,
        depositLevel,
        moneyAmount,
        holdemMoneyAmount,
        rollingPointAmount,
        losingPointAmount,
        casinoMoneyAmount,
        noteRedisSize,
        oneToOneRedisSize,
      } = action.payload

      state.memberHoldingMoney = moneyAmount
      state.memberTotalGameMoney = casinoMoneyAmount
      state.holdemMoneyAmount = holdemMoneyAmount
      state.memberHoldingRollingPoint = rollingPointAmount
      state.memberHoldingLosingPoint = losingPointAmount
      state.userId = userId
      state.userName = userName
      state.nickName = nickName
      state.bankName = bankName
      state.accountNum = accountNum
      state.accountHolder = accountHolder
      state.depositLevel = depositLevel
      state.noteRedisSize = noteRedisSize ?? 0
      state.oneToOneRedisSize = oneToOneRedisSize ?? 0
    },

    setNoteMessageInfo: (state, action) => {
      state.noteMessageInfo = action.payload
    },
    setOneToOneMessageInfo: (state, action) => {
      state.oneToOneMessageInfo = action.payload
    },
    setTempCurrentAccessGame: (state, action) => {
      // 접속: true, 미접속: false
      state.tempCurrentAccessGame = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMemberInfo.fulfilled, (state, action) => {
      const {
        holdingMoney,
        holdingMemberRollingPoint,
        holdingMemberLosingPoint,
        holdingTotalGameMoney,
        holdemMoneyAmount,
        userId,
        userName,
        bankName,
        accountNum,
        accountHolder,
        depositLevel,
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
        sportsGameMaxBetting,
        sportsGameMinBetting,
        sportsGameMaxWinning,
        sportsGameMinFolder,
        sportsGameMaxFolder,
        liveSportsGameMaxBetting,
        liveSportsGameMinBetting,
        liveSportsGameMaxWinning,
        liveSportsGameMinFolder,
        liveSportsGameMaxFolder,
        bettingPerMaxCriteria,
        noteRedisSize,
        oneToOneRedisSize,
        currentAccessGame,
      } = action.payload
      state.memberHoldingMoney = holdingMoney
      state.memberTotalGameMoney = holdingTotalGameMoney
      state.holdemMoneyAmount = holdemMoneyAmount
      state.memberHoldingRollingPoint = holdingMemberRollingPoint
      state.memberHoldingLosingPoint = holdingMemberLosingPoint
      state.noteRedisSize = noteRedisSize
      state.oneToOneRedisSize = oneToOneRedisSize
      state.userId = userId
      state.userName = userName
      state.bankName = bankName
      state.accountNum = accountNum
      state.accountHolder = accountHolder
      state.depositLevel = depositLevel
      state.miniGameBettingInfo = {
        miniGameMinBetting,
        miniGameMaxBetting,
        miniGameMaxWinning,
      }
      state.sportsGameBettingInfo = {
        sportsGameMaxBetting,
        sportsGameMinBetting,
        sportsGameMaxWinning,
        sportsGameMinFolder,
        sportsGameMaxFolder,
        liveSportsGameMaxBetting,
        liveSportsGameMinBetting,
        liveSportsGameMaxWinning,
        liveSportsGameMinFolder,
        liveSportsGameMaxFolder,
        bettingPerMaxCriteria,
      }
      state.currentAccessGame = currentAccessGame
    })

    builder.addCase(fetchNoteSize.fulfilled, (state, action) => {
      const { noteSize } = action.payload
      state.noteRedisSize = noteSize
    })
    builder.addCase(fetchOneToOneSize.fulfilled, (state, action) => {
      const { oneToOneSize } = action.payload
      state.oneToOneRedisSize = oneToOneSize
    })
  },
})

export const { setUserInfo, setNoteMessageInfo, setTempCurrentAccessGame, setOneToOneMessageInfo } =
  memberInfoSlice.actions

export default memberInfoSlice.reducer
