import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'
import { searchHeaderInfo } from '../utils/api/header/headerApi'

const createAsyncThunkWithAPI = (actionType, apiFunc) =>
  createAsyncThunk(actionType, async () => {
    const res = await apiFunc()
    return res.data
  })

export const fetchHeaderInfo = createAsyncThunkWithAPI('headerInfo/fetchHeaderInfo', searchHeaderInfo)

const headerInfoSlice = createSlice({
  name: 'headerInfo',
  initialState: {
    hideSideBar: isMobile,
    newMember: 0,
    totalMember: 0,
    newPartner: 0,
    totalPartner: 0,
    todayMemberDeposit: 0,
    todayMemberWithdrawal: 0,
    todayPartnerDeposit: 0,
    todayPartnerWithdrawal: 0,
    todayMemberDepositCount: 0,
    todayMemberWithdrawalCount: 0,
    todayPartnerDepositCount: 0,
    todayPartnerWithdrawalCount: 0,
    signInCount: 0,
    memberMoneyAmount: 0,
    memberRollingPointAmount: 0,
    memberBettingAwaitingAmount: 0,
    partnerBettingAwaitingAmount: 0,
  },
  reducers: {
    setHideSideBar: (state, action) => {
      state.hideSideBar = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchHeaderInfo.fulfilled, (state, action) => {
      const {
        newMember,
        totalMember,
        newPartner,
        totalPartner,
        todayMemberDeposit,
        todayMemberWithdrawal,
        todayPartnerDeposit,
        todayPartnerWithdrawal,
        todayMemberDepositCount,
        todayMemberWithdrawalCount,
        todayPartnerDepositCount,
        todayPartnerWithdrawalCount,
        signInCount,
        memberMoneyAmount,
        memberRollingPointAmount,
        memberBettingAwaitingAmount,
        partnerBettingAwaitingAmount,
      } = action.payload.content

      state.newMember = newMember
      state.totalMember = totalMember
      state.newPartner = newPartner
      state.totalPartner = totalPartner
      state.todayMemberDepositCount = todayMemberDepositCount
      state.todayMemberWithdrawalCount = todayMemberWithdrawalCount
      state.todayPartnerDepositCount = todayPartnerDepositCount
      state.todayPartnerWithdrawalCount = todayPartnerWithdrawalCount
      state.todayMemberDeposit = todayMemberDeposit
      state.todayMemberWithdrawal = todayMemberWithdrawal
      state.todayPartnerDeposit = todayPartnerDeposit
      state.todayPartnerWithdrawal = todayPartnerWithdrawal
      state.signInCount = signInCount
      state.memberMoneyAmount = memberMoneyAmount
      state.memberRollingPointAmount = memberRollingPointAmount
      state.memberBettingAwaitingAmount = memberBettingAwaitingAmount
      state.partnerBettingAwaitingAmount = partnerBettingAwaitingAmount
    })
  },
})

export const { setHideSideBar } = headerInfoSlice.actions

export default headerInfoSlice.reducer
