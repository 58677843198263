import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchBoardList, searchNoteList, searchOnetoOneList } from '../utils/api/customCenter/customCenterApi'

export const fetchNoteList = createAsyncThunk('memberInfo/fetchNoteList', async params => {
  const res = await searchNoteList(params)
  return res.data
})

export const fetchOneToOneList = createAsyncThunk('memberInfo/fetchOneToOneList', async params => {
  const res = await searchOnetoOneList(params)
  return res.data
})

const customCenterSlice = createSlice({
  name: 'customCenter',
  initialState: {
    noticeInfo: {
      totalElement: 0,
      content: [],
    },
    eventInfo: {
      totalElement: 0,
      content: [],
    },
    noteInfo: {
      totalElement: 0,
      content: [],
      noReadSize: 0,
    },
    oneToOneInfo: {
      totalElement: 0,
      content: [],
    },
  },
  reducers: {
    onClickNoteRowHandler: (state, action) => {
      const noteKey = action.payload

      const prevNoteContent = [...state.noteInfo.content]

      const idx = prevNoteContent.findIndex(item => item.noteKey === noteKey)
      if (idx !== -1) {
        prevNoteContent[idx] = {
          ...prevNoteContent[idx],
          isVisible: !prevNoteContent[idx].isVisible,
        }
      }

      state.noteInfo.content = prevNoteContent
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchNoteList.fulfilled, (state, action) => {
      const { totalElement, content, noReadSize } = action.payload

      state.noteInfo = {
        totalElement,
        content,
        noReadSize,
      }
    })
    builder.addCase(fetchOneToOneList.fulfilled, (state, action) => {
      const { totalElement, content } = action.payload

      state.oneToOneInfo = {
        totalElement,
        content,
      }
    })
  },
})

export const { onClickNoteRowHandler } = customCenterSlice.actions

export default customCenterSlice.reducer
