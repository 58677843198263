import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements'
import { isMobile } from 'react-device-detect'
import { postSiginIn } from '@/utils/api/authManage/authManageApi'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LogInForm from './components/LogInForm'
import OtpForm from './components/OtpForm'
import { createAccessToken, handleSignIn } from '../../socketio/userAuthSocketIOHandler'
import { setUserInfo } from '../../redux/memberInfoSlice'
import { setAccessToken } from '../../utils/token'
import Loading from '../../shared/components/Loading'

const LogIn = () => {
  const [siginInFlag, setSignInFlag] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [otpShown, setOtpShown] = useState(false)

  // const [loginIp, setLoginIp] = useState('')
  // useEffect(() => {
  //   axios.get('https://geolocation-db.com/json/').then(res => {
  //     setLoginIp(res.data.IPv4)
  //   })
  // }, [])

  const onLoginSubmit = e => {
    if (siginInFlag) return

    setSignInFlag(true)

    const body = {
      userId: e.userId,
      password: e.password,
      // eslint-disable-next-line no-useless-escape
      host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
    }

    handleSignIn(body).then(signInRes => {
      if (!signInRes.status) {
        alert('아이디, 비밀번호 확인 후 다시 시도해주세요.')
        setSignInFlag(false)
        return
      }
      const tokenData = {
        userId: body.userId,
        password: body.password,
      }

      // 토큰 발급
      createAccessToken(tokenData)
        .then(atRes => {
          if (!atRes.status) {
            alert('다시 로그인해주세요.')
            setSignInFlag(false)
            return
          }
          // 유저 정보 리덕스에 저장
          dispatch(setUserInfo(atRes.data.user))

          // 로컬 스토리지에 토큰 저장
          setAccessToken(atRes.data.accessToken)
          setSignInFlag(false)
          window.location.href = '/'
        })
        .finally(() => {
          setSignInFlag(false)
        })
    })
  }

  const onOtpSubmit = e => {
    // postOtp({
    //   otpNum: e.optNum,
    // })
    //   .then(res => {
    //     alert('최종 로그인 성공')
    //     // console.log('res => ', res)
    //     // history.push('/')
    //   })
    //   .catch(error => {
    //     console.log('error => ', error.response.data)
    //     alert('OTP인증에 실패 했습니다. 다시 로그인 해주세요.')
    //     window.location.reload()
    //   })
  }

  return (
    <AccountWrap isMobile={isMobile}>
      <AccountContent>
        <AccountCard isMobile={isMobile}>
          <AccountHead>
            <AccountTitle>
              <AccountLogo>
                <AccountLogoAccent>TYSON-파트너</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            {/* <h4 className="subhead">Start your business easily</h4> */}
          </AccountHead>
          {
            {
              true: <OtpForm onSubmit={onOtpSubmit} />,
              false: <LogInForm onSubmit={onLoginSubmit} />,
            }[otpShown]
          }
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  )
}

export default LogIn
