import {
  Card,
  CardBody,
  PartnerSettlementCardSubhead,
  PartnerSettlementCardTitle,
  PartnerSettlementCardTitleWrap,
} from '@/shared/components/Card'
import Tooltip from '@/shared/components/Tooltip'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { searchMoneyStatistics } from '../../../utils/api/statistics/accumulateStatisticsApi'
import { convertToKst, convertToKstDate } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const DailyAssetStatisticsList = () => {
  /**
   * 일별 배팅 통계
   */
  const now = new Date()
  now.setDate(1)
  now.setHours(0, 0, 0, 0)

  const [params, setParams] = useState({
    startDate: convertToKst(now),
  })

  const [content, setContent] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [sumData, setSumData] = useState({
    memberSignUpCount: 0,
    partnerSignUpCount: 0,
    depositMemberCount: 0,
    memberDepositAmount: 0,
    withdrawalMemberCount: 0,
    memberWithdrawalAmount: 0,
    depositHoldemMemberCount: 0,
    memberHoldemDepositAmount: 0,
    withdrawalHoldemMemberCount: 0,
    memberHoldemWithdrawalAmount: 0,
    memberRollingExchangeAmount: 0,
    depositPartnerCount: 0,
    partnerDepositAmount: 0,
    withdrawalPartnerCount: 0,
    partnerWithdrawalAmount: 0,
    depositHoldemPartnerCount: 0,
    partnerHoldemDepositAmount: 0,
    withdrawalHoldemPartnerCount: 0,
    partnerHoldemWithdrawalAmount: 0,
    partnerRollingExchangeAmount: 0,
    partnerLosingExchangeAmount: 0,
    adminIncAssetAmount: 0,
    adminDecAssetAmount: 0,
    totalProfit: 0,
  })

  const fetchSearchDailyMoneyStatistics = async () => {
    setApiLoading(true)
    setSumData({
      memberSignUpCount: 0,
      partnerSignUpCount: 0,
      depositMemberCount: 0,
      memberDepositAmount: 0,
      withdrawalMemberCount: 0,
      memberWithdrawalAmount: 0,
      depositHoldemMemberCount: 0,
      memberHoldemDepositAmount: 0,
      withdrawalHoldemMemberCount: 0,
      memberHoldemWithdrawalAmount: 0,
      memberRollingExchangeAmount: 0,
      depositPartnerCount: 0,
      partnerDepositAmount: 0,
      withdrawalPartnerCount: 0,
      partnerWithdrawalAmount: 0,
      depositHoldemPartnerCount: 0,
      partnerHoldemDepositAmount: 0,
      withdrawalHoldemPartnerCount: 0,
      partnerHoldemWithdrawalAmount: 0,
      partnerRollingExchangeAmount: 0,
      partnerLosingExchangeAmount: 0,
      adminIncAssetAmount: 0,
      adminDecAssetAmount: 0,
      totalProfit: 0,
    })
    await searchMoneyStatistics(params)
      .then(res => {
        setContent(res.data.content)
        setSumData(prev =>
          res.data.content.reduce(
            (acc, item) => ({
              memberSignUpCount: acc.memberSignUpCount + item.memberSignUpCount,
              partnerSignUpCount: acc.partnerSignUpCount + item.partnerSignUpCount,
              depositMemberCount: acc.depositMemberCount + item.depositMemberCount,
              memberDepositAmount: acc.memberDepositAmount + item.memberDepositAmount,
              withdrawalMemberCount: acc.withdrawalMemberCount + item.withdrawalMemberCount,
              memberWithdrawalAmount: acc.memberWithdrawalAmount + item.memberWithdrawalAmount,
              depositHoldemMemberCount: acc.depositHoldemMemberCount + item.depositHoldemMemberCount,
              memberHoldemDepositAmount: acc.memberHoldemDepositAmount + item.memberHoldemDepositAmount,
              withdrawalHoldemMemberCount: acc.withdrawalHoldemMemberCount + item.withdrawalHoldemMemberCount,
              memberHoldemWithdrawalAmount: acc.memberHoldemWithdrawalAmount + item.memberHoldemWithdrawalAmount,
              memberRollingExchangeAmount: acc.memberRollingExchangeAmount + item.memberRollingExchangeAmount,
              depositPartnerCount: acc.depositPartnerCount + item.depositPartnerCount,
              partnerDepositAmount: acc.partnerDepositAmount + item.partnerDepositAmount,
              withdrawalPartnerCount: acc.withdrawalPartnerCount + item.withdrawalPartnerCount,
              partnerWithdrawalAmount: acc.partnerWithdrawalAmount + item.partnerWithdrawalAmount,
              depositHoldemPartnerCount: acc.depositHoldemPartnerCount + item.depositHoldemPartnerCount,
              partnerHoldemDepositAmount: acc.partnerHoldemDepositAmount + item.partnerHoldemDepositAmount,
              withdrawalHoldemPartnerCount: acc.withdrawalHoldemPartnerCount + item.withdrawalHoldemPartnerCount,
              partnerHoldemWithdrawalAmount: acc.partnerHoldemWithdrawalAmount + item.partnerHoldemWithdrawalAmount,
              partnerRollingExchangeAmount: acc.partnerRollingExchangeAmount + item.partnerRollingExchangeAmount,
              partnerLosingExchangeAmount: acc.partnerLosingExchangeAmount + item.partnerLosingExchangeAmount,
              adminIncAssetAmount: acc.adminIncAssetAmount + item.adminIncAssetAmount,
              adminDecAssetAmount: acc.adminDecAssetAmount + item.adminDecAssetAmount,
              totalProfit: acc.totalProfit + item.totalProfit,
            }),
            {
              memberSignUpCount: prev.memberSignUpCount,
              partnerSignUpCount: prev.partnerSignUpCount,
              depositMemberCount: prev.depositMemberCount,
              memberDepositAmount: prev.memberDepositAmount,
              withdrawalMemberCount: prev.withdrawalMemberCount,
              memberWithdrawalAmount: prev.memberWithdrawalAmount,
              depositHoldemMemberCount: prev.depositHoldemMemberCount,
              memberHoldemDepositAmount: prev.memberHoldemDepositAmount,
              withdrawalHoldemMemberCount: prev.withdrawalHoldemMemberCount,
              memberHoldemWithdrawalAmount: prev.memberHoldemWithdrawalAmount,
              memberRollingExchangeAmount: prev.memberRollingExchangeAmount,
              depositPartnerCount: prev.depositPartnerCount,
              partnerDepositAmount: prev.partnerDepositAmount,
              partnerWithdrawalAmount: prev.partnerWithdrawalAmount,
              withdrawalPartnerCount: prev.withdrawalPartnerCount,
              depositHoldemPartnerCount: prev.depositHoldemPartnerCount,
              partnerHoldemDepositAmount: prev.partnerHoldemDepositAmount,
              withdrawalHoldemPartnerCount: prev.withdrawalHoldemPartnerCount,
              partnerHoldemWithdrawalAmount: prev.partnerHoldemWithdrawalAmount,
              partnerRollingExchangeAmount: prev.partnerRollingExchangeAmount,
              partnerLosingExchangeAmount: prev.partnerLosingExchangeAmount,
              adminIncAssetAmount: prev.adminIncAssetAmount,
              adminDecAssetAmount: prev.adminDecAssetAmount,
              totalProfit: prev.totalProfit,
            },
          ),
        )
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchDailyMoneyStatistics()
  }, [params])

  const [searchStartDate, setSearchStartDate] = useState(now)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams({
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      dailyStartDate: null,
      dailyEndDate: null,
    })
  }

  const adjustTimes = date => {
    if (!date) return
    const adjustedDate = new Date(date)
    adjustedDate.setHours(0, 0, 0) // 초만 59로 조정
    return adjustedDate
  }

  // 검색하기 버튼
  const onSearchClickHandler2 = () => {
    if (!searchExecuteStartDate || !searchExecuteEndDate) {
      alert('일별검색시 시작일과 종료일 설정은 필수입니다.')
    }

    const adjustedStartDate = adjustTimes(searchExecuteStartDate)
    const adjustedEndDate = adjustTimes(searchExecuteEndDate)

    setParams({
      startDate: null,
      dailyStartDate: !searchExecuteStartDate ? null : convertToKst(adjustedStartDate),
      dailyEndDate: !searchExecuteEndDate ? null : convertToKst(adjustedEndDate),
    })
  }

  return (
    <>
      <Card style={{ marginTop: '30px' }}>
        <CardBody style={{ padding: '0px' }}>
          <PartnerSettlementCardTitleWrap>
            <PartnerSettlementCardTitle>일별 입출금 통계</PartnerSettlementCardTitle>
            <PartnerSettlementCardSubhead>
              <p>
                * 본인 포함{' '}
                <span style={{ fontWeight: 'bolder', color: 'red', fontSize: '12px' }}>
                  직영/하부 회원 및 하위 파트너{' '}
                </span>
                들의 일별 입출금 통계를 확인 하실 수 있습니다.
              </p>
              <p>
                *{' '}
                <span style={{ color: 'black', fontWeight: 'bolder' }}>
                  회원/파트너 입금 수, 출금 수는 동일한 ID로 여러번 신청해도 1회로 값이 책정됩니다.
                </span>
              </p>
            </PartnerSettlementCardSubhead>
          </PartnerSettlementCardTitleWrap>
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>날짜</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    locale={ko}
                    showMonthYearPicker
                    isClearable
                    placeholderText="날짜"
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                월별 검색하기
              </Button>
            </SearchButtonWrap>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchExecuteStartDate}
                    onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd 00:00:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchExecuteEndDate}
                    onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd 00:00:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler2()
                }}
              >
                일별 검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          {apiLoading ? (
            <Loading />
          ) : (
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th scope="col">정산일</th>
                    <CustomTh scope="col" colorText="isMain" textColor="profit">
                      일별 입출금 수익
                      <br />
                      <span style={{ color: '#fff' }}>(홀덤 입출제외)</span>
                    </CustomTh>
                    <CustomTh scope="col" colorText="isMain" textColor="profit">
                      일별 가입자 수
                    </CustomTh>
                    <th>
                      <CustomDiv>
                        회원 가입수
                        <CustomHr />
                        파트너 가입수
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 입금-출금액(일반/홀덤)
                        <CustomHr />
                        파트너 입금-출금액(일반/홀덤)
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 입금수(일반/홀덤)
                        <CustomHr />
                        파트너 입금수(일반/홀덤)
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 입금액(일반/홀덤)
                        <CustomHr />
                        파트너 입금액(일반/홀덤)
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 출금수(일반/홀덤)
                        <CustomHr />
                        파트너 출금수(일반/홀덤)
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 출금액(일반/홀덤)
                        <CustomHr />
                        파트너 출금액(일반/홀덤)
                      </CustomDiv>
                    </th>
                    <th>
                      <CustomDiv>
                        회원 롤링전환액
                        <CustomHr />
                        파트너 롤링전환액
                      </CustomDiv>
                    </th>
                    <th>파트너 루징전환액</th>
                  </tr>
                </thead>
                <tbody>
                  {content.length !== 0 && (
                    <tr style={{ background: 'rgba(255,24,8,0.3)', color: 'red' }}>
                      <td>합계</td>
                      <td>
                        {(
                          Number(sumData?.memberDepositAmount) -
                          Number(sumData?.memberWithdrawalAmount) +
                          Number(sumData?.partnerDepositAmount) -
                          Number(sumData?.partnerWithdrawalAmount)
                        )
                          ?.toString()
                          .replace(commonReg2, ',')}
                      </td>
                      <td>
                        {(sumData?.memberSignUpCount + sumData?.partnerSignUpCount)
                          ?.toString()
                          .replace(commonReg2, ',')}
                      </td>
                      <td>
                        <CustomDiv>
                          {sumData?.memberSignUpCount?.toString().replace(commonReg2, ',')}
                          <CustomHr />
                          {sumData?.partnerSignUpCount?.toString().replace(commonReg2, ',')}
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {(Number(sumData?.memberDepositAmount) - Number(sumData?.memberWithdrawalAmount))
                            ?.toString()
                            .replace(commonReg2, ',')}{' '}
                          (
                          {(Number(sumData?.memberHoldemDepositAmount) - Number(sumData?.memberHoldemWithdrawalAmount))
                            ?.toString()
                            .replace(commonReg2, ',')}
                          )
                          <CustomHr />
                          {(Number(sumData?.partnerDepositAmount) - Number(sumData?.partnerWithdrawalAmount))
                            ?.toString()
                            .replace(commonReg2, ',')}{' '}
                          (
                          {(
                            Number(sumData?.partnerHoldemDepositAmount) - Number(sumData?.partnerHoldemWithdrawalAmount)
                          )
                            ?.toString()
                            .replace(commonReg2, ',')}
                          )
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {sumData?.depositMemberCount?.toString().replace(commonReg2, ',')} (
                          {sumData?.depositHoldemMemberCount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {sumData?.depositPartnerCount?.toString().replace(commonReg2, ',')} (
                          {sumData?.depositHoldemPartnerCount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>
                      <td>
                        <CustomDiv>
                          {sumData?.memberDepositAmount?.toString().replace(commonReg2, ',')} (
                          {sumData?.memberHoldemDepositAmount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {sumData?.partnerDepositAmount?.toString().replace(commonReg2, ',')} (
                          {sumData?.partnerHoldemDepositAmount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {sumData?.withdrawalMemberCount?.toString().replace(commonReg2, ',')} (
                          {sumData?.withdrawalHoldemMemberCount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {sumData?.withdrawalPartnerCount?.toString().replace(commonReg2, ',')} (
                          {sumData?.withdrawalHoldemPartnerCount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>
                      <td>
                        <CustomDiv>
                          {sumData?.memberWithdrawalAmount?.toString().replace(commonReg2, ',')} (
                          {sumData?.memberHoldemWithdrawalAmount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {sumData?.partnerWithdrawalAmount?.toString().replace(commonReg2, ',')} (
                          {sumData?.partnerHoldemWithdrawalAmount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {sumData?.memberRollingExchangeAmount?.toString().replace(commonReg2, ',')}
                          <CustomHr />
                          {sumData?.partnerRollingExchangeAmount?.toString().replace(commonReg2, ',')}
                        </CustomDiv>
                      </td>

                      <td>{sumData?.partnerLosingExchangeAmount?.toString().replace(commonReg2, ',')}</td>
                    </tr>
                  )}
                  {content?.map((original, index) => (
                    <tr>
                      <td>{convertToKstDate(original?.settlementDate)}</td>

                      <td>
                        {(
                          Number(original?.memberDepositAmount) -
                          Number(original?.memberWithdrawalAmount) +
                          Number(original?.partnerDepositAmount) -
                          Number(original?.partnerWithdrawalAmount)
                        )
                          ?.toString()
                          .replace(commonReg2, ',')}
                      </td>
                      <td>
                        {(original?.memberSignUpCount + original?.partnerSignUpCount)
                          ?.toString()
                          .replace(commonReg2, ',')}
                      </td>
                      <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                        <CustomDiv>
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 회원가입 ID: ${
                              original?.memberSignUpIds ? original.memberSignUpIds : '없음'
                            }`}
                            place="bottom"
                          >
                            <span>{original?.memberSignUpCount?.toString().replace(commonReg2, ',')}</span>
                          </TooltipWrapper>

                          <CustomHr />
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 파트너가입 ID: ${
                              original?.partnerSignUpIds ? original.partnerSignUpIds : '없음'
                            }`}
                            place="bottom"
                          >
                            <span>{original?.partnerSignUpCount?.toString().replace(commonReg2, ',')}</span>
                          </TooltipWrapper>
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {(Number(original?.memberDepositAmount) - Number(original?.memberWithdrawalAmount))
                            ?.toString()
                            .replace(commonReg2, ',')}{' '}
                          (
                          {(
                            Number(original?.memberHoldemDepositAmount) - Number(original?.memberHoldemWithdrawalAmount)
                          )
                            ?.toString()
                            .replace(commonReg2, ',')}
                          )
                          <CustomHr />
                          {(Number(original?.partnerDepositAmount) - Number(original?.partnerWithdrawalAmount))
                            ?.toString()
                            .replace(commonReg2, ',')}{' '}
                          (
                          {(
                            Number(original?.partnerHoldemDepositAmount) -
                            Number(original?.partnerHoldemWithdrawalAmount)
                          )
                            ?.toString()
                            .replace(commonReg2, ',')}
                          )
                        </CustomDiv>
                      </td>

                      <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                        <CustomDiv>
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 회원 입금 ID -> 일반머니: ${
                              original?.depositMemberIds || '없음'
                            }, 홀덤머니: ${original?.depositHoldemMemberIds || '없음'}`}
                            place="bottom"
                          >
                            <span>
                              {original?.depositMemberCount?.toString().replace(commonReg2, ',')} (
                              {original?.depositHoldemMemberCount?.toString().replace(commonReg2, ',')})
                            </span>
                          </TooltipWrapper>
                          <CustomHr />
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 파트너 입금 ID -> 일반머니: ${
                              original?.depositPartnerIds || '없음'
                            }, 홀덤머니: ${original?.depositHoldemPartnerIds || '없음'}`}
                            place="bottom"
                          >
                            <span>
                              {original?.depositPartnerCount?.toString().replace(commonReg2, ',')} (
                              {original?.depositHoldemPartnerCount?.toString().replace(commonReg2, ',')})
                            </span>
                          </TooltipWrapper>
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {original?.memberDepositAmount?.toString().replace(commonReg2, ',')} (
                          {original?.memberHoldemDepositAmount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {original?.partnerDepositAmount?.toString().replace(commonReg2, ',')} (
                          {original?.partnerHoldemDepositAmount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>

                      <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                        <CustomDiv>
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 회원출금 ID -> 일반머니: ${
                              original?.withdrawalMemberIds || '없음'
                            }, 홀덤머니: ${original?.withdrawalHoldemMemberIds || '없음'}`}
                            place="bottom"
                          >
                            <span>
                              {original?.withdrawalMemberCount?.toString().replace(commonReg2, ',')} (
                              {original?.withdrawalHoldemMemberCount?.toString().replace(commonReg2, ',')})
                            </span>
                          </TooltipWrapper>
                          <CustomHr />
                          <TooltipWrapper
                            text={`${convertToKstDate(original?.settlementDate)} 파트너출금 ID -> 일반머니: ${
                              original?.withdrawalPartnerIds || '없음'
                            }, 홀덤머니: ${original?.withdrawalHoldemPartnerIds || '없음'}`}
                            place="bottom"
                          >
                            <span>
                              {original?.withdrawalPartnerCount?.toString().replace(commonReg2, ',')} (
                              {original?.withdrawalHoldemPartnerCount?.toString().replace(commonReg2, ',')})
                            </span>
                          </TooltipWrapper>
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {original?.memberWithdrawalAmount?.toString().replace(commonReg2, ',')} (
                          {original?.memberHoldemWithdrawalAmount?.toString().replace(commonReg2, ',')})
                          <CustomHr />
                          {original?.partnerWithdrawalAmount?.toString().replace(commonReg2, ',')} (
                          {original?.partnerHoldemWithdrawalAmount?.toString().replace(commonReg2, ',')})
                        </CustomDiv>
                      </td>

                      <td>
                        <CustomDiv>
                          {original?.memberRollingExchangeAmount?.toString().replace(commonReg2, ',')}
                          <CustomHr />
                          {original?.partnerRollingExchangeAmount?.toString().replace(commonReg2, ',')}
                        </CustomDiv>
                      </td>

                      <td>{original?.partnerLosingExchangeAmount?.toString().replace(commonReg2, ',')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {content.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
            </TableWrap>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default DailyAssetStatisticsList

DailyAssetStatisticsList.propTypes = {}

const getColor = colorText => {
  switch (true) {
    case colorText === 'isActiveLiveInfo':
      return 'green'
    case colorText === 'isActiveSlotInfo':
      return '#a66414'
    case colorText === 'isActiveSportsInfo':
      return 'purple'
    case colorText === 'isActiveMiniGameInfo':
      return '#808212'
    case colorText === 'isMain':
      return 'blue'
    default:
      return 'lightgrey'
  }
}

const CustomDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const CustomHr = styled.div`
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  height: 2px;
  width: 80%;
  background: #8f8f8f;
`

const CustomTh = styled.th`
  background: ${props => getColor(props.colorText)} !important;
  opacity: 0.7;
  color: ${props => (props.textColor === 'profit' ? 'yellow' : '#fff')} !important;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
