import { Button } from '@/shared/components/Button'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { decodeAccessToken } from '../../../../utils/token'
import {
  BettingResultStatusEnumsSelectOptions,
  sportResultStatusEnums,
} from '../../../../enums/SystemManagement/sportEnums'
import { GameSettingSelect } from '../../../SportManagement/GameSettingManagement/GameSettingSelect'
import { updateMemberSportsEventResult } from '../../../../utils/api/sport/sportIInfoApi'

function ResultModifyForm({
  sportsDetailBettingLogKey,
  sportsBettingLogKey,
  resultStatus,
  onClickHandler,
  fetchSearchSportsMarket,
}) {
  const authority = decodeAccessToken()?.adminAuthority
  const [sportResultValue, setSportResultValue] = useState(resultStatus)
  const sportResultOptions = BettingResultStatusEnumsSelectOptions()
  const onSportResultChangeHandler = option => {
    setSportResultValue(option)
  }
  const [loading, setLoading] = useState(false)
  const onSubmit = e => {
    if (loading) return
    if (sportResultValue.value) {
      if (sportResultValue.value === resultStatus) {
        alert('같은 상태값으로 변경할 수 없습니다.')
        return
      }
      if (sportResultValue.value === 'ING') {
        alert('진행중으로는 변경할 수 없습니다.')
        return
      }
      if (window.confirm('경기 결과를 변경 하시겠습니까?')) {
        setLoading(true)
        const body = {
          sportsBettingLogKey,
          sportsDetailBettingLogKey,
          resultStatus: sportResultValue.value,
        }

        updateMemberSportsEventResult(body)
          .then(res => {
            alert('경기결과가 수정됐습니다.')
            onClickHandler()
            setLoading(false)
            if (typeof fetchSearchSportsMarket === 'function') {
              fetchSearchSportsMarket()
            }
          })
          .catch(error => {
            setLoading(false)
            const { errorCode, message } = error.response.data
            if (errorCode === 'SPORTS-1015') {
              alert(`${message}`)
            } else if (errorCode === 'SPORTS-1012') {
              alert(`${message}`)
            } else if (errorCode === 'SPORTS-1016') {
              alert(`${message}`)
            } else if (errorCode === 'SPORTS-1011') {
              alert(`${message}`)
            } else {
              alert('경기결과가 수정이 실패했습니다.')
            }
            onClickHandler()
          })
      }
    }
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={
          {
            // userId,
            // selectedNote: noteOptionValue,
            // noteTarget: isTotal ? noteTargetOptionValue : {},
            // noteTitle,
            // noteContent,
            // imageFile,
          }
        }
      >
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer horizontal onSubmit={handleSubmit}>
            <FormGroup>
              <FormGroupLabel>현재 경기결과</FormGroupLabel>
              <FormGroupField>
                <Field name="sportType">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <input
                        {...input}
                        type="text"
                        value={sportResultStatusEnums[resultStatus]}
                        disabled
                        style={{ width: '100%' }}
                      />
                    </FormInputWrap>
                  )}
                </Field>
              </FormGroupField>
            </FormGroup>
            <FormGroup>
              <FormGroupLabel>수정 결과</FormGroupLabel>
              <FormGroupField>
                <Field name="sportType">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <GameSettingSelect
                        {...input}
                        value={sportResultValue}
                        onChange={option => {
                          input.onChange(option)
                          onSportResultChangeHandler(option)
                        }}
                        options={sportResultOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                      />
                    </FormInputWrap>
                  )}
                </Field>
              </FormGroupField>
            </FormGroup>
            <FormButtonToolbar style={{ marginLeft: '0px' }}>
              {
                {
                  SUPER: (
                    <>
                      <Button variant="primary" type="submit">
                        수정하기
                      </Button>
                    </>
                  ),
                }[authority]
              }
            </FormButtonToolbar>
          </FormContainer>
        )}
      </Form>
    </>
  )
}

export default ResultModifyForm

ResultModifyForm.propTypes = {
  resultStatus: PropTypes.string.isRequired,
  sportsDetailBettingLogKey: PropTypes.string.isRequired,
  sportsBettingLogKey: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  fetchSearchSportsMarket: PropTypes.func.isRequired,
}
