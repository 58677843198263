import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import { searchMemberMoneyExchangeRate } from '../../../utils/api/memberHistory/memberHistoryApi'
import { decodeAccessToken } from '../../../utils/token'
import MemberHistoryTabs from '../MemberHistoryTabs'
import MoneyExchangeRateListData from './MoneyExchangeRateListData'
import MoneySortSearchButtonNotNull from '../../../shared/components/MoneySortSearchButtonNotNull'

const MoneyExchangeRateList = () => {
  const authority = decodeAccessToken()?.adminAuthority

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)
  const [whatSearch, setWhatSearch] = useState('totalExchange')
  const [userAuthority, setUserAuthority] = useState(null)
  const [moneySort, setMoneySort] = useState('NORMAL_MONEY')

  /**
   * 회원 머니이동내역 조회
   */
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  endDate.setDate(today.getDate() + 1)
  endDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  startDate.setMonth(endDate.getMonth() - 1)
  startDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
    userAuthority: null,
    userId: null,
    whatSearch,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
    moneySort,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      userAuthority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      moneySort: !moneySort ? null : moneySort,
    }))
  }, [moneySort])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      whatSearch,
    }))
  }, [whatSearch])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [moneyExchangeListData, setMoneyExchangeRateListData] = useState(MoneyExchangeRateListData(content))

  useEffect(() => {
    setMoneyExchangeRateListData(MoneyExchangeRateListData(content))
  }, [content])

  const [rows, setData] = useState(moneyExchangeListData.tableRowsData)

  useEffect(() => {
    setData(moneyExchangeListData.tableRowsData)
  }, [moneyExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(startDate)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(endDate)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    if (searchExecuteStartDate === null || searchExecuteEndDate === null) {
      alert('기간 설정은 필수 입니다.')
      return
    }

    setParams(prev => ({
      ...prev,
      userId: !searchMemberId ? null : searchMemberId,
      startDate: convertToKst(searchExecuteStartDate),
      endDate: convertToKst(searchExecuteEndDate),
      whatSearch,
      page: 0,
    }))
  }
  const [loading, setLoading] = useState(false)

  const fetchSearchMoneyExchangeRateList = async () => {
    if (loading) return

    setLoading(true)
    await searchMemberMoneyExchangeRate(params)
      .then(res => {
        setContent(res.data.content)
        setTotalElement(res.data.totalElement)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMoneyExchangeRateList()
  }, [params])

  const [activeKey, setActiveKey] = useState('5')

  return (
    <Card>
      <CardBody>
        <MemberHistoryTabs activeKey={activeKey} />
        {loading && (
          <>
            <Loading />
          </>
        )}
        <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <MoneySortSearchButtonNotNull
            moneySort={moneySort}
            onClickHandler={e => {
              setMoneySort(e.target.id)
            }}
          />
        </div>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd 00:00:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd 00:00:00"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <CustomButton
              type="button"
              active={whatSearch === 'totalExchange'}
              onClick={e => {
                if (whatSearch !== 'totalExchange') {
                  setWhatSearch('totalExchange')
                } else {
                  setWhatSearch(null)
                }
              }}
            >
              환수율 정렬(⬆)
            </CustomButton>
            <CustomButton
              type="button"
              active={whatSearch === 'totalExchangeDown'}
              onClick={e => {
                if (whatSearch !== 'totalExchangeDown') {
                  setWhatSearch('totalExchangeDown')
                } else {
                  setWhatSearch(null)
                }
              }}
            >
              환수율 정렬(⬇️)
            </CustomButton>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase
          key="common"
          columns={moneyExchangeListData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
        />
      </CardBody>
    </Card>
  )
}

export default MoneyExchangeRateList

const CustomButton = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#000')} !important;
  background-color: ${props => (props.active ? '#454545' : 'lightgray')} !important;
`
