import { Card, CardBody, CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { UserAuthority } from '../../../enums/authority'
import { HoldemResultStatusEnums } from '../../../enums/Holdem/HoldemBettingEnums'
import { HoldemBettingResultEnumsSelectOptions } from '../../../enums/Log/bettingEnums'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import Loading from '../../../shared/components/Loading'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import { partnerSearchWildGamesBettingLog } from '../../../utils/api/holdem/wildGamesApi'
import { convertToKst, convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import BettingManagementTabs from '../BettingManagementTabs'

const WildGamesBettingLogPage = () => {
  const history = useHistory()

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    page: 0,
    size: 15,
    isExact: false,
  })

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [bettingLogList, setBettingLogList] = useState([])

  const fetchWildGamesBettingLog = async () => {
    setLoading(true)

    await partnerSearchWildGamesBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setBettingLogList(res.data.bettingLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchWildGamesBettingLog()
  }, [params])

  const [userAuthority, setUserAuthority] = useState(null)

  const onSearchUserAuthorityHandler = authority => {
    setUserAuthority(authority)

    setParams(prev => ({
      ...prev,
      authority,
      page: 0,
    }))
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = HoldemBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 배팅번호
  const [searchBettingNum, setSearchBettingNum] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      topUserId: !searchTopPartnerId ? null : searchTopPartnerId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      bettingResult: !searchGameResult ? null : searchGameResult,
      bettingNum: !searchBettingNum ? null : searchBettingNum,
      page: 0,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 15))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <>
      {loading && <Loading />}
      <Card>
        <CardBody>
          <BettingManagementTabs activeKey={6} />

          <MiniGameTypeSelectWrapper>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active
              onClick={() => {
                history.push('/management/log/holdem/wild-games/betting-log')
              }}
            >
              배팅내역
            </Button>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                history.push('/management/log/holdem/wild-games/betting-detail-log')
              }}
            >
              배팅 세부내역
            </Button>
          </MiniGameTypeSelectWrapper>

          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchMemberId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchMemberId}
                    onChange={e => onSearchMemberIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>배팅번호</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchBettingNum"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchBettingNum}
                    onChange={e => {
                      setSearchBettingNum(e.target.value)
                    }}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>게임결과</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchGameResult"
                  placeholder="게임결과"
                  onChange={option => {
                    onSelectGameResultOptionHandler(option)
                  }}
                  value={searchGameResultOptionValue}
                  options={searchGameResultOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          <SportBettingLogContentWrap>
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>사이트명</th>
                  <th>처리일시</th>
                  <th>상위파트너 (이름)</th>
                  <th>유저 (이름)</th>
                  <th>배팅번호</th>
                  <th>라운드</th>
                  <th>공급사</th>
                  <th>게임명</th>
                  <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅 금액</th>
                  <th>당첨 금액</th>
                  <th>잭팟 금액</th>
                  <th>기타 금액</th>
                  <th style={{ backgroundColor: 'green', color: '#fff' }}>
                    <TooltipWrapper text="변동금액 = 당첨 + 잭팟 - 배팅 + 기타" place="bottom">
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>변동 금액</span>
                    </TooltipWrapper>
                  </th>
                  <th>시작 금액</th>
                  <th>종료 금액</th>
                  <th>잔고</th>
                  <th>결과</th>
                </tr>
              </thead>
              <tbody>
                {bettingLogList.length === 0 ? (
                  <tr>
                    <td colSpan={20}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          color: '#000',
                          fontSize: '15px',
                          padding: '20px 0px',
                        }}
                      >
                        최근 게시물이 없습니다.
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {bettingLogList.map(bettingLog => {
                      return (
                        <>
                          <tr key={bettingLog.bettingLogKey}>
                            <td>{bettingLog.num}</td>
                            <td>{bettingLog.edgeName}</td>

                            <td>{convertToKstDateTime(bettingLog.transactionDate)}</td>
                            <td>
                              {bettingLog.topUserId ? (
                                <>
                                  {bettingLog.topUserId} ({bettingLog.topUserName})
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              <span
                                onClick={() => {
                                  window.open(`/user-info/${bettingLog.userId}`, '_blank')
                                }}
                                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                              >
                                {bettingLog.userId} ({bettingLog.userName})
                              </span>
                              <span style={{ marginLeft: '5px' }}>
                                <CustomAuthorityButton authority={bettingLog.userAuthority}>
                                  {UserAuthority[bettingLog.userAuthority]}
                                </CustomAuthorityButton>
                              </span>
                            </td>
                            <td>{bettingLog.sequence}</td>
                            <td>{bettingLog.roundId}</td>
                            <td>{bettingLog.providerName}</td>
                            <td>{bettingLog.gameName}</td>
                            <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                              {bettingLog.bet.toString().replace(commonReg2, ',')}
                            </td>
                            <td>{bettingLog.win.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.jackpot.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.other.toString().replace(commonReg2, ',')}</td>
                            <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                              {bettingLog.amount.toString().replace(commonReg2, ',')}
                            </td>
                            <td>{bettingLog.startAmount.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.endAmount.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.balance.toString().replace(commonReg2, ',')}</td>
                            <td>
                              <CustomButton type="button" bettingResult={bettingLog.bettingResult}>
                                {HoldemResultStatusEnums[bettingLog.bettingResult]}
                              </CustomButton>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                )}
              </tbody>
            </table>
            <PaginationWrap>
              <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
            </PaginationWrap>
          </SportBettingLogContentWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default WildGamesBettingLogPage

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  // margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
