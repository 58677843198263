import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

export const WebPageWrap = styled.div`
  background: #000000;
  border: 2px solid #edae07;
  min-height: 846px;
`

export const WebPageWrap2 = styled.div`
  background: #000000;
  border: 2px solid #edae07;
`

// sign up form
export const MoneyFormWrap = styled.form`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const CompFormWrap = styled.form`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
`

// sign up form group
export const MoneyFormGroup = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: left;
  // padding-bottom: 10px;
  gap: 5px;
  // border-bottom: 1px solid #4a4a4a;
`

// sign up form label
export const MoneyFormLabel = styled.div`
  width: 160px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

// sign up form field
export const MoneyFormField = styled.div`
  // width: 900px;
  display: flex;
`

// sign up form input wrap
export const MoneyFormInputWrap = styled.div`
  width: 100%;
  // border: 1px solid #4a4a4a;
  position: relative;
  height: 35px;
`

// sign up form input
export const MoneyFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// sign up form description
export const MoneyFormDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #d3d3d3;
`

// 버튼 안에 텍스트
export const MoneyFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #000;
`

// sign up from button
export const MoneyFormButton = styled.button`
  min-width: 100px;
  width: max-content;
  height: 40px;
  margin-left: 10px;
  border-radius: 0;

  background: #dedede;
`

// 버튼 안에 텍스트
export const MobileMoneyFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-align: center;

  // background: linear-gradient(to right, #fcf4cb, #fddb2a);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`

export const MobileMoneyFormButton = styled.button`
  width: 50px;
  height: 25px;
  margin-left: 10px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MobileMoneyFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MobileMoneyFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MobileMoneyFormButtomInfo} {
      color: #ffffff4d;
    }
  }
`

// 버튼 안에 텍스트
export const MoneyFormModifyButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  color: red !important;
`

// sign up from button
export const MoneyFormModifyButton = styled.button`
  width: 80px;
  height: 40px;
  margin-left: 10px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

// 버튼 안에 텍스트
export const MobileMoneyFormModifyButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-align: center;

  color: white !important;
`

// sign up from button
export const MobileMoneyFormModifyButton = styled.button`
  width: 50px;
  height: 25px;
  margin-top: 3px;
  margin-left: 10px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MobileMoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MobileMoneyFormModifyButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MobileMoneyFormModifyButtomInfo} {
      color: #ffffff4d;
    }
  }
`

// 버튼 안에 텍스트
export const MoneyFormIdConfirmButtonInfo = styled.span`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #000000;
`

// Sign up form id confirm button
export const MoneyFormIdConfirmButton = styled.button`
  width: 110px;
  height: 29px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MoneyFormIdConfirmButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MoneyFormIdConfirmButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MoneyFormIdConfirmButtonInfo} {
      color: #ffffff4d;
    }
  }
`

export const CustomMoneyFormGroupField = styled(MoneyFormField)`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
