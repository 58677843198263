export const memberNoteValidate = values => {
  const errors = {}

  if (!values.noteTitle) {
    errors.noteTitle = '필수 입력 항목입니다.'
  }
  if (!values.noteContent.getCurrentContent().hasText()) {
    errors.noteContent = '필수 입력 항목입니다.'
  }

  return errors
}
